import {
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  VStack,
  useToast,
  Text,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import {
  bulkCreatePatients,
  createPatient,
  deletePatient,
  getPatientList,
  updatePatient,
} from "../../api/patients";
import Header from "../../components/Head";
import PatientModal from "../../components/Patients/PatientModal";
import {
  DataSorting,
  PatientTable,
} from "../../components/Patients/PatientTable";
import TablePagination from "../../components/TablePagination";
import { PatientDto } from "../../types/patient";
import BulkUploadModal from "../../components/Patients/BulkPatientUploadModal";
import { getAllStaffList } from "../../api/staff";
import { StaffDto } from "../../types/staff";
import { StaffSelect } from "../../components/StaffSelect";
import { useStaffStore } from "../../store/staffStore";
import { FaSearch } from "react-icons/fa";
import { debounce } from "lodash";
import AddClientMenu from "../../components/Patients/AddClientMenu";

export default function ViewPatientsPage() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allPatients, setAllPatients] = useState<PatientDto[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const [staffFilter, setStaffFilter] = useState("");
  const [selectedPatient, setSelectedPatient] = useState<PatientDto | null>(
    null
  );
  const [staffList, setStaffList] = useState<StaffDto[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sorting, setSorting] = useState<DataSorting>({
    column: "lastActivity",
    direction: "desc",
  });

  const currentStaff = useStaffStore((state) => state.currentStaff);
  const toast = useToast();

  const fetchAllPatients = async () => {
    try {
      setLoading(true);
      const resp = await getPatientList({
        size: 1000,
        page: 1,
      });
      if (resp.data) {
        setAllPatients(resp.data.data);
      }
    } catch (error: any) {
      if (error.response?.status === 401) return;
      toast({
        title: "Failed to fetch patient info",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getStaffDetails = async () => {
    const resp = await getAllStaffList(currentStaff?.organizationId || "");
    if (resp.message) {
      toast({
        title: "Failed to fetch staff info",
        description: resp.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setStaffList(resp.data || []);
  };

  const getFilteredPatients = useCallback(() => {
    return allPatients
      .filter((patient) => {
        const matchesStaff =
          !staffFilter ||
          staffFilter === "all" ||
          patient.staffId === staffFilter;
        const matchesSearch =
          !nameFilter ||
          patient.name.toLowerCase().includes(nameFilter.toLowerCase());
        return matchesStaff && matchesSearch;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [allPatients, nameFilter, staffFilter]);

  const getSortedPatients = useCallback(() => {
    const filtered = getFilteredPatients();
    return filtered.sort((a, b) => {
      const multiplier = sorting.direction === "asc" ? 1 : -1;
      if (sorting.column === "name") {
        return multiplier * a.name.localeCompare(b.name);
      } else if (sorting.column === "lastActivity") {
        const dateA = new Date(a.lastActivity || 0).getTime();
        const dateB = new Date(b.lastActivity || 0).getTime();
        return multiplier * (dateA - dateB);
      }
      return 0;
    });
  }, [getFilteredPatients, sorting]);

  const getPaginatedPatients = useCallback(() => {
    const filtered = getSortedPatients();
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filtered.slice(start, end);
  }, [getSortedPatients, currentPage, itemsPerPage]);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setNameFilter(value);
      setCurrentPage(1);
    }, 300),
    []
  );

  const handleStaffFilter = (staffId: string) => {
    setStaffFilter(staffId);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchAllPatients();
    getStaffDetails();
  }, []);

  const filteredPatients = getFilteredPatients();
  const paginatedPatients = getPaginatedPatients();

  const handleModalClose = () => setModalOpen(false);

  const [isBulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);


  const handleSubmitBulkUpload = async (file: File) => {
    try {
      const response = await bulkCreatePatients(file);
      return {
        data: { results: { successCount: response.data.results.created } },
      };
    } catch (error: any) {
      toast({
        title: "Bulk upload failed",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  const handleSubmitPatient = async (patientData: PatientDto) => {
    if (selectedPatient) {
      try {
        // Update existing patient
        await updatePatient(selectedPatient.id, {
          ...patientData,
        });
        toast({
          title: "Patient updated",
          description: `Patient ${patientData.name} has been updated successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleModalClose();
      } catch (error: any) {
        toast({
          title: "Failed to update patient",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      try {
        // Create new patient
        const newPatient = await createPatient({
          ...patientData,
        });
        setAllPatients([...allPatients, newPatient.data]);
        toast({
          title: "Created new patient",
          description: `Patient ${newPatient.data.name} has been created successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleModalClose();
      } catch (error: any) {
        toast({
          title: "Failed to create patient",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleEdit = (patientId: string) => {
    const patientToEdit = allPatients.find(
      (patient) => patient.id === patientId
    );
    if (patientToEdit) {
      setSelectedPatient(patientToEdit);
      setModalOpen(true);
    }
  };

  const handleDelete = async (patientId: string) => {
    await deletePatient(patientId);
    setAllPatients(allPatients.filter((patient) => patient.id !== patientId));
  };

  return (
    <Container maxW="container.xl">
      <Header title="Clients" description="Manage and organize your clients" />
      <Stack spacing={8}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="lg">Clients</Heading>
          <AddClientMenu
            onAddSingle={() => {
              setSelectedPatient(null);
              setModalOpen(true);
            }}
            onBulkUpload={() => setBulkUploadModalOpen(true)}
          />
        </Flex>
        <VStack spacing={4} align="stretch">
          <FormControl>
            <FormLabel>Search</FormLabel>
            <InputGroup size="lg">
              <InputLeftElement pointerEvents="none">
                <FaSearch color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search by name..."
                onChange={(e) => debouncedSearch(e.target.value)}
              />
            </InputGroup>
          </FormControl>

          <HStack spacing={2} align="center">
            <Text
              fontSize={{ base: "sm", "2xl": "md" }}
              color="gray.600"
              minW="80px"
            >
              Filter By:
            </Text>
            <StaffSelect
              includeAllOption
              onSelectOption={handleStaffFilter}
              prefetchedStaffList={staffList}
              inputWidth="200px"
            />
          </HStack>
        </VStack>
        <Box mt={6}>
          <PatientTable
            patients={paginatedPatients}
            onEdit={handleEdit}
            onDelete={handleDelete}
            loading={loading}
            sorting={sorting}
            setSorting={setSorting}
          />
          <TablePagination
            currentPage={currentPage}
            totalRows={filteredPatients.length}
            currentPageRows={paginatedPatients.length}
            onPageChange={setCurrentPage}
            rowsPerPage={itemsPerPage}
            onChangeRowsPerPage={setItemsPerPage}
          />
        </Box>

        <PatientModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSubmit={handleSubmitPatient}
          initialData={selectedPatient}
          staffList={staffList}
        />

        <BulkUploadModal
          isOpen={isBulkUploadModalOpen}
          onClose={() => setBulkUploadModalOpen(false)}
          onSubmit={handleSubmitBulkUpload}
          onSuccess={fetchAllPatients}
        />
      </Stack>
    </Container>
  );
}
