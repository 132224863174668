import axios from "axios";
import { useStaffStore } from "../../store/staffStore";
import { handleStaffUnauthorizedError } from "./authErrorHandler";

const axiosAuth = axios.create();
const { setAccessToken, logout } = useStaffStore.getState();

// Request interceptor
axiosAuth.interceptors.request.use((config) => {
  const accessToken = useStaffStore.getState().accessToken;
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

axiosAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      try {
        const response = await handleStaffUnauthorizedError(error, axiosAuth);
        return response;
      } catch (handlerError) {
        logout();
        throw handlerError;
      }
    }
    // Throw the error if it's not related to token expiration or refresh
    throw error;
  }
);

if (process.env.REACT_APP_API_URL?.includes("ngrok")) {
  axiosAuth.interceptors.request.use((config) => {
    config.headers["ngrok-skip-browser-warning"] = "true";
    return config;
  });
}

export default axiosAuth;
