// NavItem.tsx
import { Flex, FlexProps, Icon, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { useLocation } from "react-router-dom";

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
  path?: string;
}

const NavItem = ({ icon, children, path, ...rest }: NavItemProps) => {
  const location = useLocation();
  const isActive = path ? location.pathname.startsWith(path) : false;

  return (
    <Flex
      align="center"
      p="10px"
      borderRadius="8px"
      role="group"
      cursor="pointer"
      transition="all 0.2s"
      bg={isActive ? "blue.700" : "transparent"}
      color={isActive ? "white" : "gray.600"}
      _hover={{
        bg: "blue.700",
        color: "white",
      }}
      {...rest}
    >
      <Icon as={icon} boxSize="20px" transition="all 0.2s" />
      <Text
        as="span"
        className="nav-text"
        ml={3}
        fontSize="14px"
        fontWeight="medium"
        transition="all 0.2s"
        opacity={0}
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {children}
      </Text>
    </Flex>
  );
};

export default NavItem;
