import { VStack, Flex, Spinner, Box, Text } from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";
import { Chat } from "../../../types/chat";
import { ChatBubble } from "./ChatBubble";
import {
  format,
  isToday,
  isYesterday,
  isSameDay,
  parseISO,
  isValid,
} from "date-fns";

interface MessageListProps {
  messages: Chat[];
  isLoading: boolean;
  isNewChat?: boolean;
}

interface MessageGroup {
  date: Date;
  messages: Chat[];
}

export const MessageList = forwardRef<HTMLDivElement, MessageListProps>(
  ({ messages, isLoading, isNewChat = false }, ref) => {
    // Group messages by date
    const messageGroups = useMemo(() => {
      const groups: MessageGroup[] = [];
      let currentGroup: MessageGroup | null = null;

      // Sort messages by date first
      const sortedMessages = [...messages].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

      sortedMessages.forEach((message) => {
        try {
          const messageDate = parseISO(message.createdAt);
          if (!isValid(messageDate)) {
            console.error(
              `Invalid date found in message: ${message.createdAt}`
            );
            return; // Skip this message
          }

          if (!currentGroup || !isSameDay(currentGroup.date, messageDate)) {
            currentGroup = {
              date: messageDate,
              messages: [],
            };
            groups.push(currentGroup);
          }
          currentGroup.messages.push(message);
        } catch (error) {
          console.error(
            `Error parsing date for message: ${message.createdAt}`,
            error
          );
          return; // Skip this message
        }
      });

      return groups;
    }, [messages]);

    const formatDateSeparator = (date: Date) => {
      if (!isValid(date)) {
        return "Unknown Date";
      }
      if (isToday(date)) {
        return "Today";
      }
      if (isYesterday(date)) {
        return "Yesterday";
      }
      return format(date, "EEE, d MMM yyyy"); // e.g., "Thu, 23 Jan 2024"
    };

    return (
      <Box
        flex={1}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.200",
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "gray.300",
          },
        }}
      >
        {isLoading ? (
          <Flex justify="center" align="center" h="full" py={10}>
            <Spinner size="lg" color="blue.500" />
          </Flex>
        ) : messages.length > 0 ? (
          <VStack spacing={4} p={4} alignItems="stretch">
            {messageGroups.map((group, groupIndex) => (
              <VStack
                key={group.date.toISOString()}
                spacing={4}
                alignItems="stretch"
              >
                <Flex align="center" justify="center" position="relative">
                  <Text
                    fontSize="xs"
                    fontWeight="medium"
                    color="gray.500"
                    px={3}
                    py={1}
                  >
                    {formatDateSeparator(group.date)}
                  </Text>
                </Flex>
                {group.messages.map((message) => (
                  <ChatBubble key={message.id} message={message} />
                ))}
              </VStack>
            ))}
            <div ref={ref} />
          </VStack>
        ) : (
          <Flex
            direction="column"
            justify="center"
            align="center"
            h="full"
            p={8}
            textAlign="center"
            color="gray.500"
          >
            {isNewChat ? (
              <>
                <Text fontSize="md" fontWeight="medium" mb={2}>
                  Start a New Conversation
                </Text>
                <Text fontSize="sm">
                  Send your first message to begin chatting
                </Text>
              </>
            ) : (
              <Text fontSize="sm">No messages yet</Text>
            )}
          </Flex>
        )}
      </Box>
    );
  }
);
