import { VStack, Text, Icon, useToast, Spinner, Box } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  loginClient,
  getClientRegisteredAuthMethods,
  sendResetClientPasscodeMessage,
} from "../../api/auth";
import { webAuthnLogin } from "../../utils/webauthn";
import { useClientStore } from "../../store/clientStore";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { ClientAuth } from "../../components/ClientAuth";
import { AuthMethod } from "../../types/auth";
import { LinkType } from "../../components/ResendLink";
import { clientRedirectAfterAuth } from "../../utils/clientRedirect";
import { LogoWithWords } from "../../LogoWithWords";

const makeLabel = (authMethods: AuthMethod[]): string => {
  if (authMethods.length === 0) {
    return "You have not registered yet.";
  }
  const authMethodLabels = authMethods.map(convAuthMethodToLabel);
  if (authMethods.length === 1) {
    return `Login with ${authMethodLabels[0]}`;
  }
  const size = authMethods.length;
  return `Login with ${authMethodLabels.slice(0, size - 1).join(", ")} or ${
    authMethodLabels[size - 1]
  }`;
};

const convAuthMethodToLabel = (authMethod: AuthMethod): string => {
  switch (authMethod) {
    case AuthMethod.PASSCODE:
      return "Passcode";
    case AuthMethod.WEBAUTHN:
      return "Biometrics";
    default:
      return "Unknown";
  }
};

const ClientLogin = () => {
  const [passcode, setPasscode] = useState("");
  const navigate = useNavigate();
  const loginHook = useClientStore((state) => state.login);
  const [availableAuthMethods, setAvailableAuthMethods] = useState(
    [] as AuthMethod[]
  );
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();
  const prefetchedAuthMethods = searchParams.get("authMethods");
  const redirectPath = searchParams.get("redirectPath");

  useEffect(() => {
    const fetchAuthMethods = async () => {
      if (prefetchedAuthMethods) {
        const authMethods = prefetchedAuthMethods.split(",") as AuthMethod[];
        setAvailableAuthMethods(authMethods);
        setLoading(false);
        return;
      }
      const availableAuthMethods = await getClientRegisteredAuthMethods(
        clientId as string
      );
      if (availableAuthMethods.error) {
        toast({
          title: "Could not fetch auth methods",
          description: availableAuthMethods.error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        // set to all options if fail
        setAvailableAuthMethods([AuthMethod.PASSCODE, AuthMethod.WEBAUTHN]);
        setLoading(false);
        return;
      }
      setAvailableAuthMethods(availableAuthMethods.authMethods as AuthMethod[]);
      setLoading(false);
    };
    fetchAuthMethods();
  }, [clientId]);

  const handleLoginWithPasscode = async () => {
    const resp = await loginClient(clientId as string, passcode);
    if (resp.error) {
      toast({
        title: "Login Failed",
        description: resp.error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    loginHook({
      clientId: resp.clientId as string,
      accessToken: resp.accessToken as string,
      organizationId: resp.organizationId as string,
    });
    toast({
      title: "Login successful",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    clientRedirectAfterAuth(
      navigate,
      clientId as string,
      redirectPath as string
    );
  };

  const handleForgotPasscode = async () => {
    const resp = await sendResetClientPasscodeMessage(
      clientId as string,
      redirectPath as string
    );
    if (resp.error || !resp.success) {
      toast({
        title: "Send Reset Passcode Link Failed",
        description: resp.error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    toast({
      title: "Success",
      description: "Reset passcode link will be sent to your whatsapp soon",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
  return (
    <VStack spacing={3} alignContent={"center"} margin={4} textAlign={"center"}>
      <LogoWithWords />
      <Text fontSize={"3xl"} fontWeight={"semibold"} width={"3xs"}>
        Welcome to Speedback!
      </Text>
      {loading ? (
        <Spinner />
      ) : (
        <Box mt="-8">
          <ClientAuth
            label={makeLabel(availableAuthMethods)}
            needConfimation={false}
            passcode={passcode}
            setPasscode={setPasscode}
            passcodeButtonCallback={handleLoginWithPasscode}
            availableAuthMethods={availableAuthMethods}
            resendLinkLabel="You have not registered yet or your registered options are not supported on this device."
            resendLinkButtonLabel="Send Registration Link"
            resendLinkType={LinkType.REGISTER}
            needResetPasscode={true}
            resetPasscodeCallback={handleForgotPasscode}
          />
        </Box>
      )}
    </VStack>
  );
};

export default ClientLogin;
