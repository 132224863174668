import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import axios from "./noAuth";
import { useStaffStore } from "../../store/staffStore";
import { useClientStore } from "../../store/clientStore";

type AuthType = "staff" | "client";

// Token refresh tracking (separate for each auth type)
const refreshState = {
  staff: {
    inProgress: false,
    promise: null as Promise<AxiosResponse<any, any>> | null,
  },
  client: {
    inProgress: false,
    promise: null as Promise<AxiosResponse<any, any>> | null,
  },
};

const API_URL = process.env.REACT_APP_API_URL || "";

const getRefreshToken = (authType: AuthType) => {
  const state = refreshState[authType];
  if (!state.inProgress) {
    state.inProgress = true;
    state.promise = axios
      .post(
        `${API_URL}/auth/refresh`,
        { staff: authType === "staff" },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // Resolve with response and reset for next time
        state.inProgress = false;
        state.promise = null;
        return response;
      })
      .catch((error) => {
        // Reject with error and reset for next time
        state.inProgress = false;
        state.promise = null;
        throw error;
      });
  }
  return state.promise;
};

export const handleStaffUnauthorizedError = async (
  error: AxiosError,
  axiosAuth: AxiosInstance
): Promise<AxiosResponse<any, any> | void> => {
  return handleUnauthorizedError(error, axiosAuth, "staff");
};

export const handleClientUnauthorizedError = async (
  error: AxiosError,
  axiosAuth: AxiosInstance
): Promise<AxiosResponse<any, any> | void> => {
  return handleUnauthorizedError(error, axiosAuth, "client");
};

const handleUnauthorizedError = async (
  error: AxiosError,
  axiosAuth: AxiosInstance,
  authType: AuthType
): Promise<AxiosResponse<any, any> | void> => {
  const originalRequest = error.config;
  if (!originalRequest) {
    throw error;
  }
  if (
    error.response?.status === 401 &&
    !Object.hasOwn(originalRequest, "_retry")
  ) {
    // Set retry flag to prevent infinite loops
    Object.assign(originalRequest, { _retry: true });

    // Make a call with the refresh cookie to obtain a new token
    const refreshResponse = await getRefreshToken(authType);
    if ([200, 201].includes(refreshResponse?.status || 0)) {
      const setAccessToken =
        authType === "staff"
          ? useStaffStore.getState().setAccessToken
          : useClientStore.getState().setAccessToken;
      setAccessToken(refreshResponse?.data.accessToken);
      return axiosAuth(originalRequest);
    }
  }
  throw error;
};
