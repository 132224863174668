import { Box, Icon, Spinner, Text, VStack } from "@chakra-ui/react";
import { Camera } from "react-coolicons";
import { FC } from "react";

export const LoadingOverlay: FC = () => (
  <Box
    position="fixed"
    top={0}
    left={0}
    right={0}
    bottom={0}
    bg="blackAlpha.600"
    backdropFilter="blur(4px)"
    zIndex={999}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <Box
      bg="white"
      p={8}
      borderRadius="2xl"
      boxShadow="2xl"
      maxW="sm"
      w="90%"
      textAlign="center"
    >
      <VStack spacing={6}>
        <Box position="relative">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Icon as={Camera} color="blue.500" boxSize={6} />
          </Box>
        </Box>

        <VStack spacing={2}>
          <Text fontSize="xl" fontWeight="semibold">
            Processing your image
          </Text>
          <Text fontSize="md" color="gray.500">
            We're analyzing your health data
          </Text>
          <Text fontSize="sm" color="gray.400">
            This may take a few seconds
          </Text>
        </VStack>
      </VStack>
    </Box>
  </Box>
);
