import React from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import ClientMobileNav from "../ClientMobileNav";

const ClientLayout: React.FC = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");

  return (
    <Flex direction="column" minH="100vh" bg={bgColor}>
      {/* Main content area */}
      <Box flex="1" p={4} pb={20} overflowY="auto">
        <Outlet />
      </Box>

      {/* Mobile navigation */}
      <ClientMobileNav />
    </Flex>
  );
};

export default ClientLayout;
