import {
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Property } from "../../types/protocol";
import { FormTypeField } from "../../types/form";
import { checkNumberFieldInvalid } from "../../utils/validators";

interface PropertyFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
  value: string;
}

export const PropertyFormField = ({
  field,
  handleInputChange,
  value,
}: PropertyFormFieldProps) => {
  const [localValue, setLocalValue] = useState<string>(value);

  // For BMI only
  const [height, setHeight] = useState<string>("");
  const [weight, setWeight] = useState<string>("");

  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  
  const handleChange = (value: string) => {
    setLocalValue(value);
    handleInputChange(field.key, value);
  };

  const calculateBmi = (weightKg: number, heightM: number): number => {
    // BMI formula: weight (kg) / (height (m) * height (m))
    return Number((weightKg / (heightM * heightM)).toFixed(2));
  };

  useEffect(() => {
    if (field.property !== Property.BMI) {
      const heightM = parseFloat(height) / 100;
      const weightKg = parseFloat(weight);
      if (!isNaN(heightM) && !isNaN(weightKg)) {
        const bmi = calculateBmi(weightKg, heightM);
        handleInputChange(field.key, bmi.toString());
      }
    }
  }, [height, weight, field.key, field.property, handleInputChange]);

  if (field.property === Property.BMI) {
    return (
      <>
        <Text>Height (cm)</Text>
        <NumberInput
          value={height}
          isRequired={field.required}
          isInvalid={checkNumberFieldInvalid(field, value)}
          onChange={(valueString) => {
            setHeight(valueString);
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text mt={2}>Weight (kg)</Text>
        <NumberInput
          value={weight}
          isRequired={field.required}
          isInvalid={checkNumberFieldInvalid(field, value)}
          onChange={(valueString) => {
            setWeight(valueString);
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </>
    );
  }
  return (
    <>
      {(field.numberOptions?.max || field.numberOptions?.min) && (
        <FormHelperText>
          (
          {field.numberOptions?.min !== undefined &&
            `Min: ${field.numberOptions.min}, `}
          {field.numberOptions?.max !== undefined &&
            `Max: ${field.numberOptions.max}`}
          )
        </FormHelperText>
      )}
      <NumberInput
        isRequired={field.required}
        isInvalid={checkNumberFieldInvalid(field, value)}
        value={localValue}
        onChange={handleChange}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </>
  );
};

export default PropertyFormField;
