import {
  Box,
  Text,
  Image,
  Spinner,
  IconButton,
  Tooltip,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Chat } from "../../../types/chat";
import { useState, useCallback } from "react";
import { DownloadIcon, RepeatIcon, WarningIcon } from "@chakra-ui/icons";
import mime from "mime";
export const ChatMediaContent = ({
  message,
  isOutgoing,
  handleOpenLink,
  onOpen,
}: {
  message: Chat;
  isOutgoing: boolean;
  handleOpenLink: (e: React.MouseEvent) => void;
  onOpen: () => void;
}) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showDownloadOverlay, setShowDownloadOverlay] = useState(false);
  const toast = useToast();

  const getPreviewUrl = (mediaType: string) => {
    if (mediaType === "image") return message.mediaUrl;
    if (mediaType === "audio") return `/assets/audio_placeholder.png`;
    if (mediaType === "video") return `/assets/video_placeholder.png`;
  };

  const getPreviewText = (mediaType: string) => {
    if (mediaType === "image") return "Click to enlarge";
    if (mediaType === "audio" || mediaType === "video") return "Click to play";
  };

  const handleImageLoad = () => {
    setIsImageLoading(false);
    setImageError(false);
  };

  const handleImageError = () => {
    setIsImageLoading(false);
    setImageError(true);
  };

  const handleRetry = () => {
    setIsImageLoading(true);
    setImageError(false);
    setRetryCount((prev) => prev + 1);
  };

  const handleDownload = useCallback(
    async (e?: React.MouseEvent) => {
      if (e) {
        e.stopPropagation(); // Prevent modal from opening
      }
      if (!message.mediaUrl) return;

      try {
        setIsDownloading(true);
        const response = await fetch(message.mediaUrl, {
          mode: "cors",
          cache: "no-store",
        });
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // Extract filename from URL or use a default
        const extension = mime.getExtension(contentType || "image/jpeg");
        const filename =
          new URL(message.mediaUrl).pathname.split("/").pop() || "image";
        link.setAttribute(
          "download",
          mime.getType(filename) ? filename : `${filename}.${extension}`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        toast({
          title: "Content downloaded successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Download failed:", error);
        toast({
          title: "Download failed",
          description: "Please try opening the link directly",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsDownloading(false);
      }
    },
    [message.mediaUrl, toast]
  );

  return (
    <Box position="relative" mb={message.text ? 3 : 0}>
      {isImageLoading && (
        <Flex
          height="200px"
          alignItems="center"
          justifyContent="center"
          bg="gray.50"
          borderRadius="lg"
        >
          <Spinner color={isOutgoing ? "white" : "blue.500"} />
        </Flex>
      )}
      {!imageError ? (
        <Box
          position="relative"
          onMouseEnter={() => setShowDownloadOverlay(true)}
          onMouseLeave={() => setShowDownloadOverlay(false)}
        >
          <Image
            key={retryCount}
            src={getPreviewUrl(message.mediaType || "image")}
            alt="Message attachment"
            maxH="200px"
            w="auto"
            objectFit="contain"
            cursor="zoom-in"
            onClick={onOpen}
            onLoad={handleImageLoad}
            onError={handleImageError}
            display={isImageLoading ? "none" : "block"}
            borderRadius="lg"
            _hover={{ opacity: 0.95 }}
            transition="all 0.2s"
          />
          {/* Download Overlay */}
          <Flex
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            borderRadius="lg"
            opacity={showDownloadOverlay ? 1 : 0}
            transition="all 0.2s"
            pointerEvents={showDownloadOverlay ? "auto" : "none"}
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-end"
            p={2}
          >
            <Tooltip
              label={
                isDownloading
                  ? "Downloading..."
                  : `Download ${message.mediaType}`
              }
              placement="left"
            >
              <IconButton
                aria-label={`Download ${message.mediaType}`}
                icon={<DownloadIcon />}
                size="sm"
                isLoading={isDownloading}
                onClick={handleDownload}
                variant="solid"
                colorScheme="whiteAlpha"
                bg="whiteAlpha.900"
                color="gray.800"
                _hover={{
                  bg: "white",
                  transform: "scale(1.05)",
                }}
                transition="all 0.2s"
                zIndex={2}
              />
            </Tooltip>
            {/* Preview Indicator */}
            <Flex
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              alignItems="center"
              justifyContent="center"
              cursor="zoom-in"
              onClick={onOpen}
              bg="transparent"
              _hover={{
                bg: "blackAlpha.200",
              }}
              transition="all 0.2s"
            >
              <Text
                color="white"
                fontSize="sm"
                fontWeight="medium"
                bg="blackAlpha.600"
                px={3}
                py={1}
                borderRadius="full"
                opacity={0.8}
              >
                {getPreviewText(message.mediaType || "image")}
              </Text>
            </Flex>
          </Flex>
        </Box>
      ) : (
        <Box
          p={4}
          bg={isOutgoing ? "whiteAlpha.200" : "gray.50"}
          borderRadius="lg"
          borderStyle="dashed"
          borderWidth={2}
          borderColor={isOutgoing ? "whiteAlpha.400" : "gray.200"}
        >
          <Flex direction="column" align="center" gap={3}>
            <WarningIcon
              boxSize={6}
              color={isOutgoing ? "whiteAlpha.800" : "gray.400"}
            />
            <Text
              color={isOutgoing ? "whiteAlpha.800" : "gray.500"}
              fontSize="sm"
              textAlign="center"
            >
              Unable to load {message.mediaType}
            </Text>
            <Flex gap={2}>
              <Button
                size="sm"
                leftIcon={<RepeatIcon />}
                onClick={handleRetry}
                variant={isOutgoing ? "outline" : "solid"}
                colorScheme={isOutgoing ? "whiteAlpha" : "blue"}
                isDisabled={retryCount >= 3}
              >
                Try Again
              </Button>
              <Button
                size="sm"
                leftIcon={<DownloadIcon />}
                onClick={handleOpenLink}
                variant={isOutgoing ? "outline" : "solid"}
                colorScheme={isOutgoing ? "whiteAlpha" : "blue"}
              >
                Open Link
              </Button>
            </Flex>
            {retryCount >= 3 && (
              <Text
                fontSize="xs"
                color={isOutgoing ? "whiteAlpha.700" : "gray.500"}
                textAlign="center"
              >
                Still having trouble? Try opening the link directly
              </Text>
            )}
          </Flex>
        </Box>
      )}
    </Box>
  );
};
