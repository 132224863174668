import {
  Box,
  Container,
  Heading,
  HStack,
  IconButton,
  Text,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Skeleton,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Badge,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import {
  BeakerIcon,
  ChartBarIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { ROUTES } from "../../constants";
import { calculateAge } from "../../utils/date";
import { PatientDto } from "../../types/patient";
import { useEffect, useState } from "react";
import { getPatientList } from "../../api/patients";
import { useNavigate } from "react-router-dom";

const Labs = () => {
  const [patients, setPatients] = useState<PatientDto[]>([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const fetchPatients = async () => {
    try {
      setLoading(true);
      const response = await getPatientList({
        size: 1000,
        page: 1,
      });
      if (response.data) {
        setPatients(response.data.data);
      }
    } catch (error: any) {
      if (error.response?.status === 401) return;
      toast({
        title: "Failed to fetch patients",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPatients();
  }, []);

  const formatClientName = (patient: PatientDto): string => {
    const age = patient.dob ? calculateAge(patient.dob) : null;
    const gender = patient.sex ? patient.sex.charAt(0) : null;

    const parts = [
      age !== null && age.toString(),
      gender !== null && gender,
    ].filter(Boolean);

    return parts.length > 0
      ? `${patient.name} (${parts.join(", ")})`
      : patient.name;
  };

  const copyToClipboard = (
    text: string,
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    toast({
      title: "Phone number copied",
      status: "info",
      duration: 2000,
      isClosable: true,
      position: "bottom",
    });
  };

  const formatDateTime = (date: Date): string => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
      .format(new Date(date))
      .replace(",", " /");
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Box mb={12}>
        <Flex align="center" mb={2}>
          <BeakerIcon
            style={{ width: "32px", height: "32px", marginRight: "12px" }}
          />
          <Heading size="lg">Labs & Analytics</Heading>
          <Badge
            ml={4}
            colorScheme="blue"
            fontSize="sm"
            textTransform="uppercase"
            borderRadius="full"
            px={3}
          >
            Beta
          </Badge>
        </Flex>
        <Text color="gray.600" fontSize="md" mb={8}>
          Manage and analyze client laboratory reports and health data. Upload
          lab results, track submitted data over time, and receive AI-powered
          summaries and recommendations for better patient care.
        </Text>

        <StatGroup bg="white" p={6} borderRadius="lg" boxShadow="sm" mb={8}>
          <Stat>
            <StatLabel color="gray.600">Total Reports</StatLabel>
            <HStack align="center" spacing={2}>
              <DocumentTextIcon
                style={{ width: "20px", height: "20px", color: "#4299E1" }}
              />
              <StatNumber>{patients.length * 2}</StatNumber>
            </HStack>
          </Stat>

          <Stat>
            <StatLabel color="gray.600">Active Patients</StatLabel>
            <HStack align="center" spacing={2}>
              <ChartBarIcon
                style={{ width: "20px", height: "20px", color: "#4299E1" }}
              />
              <StatNumber>{patients.length}</StatNumber>
            </HStack>
          </Stat>

          <Stat>
            <StatLabel color="gray.600">Analysis Generated</StatLabel>
            <HStack align="center" spacing={2}>
              <ChartBarIcon
                style={{ width: "20px", height: "20px", color: "#4299E1" }}
              />
              <StatNumber>{patients.length * 3}</StatNumber>
            </HStack>
          </Stat>
        </StatGroup>
      </Box>

      <Box>
        <TableContainer>
          <Table variant="unstyled">
            <Thead>
              <Tr display="flex" width="100%" mb={4} gap={2}>
                <Th
                  flex="1.5"
                  bg="gray.50"
                  py={3}
                  px={6}
                  textTransform="uppercase"
                  fontWeight="medium"
                  fontSize="xs"
                  color="gray.600"
                  borderRadius="4px"
                  cursor="pointer"
                >
                  <HStack
                    spacing={2}
                    justify="space-between"
                    width="full"
                    align="center"
                  >
                    <Text>Client (Age, Gender)</Text>
                  </HStack>
                </Th>
                <Th
                  flex="1"
                  bg="gray.50"
                  py={3}
                  px={6}
                  textTransform="uppercase"
                  fontWeight="medium"
                  fontSize="xs"
                  color="gray.600"
                  borderRadius="4px"
                  cursor="pointer"
                >
                  <HStack
                    spacing={2}
                    justify="space-between"
                    width="full"
                    align="center"
                  >
                    <Text>Client ID</Text>
                  </HStack>
                </Th>
                <Th
                  flex="1.2"
                  bg="gray.50"
                  py={3}
                  px={6}
                  textTransform="uppercase"
                  fontWeight="medium"
                  fontSize="xs"
                  color="gray.600"
                  borderRadius="4px"
                >
                  <HStack spacing={2} justify="space-between" width="full">
                    <Text>Phone Number</Text>
                    <Box w={6} h={6} />
                  </HStack>
                </Th>

                <Th
                  flex="1"
                  bg="gray.50"
                  py={3}
                  px={6}
                  textTransform="uppercase"
                  fontWeight="medium"
                  fontSize="xs"
                  color="gray.600"
                  borderRadius="4px"
                  cursor="pointer"
                >
                  <HStack
                    spacing={2}
                    justify="space-between"
                    width="full"
                    align="center"
                  >
                    <Text>Last Activity</Text>
                  </HStack>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {patients.map((patient) => (
                <Tr
                  key={patient.id}
                  display="flex"
                  width="100%"
                  mb={2}
                  gap={2}
                  cursor="pointer"
                  onClick={() => navigate(`${ROUTES.LABS}/${patient.id}`)}
                  _hover={{
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
                    transform: "translateY(-2px)",
                  }}
                  transition="all 0.2s ease"
                  borderRadius="4px"
                >
                  <Td flex="1.5" py={4} px={6}>
                    <Skeleton isLoaded={!loading}>
                      <Text fontSize="sm">{formatClientName(patient)}</Text>
                    </Skeleton>
                  </Td>
                  <Td flex="1" py={4} px={6} fontSize="sm">
                    <Skeleton isLoaded={!loading}>
                      {patient.clientId || "-"}
                    </Skeleton>
                  </Td>
                  <Td flex="1.2" py={4} px={6}>
                    <Skeleton isLoaded={!loading}>
                      <HStack spacing={2} justify="space-between">
                        <Text fontSize="sm">{patient.whatsappPhoneNum}</Text>
                        <IconButton
                          aria-label="Copy phone number"
                          icon={<CopyIcon />}
                          size="xs"
                          variant="ghost"
                          color="gray.300"
                          _hover={{ color: "gray.500" }}
                          onClick={(e) =>
                            copyToClipboard(patient.whatsappPhoneNum || "", e)
                          }
                        />
                      </HStack>
                    </Skeleton>
                  </Td>

                  <Td flex="1" py={4} px={6} fontSize="sm">
                    <Skeleton isLoaded={!loading}>
                      {patient.lastActivity &&
                        formatDateTime(patient.lastActivity)}
                    </Skeleton>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default Labs;
