import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Icon,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiUpload, FiUserPlus } from "react-icons/fi";
import { theme } from "../../../constants";

interface AddClientMenuProps {
  onAddSingle: () => void;
  onBulkUpload: () => void;
}

const AddClientMenu: React.FC<AddClientMenuProps> = ({
  onAddSingle,
  onBulkUpload,
}) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        colorScheme={theme}
      >
        Add Client
      </MenuButton>
      <MenuList>
        <MenuItem icon={<Icon as={FiUserPlus} />} onClick={onAddSingle}>
          Add Single Client
        </MenuItem>
        <MenuItem icon={<Icon as={FiUpload} />} onClick={onBulkUpload}>
          Bulk Upload Clients
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default AddClientMenu;
