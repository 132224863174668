import {
  Box,
  Flex,
  IconButton,
  Text,
  Textarea,
  HStack,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { ArrowUpIcon, SettingsIcon } from "@chakra-ui/icons";
import { useState, useEffect, useRef, ChangeEvent, useCallback } from "react";
import { TemplateSelector } from "./TemplateSelector";
import { TemplateManager } from "./TemplateManager";
import { MessagePurposeSelector } from "./MessagePurposeSelector";
import { MessagePurpose, MESSAGE_PURPOSE_CONFIG } from "./constants";
import { useDisclosure } from "@chakra-ui/react";
import {
  MessageTemplate,
  CreateMessageTemplateDto,
} from "../../../types/messageTemplate";
import {
  getMessageTemplates,
  updateMessageTemplate,
  deleteMessageTemplate,
  createMessageTemplate,
} from "../../../api/messageTemplate";

interface ChatInputProps {
  value: string;
  onChange: (value: string) => void;
  onSend: (templateId?: string) => void;
  isDisabled: boolean;
  isLoading: boolean;
  placeholder?: string;
  isWithinWindow: boolean;
}

export const ChatInput = ({
  value,
  onChange,
  onSend,
  isDisabled,
  isLoading,
  placeholder = "Type a message...",
  isWithinWindow,
}: ChatInputProps) => {
  const [templates, setTemplates] = useState<MessageTemplate[]>([]);
  const [templatesError, setTemplatesError] = useState<string | null>(null);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const toast = useToast();

  // Helper function to determine default message purpose
  const getDefaultMessagePurpose = useCallback((isWithinWindow: boolean) => {
    return isWithinWindow ? MessagePurpose.REGULAR : MessagePurpose.SPEEDBACK;
  }, []);

  const [showTemplates, setShowTemplates] = useState(false);
  const [templateQuery, setTemplateQuery] = useState("");
  const [selectedPurpose, setSelectedPurpose] = useState<MessagePurpose>(
    getDefaultMessagePurpose(isWithinWindow)
  );

  // Update selected purpose when isWithinWindow changes
  useEffect(() => {
    setSelectedPurpose(getDefaultMessagePurpose(isWithinWindow));
  }, [isWithinWindow, getDefaultMessagePurpose]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (value.startsWith("/")) {
      setShowTemplates(true);
      setTemplateQuery(value.slice(1));
    } else {
      setShowTemplates(false);
      setTemplateQuery("");
    }
  }, [value]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = "auto";
    const scrollHeight = textarea.scrollHeight;
    const newHeight = Math.min(Math.max(32, scrollHeight), 180);

    textarea.style.height = `${newHeight}px`;
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  useEffect(() => {
    window.addEventListener("resize", adjustTextareaHeight);
    return () => window.removeEventListener("resize", adjustTextareaHeight);
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!showTemplates) {
        handleSend();
      }
    } else if (e.key === "Escape" && showTemplates) {
      setShowTemplates(false);
    }
  };

  const handleTemplateSelect = (content: string) => {
    onChange(content);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleSend = () => {
    const templateId = MESSAGE_PURPOSE_CONFIG[selectedPurpose].templateId;
    onSend(templateId);

    // Reset to default message purpose after sending
    setSelectedPurpose(getDefaultMessagePurpose(isWithinWindow));
  };

  const {
    isOpen: isManageOpen,
    onOpen: onManageOpen,
    onClose: onManageClose,
  } = useDisclosure();

  const fetchTemplates = async () => {
    try {
      setTemplatesLoading(true);
      const response = await getMessageTemplates();
      setTemplates(response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
      setTemplatesError("Failed to load templates");
    } finally {
      setTemplatesLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleEditTemplate = async (template: MessageTemplate) => {
    try {
      setTemplatesLoading(true);
      await updateMessageTemplate(template.id, template);
      await fetchTemplates(); // Refresh the list
      toast({
        title: "Template updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating template:", error);
      toast({
        title: "Failed to update template",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setTemplatesLoading(false);
    }
  };

  const handleDeleteTemplate = async (templateId: string) => {
    try {
      setTemplatesLoading(true);
      await deleteMessageTemplate(templateId);
      await fetchTemplates(); // Refresh the list
      toast({
        title: "Template deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting template:", error);
      toast({
        title: "Failed to delete template",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setTemplatesLoading(false);
    }
  };

  const handleCreateTemplate = async (template: CreateMessageTemplateDto) => {
    try {
      setCreating(true);
      await createMessageTemplate(template);
      await fetchTemplates(); // Refresh the list
      toast({
        title: "Template created",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error creating template:", error);
      toast({
        title: "Failed to create template",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setCreating(false);
    }
  };

  return (
    <Box p={4} borderTop="1px" borderColor="gray.200" position="relative">
      <HStack
        position="absolute"
        bottom="calc(100% + 8px)"
        left={4}
        fontSize="xs"
        color="gray.500"
        zIndex={1}
        spacing={2}
      >
        <Text>{!showTemplates && !value && "Type / to use a template"}</Text>
        {!showTemplates && !value && (
          <Tooltip label="Manage Templates" placement="top">
            <IconButton
              icon={<SettingsIcon />}
              aria-label="Manage templates"
              size="xs"
              variant="ghost"
              onClick={onManageOpen}
            />
          </Tooltip>
        )}
      </HStack>

      <Box position="absolute" bottom="calc(100% + 8px)" right={4} zIndex={1}>
        <MessagePurposeSelector
          selectedPurpose={selectedPurpose}
          onChange={setSelectedPurpose}
          isWithinWindow={isWithinWindow}
        />
      </Box>

      <Flex gap={2} align="flex-end">
        <Box flex={1} position="relative">
          <Textarea
            ref={textareaRef}
            placeholder={showTemplates ? "Search templates..." : placeholder}
            value={value}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              onChange(e.target.value);
            }}
            onKeyPress={handleKeyPress}
            disabled={isDisabled || isLoading}
            minH="40px"
            maxH="180px"
            resize="none"
            overflow="auto"
            py={2}
            lineHeight="shorter"
            transition="all 0.2s ease"
            borderRadius="xl"
            _focus={{
              boxShadow: "none",
              borderColor: "blue.500",
            }}
            _hover={{
              borderColor: "gray.300",
            }}
          />
        </Box>
        <IconButton
          aria-label="Send message"
          icon={<ArrowUpIcon />}
          colorScheme="blue"
          onClick={handleSend}
          isLoading={isLoading}
          isDisabled={isDisabled || !value.trim() || showTemplates}
          height="40px"
          width="40px"
          borderRadius="xl"
        />
      </Flex>

      {showTemplates && (
        <TemplateSelector
          searchQuery={templateQuery}
          onSelect={handleTemplateSelect}
          onClose={() => setShowTemplates(false)}
          templates={templates}
          loading={templatesLoading}
          error={templatesError}
          onCreateTemplate={handleCreateTemplate}
          isCreating={creating}
        />
      )}

      <TemplateManager
        isOpen={isManageOpen}
        onClose={onManageClose}
        templates={templates}
        onEdit={handleEditTemplate}
        onDelete={handleDeleteTemplate}
        onCreateTemplate={handleCreateTemplate}
        isCreating={creating}
      />
    </Box>
  );
};
