import { Center, Heading, Text, VStack, Button, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useParams } from "react-router-dom";
import { FC } from "react";

const TerraAuthSuccess: FC = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  return (
    <Center mt={20} w={"full"}>
      <VStack spacing={4} ml={5} mr={5}>
        <Heading as="h1" size="lg">
          Authentication Successful!
        </Heading>
        <Text>Your wearable health data has been successfully connected.</Text>

        <Image src="/thank_you.png" alt="Thank you" width="300" />

        <Text fontSize="sm" color="gray.600" textAlign="center" mt={2}>
          You can now return to your health passport to record more information or view your health trends.
        </Text>

        <Button
          variant={"outline"}
          width={"full"}
          onClick={() => {
            navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_PASS}`);
          }}
        >
          Back to Health Passport
        </Button>
      </VStack>
    </Center>
  );
};

export default TerraAuthSuccess;
