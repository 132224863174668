import {
  ClientPassConf,
  MetricConfigs,
  MetricConfWithDetail,
} from "../../types/clientpass";
import { MetricCard } from "../MetricCard";
import {
  Flex,
  Button,
  VStack,
  useToast,
  Center,
  Spinner,
  Divider,
  Textarea,
  Icon,
  Text,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  getClientPassConfig,
  upsertClientPassConfig,
} from "../../api/clientpass";
import { sendClientPassMessage } from "../../api/clientpass";
import { useFilteredMetrics } from "../../hooks/useFilteredMetrics";
import { PatientDto } from "../../types/patient";
import { PaperPlane } from "react-coolicons";
import { SendClientPassMessageReqBody } from "../../types/clientpass";

export type MetricConfigurationProps = {
  patient: PatientDto;
};

type MetricToggleMap = Map<string, boolean>;

export const MetricConfiguration = (props: MetricConfigurationProps) => {
  const [metricToggleMap, setMetricToggleMap] = useState<MetricToggleMap>(
    new Map<string, boolean>()
  );
  const [metricDetails, setMetricDetails] = useState<MetricConfWithDetail[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const toast = useToast();
  const filteredMetricDetails = useFilteredMetrics(metricDetails);
  const handleSwitchCallback = (metricId: string, enabled: boolean) => {
    const newMap = new Map(metricToggleMap);
    if (newMap.has(metricId)) {
      newMap.set(metricId, enabled);
      setMetricToggleMap(newMap);
    }
  };
  const fetchClientPassConfig = async (id: string) => {
    setLoading(true);
    const resp = await getClientPassConfig(id);
    if (resp.message || !resp.data) {
      toast({
        title: "Failed to fetch vitals",
        description: resp.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    setLoading(false);
    setMetricDetails(resp.data.metrics);
    setMetricToggleMap(createMetricDetailMap(resp.data.metrics));
  };
  const handleSave = async () => {
    setSaving(true);
    let metricConfigs: MetricConfigs = {};
    metricToggleMap.forEach((value, key) => {
      metricConfigs[key] = { enabled: value };
    });
    const clientPassConf: ClientPassConf = {
      clientId: props.patient.id || "",
      metricConfigs: metricConfigs,
    };
    const resp = await upsertClientPassConfig(clientPassConf);
    if (resp.message) {
      toast({
        title: "Failed to update vitals",
        description: resp.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setSaving(false);
    }
    toast({
      title: "Update Successful",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setSaving(false);
  };

  useEffect(() => {
    fetchClientPassConfig(props.patient.id);
  }, [props.patient]);

  if (loading) {
    return (
      <Center h="full" w="full">
        <Spinner></Spinner>
      </Center>
    );
  }
  return (
    <>
      <VStack gap={4} alignItems={"flex-start"}>
        <Flex wrap={"wrap"} justifyContent={"left"} gap={2}>
          {filteredMetricDetails?.map((metricDetail) => (
            <MetricCard
              key={metricDetail.detail.id}
              metricDetail={metricDetail.detail}
              adminView={true}
              enabled={metricToggleMap.get(metricDetail.detail.id)}
              switchCallback={handleSwitchCallback}
            />
          ))}
        </Flex>
        <Button w="2xs" onClick={handleSave} isLoading={saving}>
          Save
        </Button>
        <Divider />
        <SendMessageSection client={props.patient} saveMetrics={handleSave} />
      </VStack>
    </>
  );
};

type SendMessageSectionProps = {
  client: PatientDto;
  saveMetrics: () => void; // callback to save the confiugred metrics before sending the message (design could be improved)
};
const SendMessageSection = (props: SendMessageSectionProps) => {
  const toast = useToast();
  const [message, setMessage] = useState("");

  const handleSendMessage = async () => {
    await props.saveMetrics();
    if (!message) {
      toast({
        title: "Message cannot be empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const req: SendClientPassMessageReqBody = {
      clientId: props.client.id,
      message: message,
      organizationId: props.client.tenantId as string,
      whatsappNumber: props.client.whatsappPhoneNum as string,
    };
    const resp = await sendClientPassMessage(req);
    if (resp.message) {
      toast({
        title: "Failed to send message",
        description: resp.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    toast({
      title: "Message sent",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
  return (
    <VStack mt={6} w="full" alignItems={"flex-start"} gap={6}>
      <Text fontSize={"xl"} color={"blue.900"}>
        Send a message to {props.client.name}
      </Text>
      <Textarea
        w="full"
        placeholder="Type your message here"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button onClick={handleSendMessage}>
        <HStack>
          <Icon as={PaperPlane} fontSize={"s"} />
          <Text fontSize={"s"}>Save and send message</Text>
        </HStack>
      </Button>
    </VStack>
  );
};

function createMetricDetailMap(
  metricDetails: MetricConfWithDetail[]
): MetricToggleMap {
  const metricDetailMap = new Map<string, boolean>();
  metricDetails.forEach((metric) => {
    metricDetailMap.set(metric.detail.id, metric.enabled);
  });

  return metricDetailMap;
}
