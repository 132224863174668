import { VitalType } from "../types/llm";
import axios from "./axios/clientAuth";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "";

export async function extractImageData(image: File, vitalType?: VitalType) {
  const formData = new FormData();
  formData.append("image", image);
  if (vitalType) {
    formData.append("vitalType", vitalType);
  }

  try {
    const response = await axios.post(
      `${API_URL}/llm-chat/extract-image-data`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error extracting image data:", error);
    throw error;
  }
}