import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  useToast,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import {
  confirmDeletePatient,
  previewDeletePatient,
} from "../../../api/patients";

interface DeletePatientModalProps {
  isOpen: boolean;
  onClose: () => void;
  patient: {
    id: string;
    name: string;
  };
  onSuccess: () => void;
}

type DeletePreviewData = Array<{ entity_type: string; record_count: number }>;

const DELETE_CONFIRM_TEXT = "delete this patient";
export const DeletePatientModal: React.FC<DeletePatientModalProps> = ({
  isOpen,
  onClose,
  patient,
  onSuccess,
}) => {
  const [deleteConfirmText, setDeleteConfirmText] = useState("");
  const [deletePreviewData, setDeletePreviewData] =
    useState<DeletePreviewData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (isOpen) {
      handlePreviewDelete();
    }
  }, [isOpen]);

  const resetState = () => {
    setDeleteConfirmText("");
    setDeletePreviewData(null);
  };

  const handleModalClose = () => {
    resetState();
    onClose();
  };

  const handlePreviewDelete = async () => {
    if (!patient) return;

    try {
      setIsLoading(true);
      const response = await previewDeletePatient(patient.id);

      if (!response.data) {
        throw new Error(`Error: ${response.status}`);
      }
      setDeletePreviewData(response.data);
    } catch (error) {
      console.error("Error previewing patient deletion:", error);
      toast({
        title: "Error previewing deletion",
        description:
          error instanceof Error
            ? error.message
            : "Failed to preview patient deletion",
        status: "error",
        duration: 3000,
      });
      handleModalClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    if (!patient || deleteConfirmText !== DELETE_CONFIRM_TEXT) return;

    try {
      setIsLoading(true);
      const response = await confirmDeletePatient(patient.id);

      if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`);
      }

      toast({
        title: "Patient deleted",
        description: `${patient.name} has been permanently deleted`,
        status: "success",
        duration: 3000,
      });

      // Call success callback to refresh data
      onSuccess();
      handleModalClose();
    } catch (error) {
      console.error("Error deleting patient:", error);
      toast({
        title: "Error deleting patient",
        description:
          error instanceof Error ? error.message : "Failed to delete patient",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Patient</ModalHeader>
        <ModalBody>
          {isLoading && !deletePreviewData ? (
            <VStack spacing={4}>
              <Spinner />
              <Text>Loading deletion preview...</Text>
            </VStack>
          ) : (
            <>
              <Text>
                Are you sure you want to permanently delete{" "}
                <strong>{patient.name}</strong>?
              </Text>

              <Text mt={4} fontWeight="bold">
                The following data will be deleted:
              </Text>
              {deletePreviewData && deletePreviewData.length > 0 && (
                <Box
                  mt={2}
                  p={4}
                  borderWidth="1px"
                  borderRadius="md"
                  bg="gray.50"
                  maxHeight="200px"
                  overflowY="auto"
                >
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Data Type</Th>
                        <Th isNumeric>Count</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {deletePreviewData.map((item, index) => (
                        <Tr key={index}>
                          <Td>{item.entity_type}</Td>
                          <Td isNumeric>{item.record_count}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              )}

              <Text mt={4} color="red.500" fontWeight="bold">
                This action cannot be undone!
              </Text>

              <FormControl mt={4}>
                <FormLabel>Type "{DELETE_CONFIRM_TEXT}" to confirm:</FormLabel>
                <Input
                  value={deleteConfirmText}
                  onChange={(e) => setDeleteConfirmText(e.target.value)}
                  placeholder={DELETE_CONFIRM_TEXT}
                />
              </FormControl>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleModalClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={handleConfirmDelete}
            isDisabled={deleteConfirmText !== DELETE_CONFIRM_TEXT || isLoading}
            isLoading={isLoading && deletePreviewData !== null}
          >
            Delete Patient
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
