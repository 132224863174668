import { Box } from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router-dom";
import SidebarWithHeader from "../SidebarWithHeader";
import { ROUTES } from "../../constants";

const Layout = () => {
  const location = useLocation();
  const isChatsPage = location.pathname === ROUTES.CHATS;

  return (
    <Box>
      <SidebarWithHeader>
        <Box
          mt={isChatsPage ? 0 : 6}
          mb={isChatsPage ? 0 : 6}
          overflow={"auto"}
        >
          <Outlet />
        </Box>
      </SidebarWithHeader>
    </Box>
  );
};

export default Layout;
