import React from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "../../constants";
import { FaHome, FaClipboardList, FaChartLine } from "react-icons/fa";

interface NavItemProps {
  icon: React.ReactElement;
  label: string;
  to: string;
  isActive: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ icon, label, to, isActive }) => {
  const activeColor = useColorModeValue("blue.900", "blue.300");
  const inactiveColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Link to={to} style={{ textDecoration: "none", flex: 1 }}>
      <Flex
        direction="column"
        align="center"
        justify="center"
        py={2}
        color={isActive ? activeColor : inactiveColor}
      >
        <Box fontSize="xl" mb={1}>
          {icon}
        </Box>
        <Text fontSize="xs" fontWeight={isActive ? "bold" : "normal"}>
          {label}
        </Text>
      </Flex>
    </Link>
  );
};

const ClientMobileNav: React.FC = () => {
  const location = useLocation();
  const { clientId } = useParams<{ clientId: string }>();
  const pathname = location.pathname;

  const navItems = [
    {
      label: "Home",
      icon: <FaHome />,
      to: `${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_PASS}`,
      isActive: pathname.includes(ROUTES.CLIENT_PASS),
    },
    {
      label: "Forms",
      icon: <FaClipboardList />,
      to: `${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_FORMS}`,
      isActive: pathname.includes(ROUTES.CLIENT_FORMS),
    },
    {
      label: "Metrics",
      icon: <FaChartLine />,
      to: `${ROUTES.CLIENTS}/${clientId}${ROUTES.VIEW_METRIC_DATA}`,
      isActive: pathname.includes(ROUTES.VIEW_METRIC_DATA),
    },
  ];

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bg={useColorModeValue("white", "gray.800")}
      boxShadow="0 -2px 10px rgba(0, 0, 0, 0.05)"
      zIndex={10}
    >
      <Flex justify="space-around" align="center">
        {navItems.map((item) => (
          <NavItem
            key={item.label}
            icon={item.icon}
            label={item.label}
            to={item.to}
            isActive={item.isActive}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default ClientMobileNav;
