import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Flex,
  useColorModeValue,
  Spinner,
  Button,
  Icon,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { getOutstandingForms } from "../../api/forms";
import { FaHistory, FaClipboardList, FaChevronRight } from "react-icons/fa";

interface Form {
  id: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
}

const ClientForms: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const navigate = useNavigate();
  const [forms, setForms] = useState<Form[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const cardBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const sectionBg = useColorModeValue("gray.50", "gray.800");

  useEffect(() => {
    const fetchForms = async () => {
      if (!clientId) return;

      try {
        const data = await getOutstandingForms(clientId);
        setForms(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching forms:", err);
        setError("Failed to load forms. Please try again later.");
        setLoading(false);
      }
    };

    fetchForms();
  }, [clientId]);

  const handleFormClick = (formId: string) => {
    navigate(`${ROUTES.FORM}/${formId}/${clientId}?channel=WHATSAPP`);
  };

  const navigateToHistory = () => {
    navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.FORM_HISTORY}`);
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100%" p={8}>
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Box p={4} textAlign="center">
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  // Group forms by status
  const newForms = forms.filter((form) => form.status === "new");
  const scheduledForms = forms.filter((form) => form.status === "scheduled");

  return (
    <Box p={4}>
      <VStack spacing={2} align="flex-start" mb={4}>
        <Box>
          <Heading fontSize="lg">Your Forms</Heading>
          <Text fontSize="md" color="gray.600" mt={1}>
            Complete these forms to keep your health information up to date
          </Text>
        </Box>
        <Button
          leftIcon={<Icon as={FaHistory} />}
          variant="ghost"
          size="sm"
          color="blue.900"
          onClick={navigateToHistory}
          pl={0}
        >
          View Submission History
        </Button>
      </VStack>

      {forms.length === 0 ? (
        <Box p={8} textAlign="center" bg={sectionBg} borderRadius="md">
          <Icon as={FaClipboardList} boxSize={12} color="gray.400" mb={4} />
          <Text fontSize="lg">No forms available at the moment.</Text>
          <Text color="gray.500" mt={2}>
            Check back later for new forms from your care team.
          </Text>
        </Box>
      ) : (
        <VStack spacing={6} align="stretch">
          {newForms.length > 0 && (
            <Box>
              <Heading fontSize="md" mb={3} color="green.600">
                New Forms
              </Heading>
              <VStack spacing={3} align="stretch">
                {newForms.map((form) => (
                  <Box
                    key={form.id}
                    p={4}
                    borderWidth="1px"
                    borderRadius="md"
                    borderColor={borderColor}
                    bg={cardBg}
                    boxShadow="sm"
                    onClick={() => handleFormClick(form.id)}
                    cursor="pointer"
                    _hover={{ boxShadow: "md", transform: "translateY(-2px)" }}
                    transition="all 0.2s"
                    borderLeftWidth="4px"
                    borderLeftColor="green.400"
                  >
                    <Flex justify="space-between" align="center">
                      <Box flex="1">
                        <Heading fontSize="sm" mb={2}>
                          {form.name}
                        </Heading>
                        <Text color="gray.600" noOfLines={2} fontSize="xs">
                          {form.description}
                        </Text>
                      </Box>
                      <Icon
                        as={FaChevronRight}
                        color="gray.400"
                        boxSize={3}
                        ml={2}
                      />
                    </Flex>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}

          {scheduledForms.length > 0 && (
            <Box>
              <Heading fontSize="md" mb={3} color="blue.600">
                Scheduled Forms
              </Heading>
              <VStack spacing={3} align="stretch">
                {scheduledForms.map((form) => (
                  <Box
                    key={form.id}
                    p={4}
                    borderWidth="1px"
                    borderRadius="md"
                    borderColor={borderColor}
                    bg={cardBg}
                    boxShadow="sm"
                    onClick={() => handleFormClick(form.id)}
                    cursor="pointer"
                    _hover={{ boxShadow: "md", transform: "translateY(-2px)" }}
                    transition="all 0.2s"
                    borderLeftWidth="4px"
                    borderLeftColor="blue.400"
                  >
                    <Flex justify="space-between" align="center">
                      <Box flex="1">
                        <Heading fontSize="sm" mb={2}>
                          {form.name}
                        </Heading>
                        <Text color="gray.600" noOfLines={2} fontSize="xs">
                          {form.description}
                        </Text>
                      </Box>
                      <Icon
                        as={FaChevronRight}
                        color="gray.400"
                        boxSize={3}
                        ml={2}
                      />
                    </Flex>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
        </VStack>
      )}
    </Box>
  );
};

export default ClientForms;
