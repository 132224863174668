import {
  Box,
  VStack,
  Text,
  Button,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  useDisclosure,
  Link,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  MessageTemplate,
  CreateMessageTemplateDto,
} from "../../../types/messageTemplate";
import { theme } from "../../../constants";
import { TEMPLATE_CATEGORIES, TemplateCategory } from "./constants";

interface TemplateSelectorProps {
  onSelect: (content: string) => void;
  onClose: () => void;
  searchQuery: string;
  templates: MessageTemplate[];
  loading: boolean;
  error: string | null;
  onCreateTemplate: (template: CreateMessageTemplateDto) => Promise<void>;
  isCreating: boolean;
}

export const TemplateSelector = ({
  onSelect,
  onClose,
  searchQuery,
  templates,
  loading,
  error,
  onCreateTemplate,
  isCreating,
}: TemplateSelectorProps) => {
  const [formData, setFormData] = useState<CreateMessageTemplateDto>({
    name: "",
    content: "",
    category: "Health Management",
  });

  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const linkColor = useColorModeValue("blue.600", "blue.300");

  const handleCreateTemplate = async () => {
    await onCreateTemplate(formData);
    setFormData({ name: "", content: "", category: "Health Management" });
    onCreateClose();
  };

  const filteredTemplates = templates.filter(
    (template) =>
      (template.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        template.content.toLowerCase().includes(searchQuery.toLowerCase())) &&
      TEMPLATE_CATEGORIES.includes(template.category as TemplateCategory)
  );

  const groupedTemplates = filteredTemplates.reduce((acc, template) => {
    const category = template.category as TemplateCategory;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(template);
    return acc;
  }, {} as Record<TemplateCategory, MessageTemplate[]>);

  if (loading) {
    return (
      <Box
        position="absolute"
        bottom="100%"
        left={0}
        right={0}
        p={4}
        bg={bgColor}
        borderRadius="md"
        boxShadow="lg"
        borderWidth={1}
        borderColor={borderColor}
        maxH="300px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner color={`${theme}.500`} />
      </Box>
    );
  }

  const NoTemplatesFound = () => (
    <HStack spacing={2} justify="flex-start">
      <Text color="gray.500">No matching templates found</Text>
      <Text color="gray.500">-</Text>
      <Link
        color={linkColor}
        fontWeight="medium"
        onClick={onCreateOpen}
        _hover={{ textDecoration: "underline" }}
      >
        create one?
      </Link>
    </HStack>
  );

  return (
    <>
      <Box
        position="absolute"
        bottom="100%"
        left={0}
        right={0}
        p={4}
        bg={bgColor}
        borderRadius="md"
        boxShadow="lg"
        borderWidth={1}
        borderColor={borderColor}
        w="50%"
        maxH="300px"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.200",
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "gray.300",
          },
        }}
      >
        {error ? (
          <Text color="red.500">{error}</Text>
        ) : Object.keys(groupedTemplates).length === 0 ? (
          <NoTemplatesFound />
        ) : (
          <VStack align="stretch" spacing={4}>
            {TEMPLATE_CATEGORIES.map((category) => {
              const categoryTemplates = groupedTemplates[category] || [];
              if (categoryTemplates.length === 0) return null;

              return (
                <Box key={category}>
                  <Text
                    fontSize="sm"
                    fontWeight="medium"
                    color={`${theme}.700`}
                    mb={2}
                  >
                    {category}
                  </Text>
                  <VStack align="stretch" spacing={1}>
                    {categoryTemplates.map((template) => (
                      <Button
                        key={template.id}
                        variant="ghost"
                        justifyContent="flex-start"
                        py={2}
                        px={3}
                        h="auto"
                        whiteSpace="normal"
                        textAlign="left"
                        onClick={() => {
                          onSelect(template.content);
                          onClose();
                        }}
                        _hover={{
                          bg: `${theme}.50`,
                        }}
                      >
                        <VStack align="flex-start" spacing={0}>
                          <Text fontSize="sm" fontWeight="medium">
                            {template.name}
                          </Text>
                          <Text
                            fontSize="xs"
                            color="gray.500"
                            noOfLines={2}
                            wordBreak="break-word"
                          >
                            {template.content}
                          </Text>
                        </VStack>
                      </Button>
                    ))}
                  </VStack>
                </Box>
              );
            })}
          </VStack>
        )}
      </Box>

      <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Template Name</FormLabel>
                <Input
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  placeholder="Enter template name"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Category</FormLabel>
                <Select
                  value={formData.category}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      category: e.target.value as TemplateCategory,
                    })
                  }
                >
                  {TEMPLATE_CATEGORIES.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Content</FormLabel>
                <Textarea
                  value={formData.content}
                  onChange={(e) =>
                    setFormData({ ...formData, content: e.target.value })
                  }
                  placeholder="Enter template content"
                  rows={4}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCreateClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleCreateTemplate}
              isLoading={isCreating}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
