import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Flex,
  useColorModeValue,
  Icon,
  Button,
  Spinner,
  useToast,
  Badge,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { FaArrowLeft, FaCalendarAlt, FaClipboardCheck } from "react-icons/fa";
import { getFormDataById } from "../../api/formData";
import { getFormById } from "../../api/forms";
import {
  FormDataResp,
  FormDto,
  FormTypeField,
  ResponseType,
} from "../../types/form";
import { format } from "date-fns";

const ClientFormResponseView: React.FC = () => {
  const { clientId, submissionId } = useParams<{
    clientId: string;
    submissionId: string;
  }>();
  const navigate = useNavigate();
  const toast = useToast();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const accentColor = useColorModeValue("blue.600", "blue.300");
  const questionBgColor = useColorModeValue("gray.50", "gray.700");

  const [formResponse, setFormResponse] = useState<FormDataResp | null>(null);
  const [formDefinition, setFormDefinition] = useState<FormDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleBackClick = () => {
    navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.FORM_HISTORY}`);
  };

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setIsLoading(true);
        if (clientId && submissionId) {
          const response = await getFormDataById(submissionId);

          if (response) {
            // Verify this submission belongs to the current client
            if (response.patientId === clientId) {
              setFormResponse(response);

              // Now fetch the form definition using the form ID from the response
              const formDef = await getFormById(response.formId);
              setFormDefinition(formDef);
            } else {
              // This submission doesn't belong to this client
              toast({
                title: "Unauthorized",
                description:
                  "You don't have permission to view this form submission.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          } else {
            toast({
              title: "Form response not found",
              description: "We couldn't find this specific form submission.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching form response:", error);
        toast({
          title: "Error loading form response",
          description: "Unable to load your form submission details.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchFormData();
  }, [clientId, submissionId, toast]);

  const formatDate = (date: Date) => {
    return format(date, "MMMM d, yyyy 'at' h:mm a");
  };

  // Render the appropriate response display based on field type
  const renderFieldResponse = (field: FormTypeField) => {
    if (!formResponse) return null;

    const value = formResponse.rawData[field.key];

    if (!value) return <Text color="gray.500">No response</Text>;

    switch (field.responseType) {
      case ResponseType.CHECKBOX:
        return (
          <Badge colorScheme={value === "true" ? "green" : "gray"}>
            {value === "true" ? "Yes" : "No"}
          </Badge>
        );

      case ResponseType.RADIO:
        return <Text>{field.options?.[value] || value}</Text>;

      case ResponseType.NUMBER:
        return <Text fontWeight="medium">{value}</Text>;

      case ResponseType.SHORT_TEXT:
      case ResponseType.LONG_TEXT:
        return <Text>{value}</Text>;

      default:
        return <Text>{value}</Text>;
    }
  };

  return (
    <Box p={4}>
      <Button
        leftIcon={<FaArrowLeft />}
        variant="ghost"
        mb={4}
        pl={0}
        onClick={handleBackClick}
        size="sm"
      >
        Back to Form History
      </Button>

      {isLoading ? (
        <Flex justify="center" align="center" minH="200px">
          <Spinner size="lg" color="blue.500" />
        </Flex>
      ) : !formResponse || !formDefinition ? (
        <Flex
          direction="column"
          align="center"
          justify="center"
          p={8}
          bg={bgColor}
          borderRadius="md"
          borderWidth="1px"
          borderColor={borderColor}
          minH="200px"
        >
          <Icon as={FaCalendarAlt} boxSize={10} color="gray.400" mb={4} />
          <Heading size="md" mb={2}>
            Form not found
          </Heading>
          <Text textAlign="center" color="gray.500">
            We couldn't find this form submission. It may have been deleted.
          </Text>
        </Flex>
      ) : (
        <VStack spacing={6} align="stretch">
          <Box
            p={4}
            bg={bgColor}
            borderRadius="md"
            borderWidth="1px"
            borderColor={borderColor}
          >
            <Flex align="center" mb={2}>
              <Icon
                as={FaClipboardCheck}
                color={accentColor}
                mr={2}
                boxSize={5}
              />
              <Heading fontSize="xl">{formDefinition.name}</Heading>
            </Flex>
            <Text color="gray.600" fontSize="sm">
              Submitted on {formatDate(formResponse.createdAt)}
            </Text>
            {formDefinition.description && (
              <Text mt={3} fontSize="md">
                {formDefinition.description}
              </Text>
            )}
          </Box>

          {formDefinition.fields.length > 0 ? (
            <Box
              p={4}
              bg={bgColor}
              borderRadius="md"
              borderWidth="1px"
              borderColor={borderColor}
            >
              <Heading fontSize="md" mb={4}>
                Your Responses
              </Heading>
              <VStack spacing={4} align="stretch">
                {formDefinition.fields.map((field, fieldIndex) => (
                  <Box
                    key={fieldIndex}
                    p={3}
                    borderRadius="md"
                    bg={questionBgColor}
                  >
                    <Text fontWeight="medium" mb={1}>
                      {field.question || field.label}
                    </Text>
                    {renderFieldResponse(field)}
                  </Box>
                ))}
              </VStack>
            </Box>
          ) : (
            <Box p={4} textAlign="center">
              <Text>This form doesn't have any questions.</Text>
            </Box>
          )}
        </VStack>
      )}
    </Box>
  );
};

export default ClientFormResponseView;
