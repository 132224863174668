import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  useColorModeValue,
  Icon,
  Button,
  List,
  ListItem,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import {
  FaArrowLeft,
  FaCalendarAlt,
  FaChevronRight,
  FaClipboardList,
} from "react-icons/fa";
import { getFormDataList } from "../../api/formData";
import { FormDataResp } from "../../types/form";
import { format, isToday, isThisWeek, isThisMonth } from "date-fns";

type GroupedSubmissions = {
  today: FormDataResp[];
  thisWeek: FormDataResp[];
  thisMonth: FormDataResp[];
  earlier: FormDataResp[];
};

const ClientFormHistory: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const navigate = useNavigate();
  const toast = useToast();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const accentColor = useColorModeValue("blue.600", "blue.300");
  const cardBgColor = useColorModeValue("white", "gray.700");
  const [formSubmissions, setFormSubmissions] = useState<FormDataResp[]>([]);
  const [groupedSubmissions, setGroupedSubmissions] =
    useState<GroupedSubmissions>({
      today: [],
      thisWeek: [],
      thisMonth: [],
      earlier: [],
    });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleBackClick = () => {
    navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_FORMS}`);
  };

  useEffect(() => {
    const fetchFormSubmissions = async () => {
      try {
        setIsLoading(true);
        if (clientId) {
          const submissions = await getFormDataList({
            patientIds: [clientId],
          });

          const sortedSubmissions = submissions.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );

          setFormSubmissions(sortedSubmissions);

          const grouped: GroupedSubmissions = {
            today: [],
            thisWeek: [],
            thisMonth: [],
            earlier: [],
          };

          sortedSubmissions.forEach((submission) => {
            const date = new Date(submission.createdAt);
            if (isToday(date)) {
              grouped.today.push(submission);
            } else if (isThisWeek(date)) {
              grouped.thisWeek.push(submission);
            } else if (isThisMonth(date)) {
              grouped.thisMonth.push(submission);
            } else {
              grouped.earlier.push(submission);
            }
          });

          setGroupedSubmissions(grouped);
        }
      } catch (error) {
        console.error("Error fetching form submissions:", error);
        toast({
          title: "Error fetching form history",
          description: "Unable to load your form submission history.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchFormSubmissions();
  }, [clientId, toast]);

  const handleSubmissionClick = (submissionId: string) => {
    navigate(
      `${ROUTES.CLIENTS}/${clientId}${ROUTES.FORM_RESPONSE}/${submissionId}/view`
    );
  };

  const formatDate = (date: Date) => {
    // Format the date in local timezone
    return format(date, "MMM d, yyyy 'at' h:mm a");
  };

  const formatSimpleDate = (date: Date) => {
    if (isToday(date)) {
      return format(date, "h:mm a");
    }
    return format(date, "MMM d, yyyy");
  };

  const renderSubmissionGroup = (
    title: string,
    submissions: FormDataResp[]
  ) => {
    if (submissions.length === 0) return null;

    return (
      <Box mb={4}>
        <Text fontSize="sm" fontWeight="medium" color="gray.500" mb={2} px={2}>
          {title}
        </Text>
        <List spacing={2} bg="transparent" borderRadius="md">
          {submissions.map((submission) => (
            <ListItem key={submission.id}>
              <Box
                p={3}
                borderRadius="md"
                bg={cardBgColor}
                boxShadow="sm"
                borderWidth="1px"
                borderColor={borderColor}
                _hover={{ boxShadow: "md", transform: "translateY(-1px)" }}
                transition="all 0.2s"
                onClick={() => handleSubmissionClick(submission.id)}
                cursor="pointer"
              >
                <Flex justify="space-between" align="center">
                  <Box flex="1">
                    <Flex align="center" mb={1}>
                      <Icon as={FaClipboardList} color={accentColor} mr={2} />
                      <Text
                        fontWeight="semibold"
                        fontSize="md"
                        color={accentColor}
                        noOfLines={1}
                      >
                        {submission.form?.name || "Unnamed Form"}
                      </Text>
                    </Flex>
                    <Text fontSize="sm" color="gray.500">
                      Submitted{" "}
                      {formatSimpleDate(new Date(submission.createdAt))}
                    </Text>
                  </Box>
                  <Icon as={FaChevronRight} color="gray.400" boxSize={5} />
                </Flex>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box p={4}>
      <Button
        leftIcon={<FaArrowLeft />}
        variant="ghost"
        mb={4}
        pl={0}
        onClick={handleBackClick}
        size="sm"
      >
        Back to Forms
      </Button>

      <Box mb={6}>
        <Heading fontSize="xl">Form History</Heading>
        <Text fontSize="md" color="gray.600" mt={1}>
          View your previously completed forms
        </Text>
      </Box>

      {isLoading ? (
        <Flex justify="center" align="center" minH="200px">
          <Spinner size="lg" color="blue.500" />
        </Flex>
      ) : formSubmissions.length === 0 ? (
        <Flex
          direction="column"
          align="center"
          justify="center"
          p={8}
          bg={bgColor}
          borderRadius="md"
          borderWidth="1px"
          borderColor={borderColor}
          minH="200px"
        >
          <Icon as={FaCalendarAlt} boxSize={10} color="gray.400" mb={4} />
          <Heading size="md" mb={2}>
            No submissions yet
          </Heading>
          <Text textAlign="center" color="gray.500">
            You haven't submitted any forms yet. When you do, they'll appear
            here.
          </Text>
        </Flex>
      ) : (
        <Box>
          {renderSubmissionGroup("Today", groupedSubmissions.today)}
          {renderSubmissionGroup("This Week", groupedSubmissions.thisWeek)}
          {renderSubmissionGroup("This Month", groupedSubmissions.thisMonth)}
          {renderSubmissionGroup("Earlier", groupedSubmissions.earlier)}
        </Box>
      )}
    </Box>
  );
};

export default ClientFormHistory;
