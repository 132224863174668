import { AxiosResponse } from "axios";
import staffAuth from "./axios/staffAuth";
import { Chat, ChatPreview, SendTextMessagePayload } from "../types/chat";

const API_URL = process.env.REACT_APP_API_URL || "";

// Organization context is handled by staffAuth
export const getChatPreviews = async (): Promise<
  AxiosResponse<ChatPreview[]>
> => {
  return staffAuth.get(`${API_URL}/chat/previews`);
};

export const getAllMessagesByPatient = async (
  patientId: string
): Promise<AxiosResponse<Chat[]>> => {
  return staffAuth.get(`${API_URL}/chat/patient/${patientId}/chats`);
};

export const getIsPatientWithinConversationWindow = async (
  patientId: string
): Promise<AxiosResponse<boolean>> => {
  return staffAuth.get(
    `${API_URL}/chat/patient/${patientId}/withinConversationWindow`
  );
};

export const sendTextMessage = async (
  patientId: string,
  payload: SendTextMessagePayload
): Promise<AxiosResponse<Chat>> => {
  return staffAuth.post(
    `${API_URL}/chat/patient/${patientId}/messages/text`,
    payload
  );
};

export const sendTemplateMessage = async (
  patientId: string,
  payload: SendTextMessagePayload
): Promise<AxiosResponse<Chat>> => {
  return staffAuth.post(
    `${API_URL}/chat/patient/${patientId}/messages/template`,
    payload
  );
};