import axios from "./axios/clientAuth";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "";

export async function generateAuth(patientId: string, successRedirectUrl: string, failureRedirectUrl: string) {
  
  try {
    const response = await axios.post(
      `${API_URL}/terra/widget/${patientId}`,
      {
        successRedirectUrl,
        failureRedirectUrl
      }
    );  

    return response.data;
  } catch (error) {
    console.error("Error generating auth:", error);
    throw error;
  }
}