import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
  Select,
  HStack,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Center,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { format, subDays, eachDayOfInterval } from "date-fns";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import {
  ArrowLeftIcon,
  ChartBarIcon,
  DocumentPlusIcon,
  ArrowTopRightOnSquareIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { LabFoodDto } from "../../types/lab";
import { getLabFoods } from "../../api/lab";

type TimeRange = "7" | "14" | "30" | "all";

const COLORS = {
  protein: "#3182CE", // blue
  carbs: "#38A169", // green
  fat: "#DD6B20", // orange
  fiber: "#805AD5", // purple
};

const EmptyState = () => (
  <Center py={12}>
    <VStack spacing={4}>
      <DocumentPlusIcon
        style={{ width: "48px", height: "48px", color: "#A0AEC0" }}
      />
      <Text color="gray.500" fontSize="lg" textAlign="center">
        No nutritional data available yet
      </Text>
      <Text color="gray.400" fontSize="sm" textAlign="center" maxW="md">
        Start logging meals through chat to see nutritional insights and trends
      </Text>
    </VStack>
  </Center>
);

const downloadCSV = (data: any[], filename: string) => {
  const headers = [
    "Date",
    "Calories",
    "Protein (g)",
    "Carbs (g)",
    "Fat (g)",
    "Fiber (g)",
  ];
  const csvContent = [
    headers.join(","),
    ...data.map((row) =>
      [row.date, row.calories, row.protein, row.carbs, row.fat, row.fiber].join(
        ","
      )
    ),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const getExportFileName = (timeRange: TimeRange) => {
  const date = format(new Date(), "yyyy-MM-dd");
  switch (timeRange) {
    case "7":
      return `nutritional-trends_7d_${date}`;
    case "14":
      return `nutritional-trends_14d_${date}`;
    case "30":
      return `nutritional-trends_30d_${date}`;
    case "all":
      return `nutritional-trends_all_${date}`;
  }
};

const NutritionalTrends = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [timeRange, setTimeRange] = useState<TimeRange>("7");
  const [foods, setFoods] = useState<LabFoodDto[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFoods = async () => {
      if (!clientId) return;
      try {
        setLoading(true);
        const response = await getLabFoods(clientId);
        setFoods(response);
      } catch (error) {
        console.error("Failed to fetch food history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFoods();
  }, [clientId]);

  const stats = useMemo(() => {
    const cutoffDate =
      timeRange === "all"
        ? new Date(
            Math.min(...foods.map((food) => new Date(food.createdAt).getTime()))
          )
        : subDays(new Date(), parseInt(timeRange));

    const filteredFoods = foods.filter(
      (food) => new Date(food.createdAt) >= cutoffDate
    );

    if (filteredFoods.length === 0) {
      return {
        averageCalories: 0,
        macros: [],
        dailyStats: [],
        totalProtein: 0,
        totalCarbs: 0,
        totalFat: 0,
        totalFiber: 0,
      };
    }

    // Calculate total macros
    const totalCalories = filteredFoods.reduce(
      (sum, food) => sum + food.calories,
      0
    );
    const totalProtein = filteredFoods.reduce(
      (sum, food) => sum + food.protein,
      0
    );
    const totalCarbs = filteredFoods.reduce(
      (sum, food) => sum + food.carbohydrates,
      0
    );
    const totalFat = filteredFoods.reduce((sum, food) => sum + food.fat, 0);
    const totalFiber = filteredFoods.reduce((sum, food) => sum + food.fiber, 0);
    const totalGrams = totalProtein + totalCarbs + totalFat;

    // Calculate macro percentages
    const macros = [
      {
        name: "Protein",
        value: totalProtein,
        percentage: Math.round((totalProtein / totalGrams) * 100),
      },
      {
        name: "Carbs",
        value: totalCarbs,
        percentage: Math.round((totalCarbs / totalGrams) * 100),
      },
      {
        name: "Fat",
        value: totalFat,
        percentage: Math.round((totalFat / totalGrams) * 100),
      },
      {
        name: "Fiber",
        value: totalFiber,
        percentage: Math.round((totalFiber / totalGrams) * 25), // Scaled for visibility since fiber is subset of carbs
      },
    ];

    // Calculate daily stats for the line chart
    const dateRange = eachDayOfInterval({
      start: cutoffDate,
      end: new Date(),
    });

    const dailyStats = dateRange.map((date) => {
      const dayFoods = filteredFoods.filter(
        (food) =>
          format(new Date(food.createdAt), "yyyy-MM-dd") ===
          format(date, "yyyy-MM-dd")
      );

      const dayCalories = dayFoods.reduce(
        (sum, food) => sum + food.calories,
        0
      );
      const dayProtein = dayFoods.reduce((sum, food) => sum + food.protein, 0);
      const dayCarbs = dayFoods.reduce(
        (sum, food) => sum + food.carbohydrates,
        0
      );
      const dayFat = dayFoods.reduce((sum, food) => sum + food.fat, 0);
      const dayFiber = dayFoods.reduce((sum, food) => sum + food.fiber, 0);

      return {
        date: format(date, timeRange === "all" ? "MMM d, yyyy" : "MMM d"),
        calories: dayCalories,
        protein: dayProtein,
        carbs: dayCarbs,
        fat: dayFat,
        fiber: dayFiber,
      };
    });

    // For table display, we want reverse chronological
    const reversedDailyStats = [...dailyStats].reverse();

    return {
      averageCalories: Math.round(totalCalories / dateRange.length),
      macros,
      dailyStats, // chronological for graph
      reversedDailyStats, // reverse chronological for table
      totalProtein,
      totalCarbs,
      totalFat,
      totalFiber,
    };
  }, [foods, timeRange]);

  if (loading) {
    return (
      <Container maxW="container.xl" py={8}>
        <Text>Loading...</Text>
      </Container>
    );
  }

  if (!loading && foods.length === 0) {
    return (
      <Container maxW="container.xl" py={8}>
        <Box mb={8}>
          <IconButton
            aria-label="Back"
            icon={<ArrowLeftIcon style={{ width: "20px", height: "20px" }} />}
            onClick={() => navigate(-1)}
            variant="ghost"
            mb={4}
          />
          <Flex justify="space-between" align="center" mb={6}>
            <HStack spacing={3}>
              <Box>
                <HStack spacing={3}>
                  <Heading size="lg">Nutritional Trends</Heading>
                  <ChartBarIcon
                    style={{ width: "24px", height: "24px", color: "gray.900" }}
                  />
                </HStack>
                <Text color="gray.600" fontSize="md" mt={1}>
                  Track daily macronutrient intake and analyze dietary patterns
                  over time
                </Text>
              </Box>
            </HStack>
          </Flex>
        </Box>
        <Box bg="white" borderRadius="lg" borderWidth="1px">
          <EmptyState />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <Box mb={8}>
        <IconButton
          aria-label="Back"
          icon={<ArrowLeftIcon style={{ width: "20px", height: "20px" }} />}
          onClick={() => navigate(-1)}
          variant="ghost"
          mb={4}
        />
        <Flex justify="space-between" align="center" mb={6}>
          <HStack spacing={3}>
            <Box>
              <HStack spacing={3}>
                <Heading size="lg">Nutritional Trends</Heading>
                <ChartBarIcon
                  style={{ width: "24px", height: "24px", color: "#4A5568" }}
                />
              </HStack>
              <Text color="gray.600" fontSize="md" mt={1}>
                Track daily macronutrient intake and analyze dietary patterns
                over time
              </Text>
            </Box>
          </HStack>
          <Select
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value as TimeRange)}
            width="200px"
          >
            <option value="7">Last 7 days</option>
            <option value="14">Last 14 days</option>
            <option value="30">Last 30 days</option>
            <option value="all">All time</option>
          </Select>
        </Flex>
      </Box>

      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <Box
          gridColumn="span 4"
          bg="white"
          p={6}
          borderRadius="lg"
          borderWidth="1px"
        >
          <Text fontSize="lg" fontWeight="medium" mb={4}>
            Macronutrient Distribution
          </Text>
          <Box height="300px">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={stats.macros}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  label={({ name, value }) => `${name} ${Math.round(value)}g`}
                  style={{ fontSize: "14px" }}
                >
                  {stats.macros.map((entry, index) => (
                    <Cell
                      key={entry.name}
                      fill={Object.values(COLORS)[index]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>

        <Box
          gridColumn="span 8"
          bg="white"
          p={6}
          borderRadius="lg"
          borderWidth="1px"
        >
          <Text fontSize="lg" fontWeight="medium" mb={4}>
            Daily Trends
          </Text>
          <Box height="300px">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={stats.dailyStats}>
                <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
                <XAxis
                  dataKey="date"
                  angle={timeRange === "all" ? -45 : 0}
                  textAnchor={timeRange === "all" ? "end" : "middle"}
                  height={timeRange === "all" ? 60 : 30}
                />
                <YAxis
                  yAxisId="calories"
                  orientation="left"
                  label={{
                    value: "Calories",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <YAxis
                  yAxisId="macros"
                  orientation="right"
                  label={{ value: "Grams", angle: 90, position: "insideRight" }}
                />
                <Tooltip
                  formatter={(value: number, name: string) => {
                    if (name === "calories")
                      return [`${value} cal`, "Calories"];
                    return [`${value}g`, name];
                  }}
                />
                <Legend />
                <Line
                  yAxisId="calories"
                  type="monotone"
                  dataKey="calories"
                  stroke="#718096"
                  strokeWidth={2}
                  dot={false}
                  name="Calories"
                />
                <Line
                  yAxisId="macros"
                  type="monotone"
                  dataKey="protein"
                  stroke={COLORS.protein}
                  strokeWidth={2}
                  dot={false}
                  name="Protein"
                />
                <Line
                  yAxisId="macros"
                  type="monotone"
                  dataKey="carbs"
                  stroke={COLORS.carbs}
                  strokeWidth={2}
                  dot={false}
                  name="Carbs"
                />
                <Line
                  yAxisId="macros"
                  type="monotone"
                  dataKey="fat"
                  stroke={COLORS.fat}
                  strokeWidth={2}
                  dot={false}
                  name="Fat"
                />
                <Line
                  yAxisId="macros"
                  type="monotone"
                  dataKey="fiber"
                  stroke={COLORS.fiber}
                  strokeWidth={2}
                  dot={false}
                  name="Fiber"
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>

        <Box
          gridColumn="span 12"
          bg="white"
          p={6}
          borderRadius="lg"
          borderWidth="1px"
        >
          <Flex justify="space-between" align="center" mb={4}>
            <Text fontSize="lg" fontWeight="medium">
              Daily Breakdown
            </Text>
            <ButtonGroup spacing={2} size="sm">
              <Link to={`/labs/${clientId}/food-history`}>
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  leftIcon={
                    <ArrowTopRightOnSquareIcon
                      style={{ width: "16px", height: "16px" }}
                    />
                  }
                >
                  View Food Details
                </Button>
              </Link>
              <Button
                variant="ghost"
                colorScheme="gray"
                leftIcon={
                  <ArrowDownTrayIcon
                    style={{ width: "16px", height: "16px" }}
                  />
                }
                onClick={() =>
                  downloadCSV(
                    stats.reversedDailyStats ?? [],
                    `${getExportFileName(timeRange)}.csv`
                  )
                }
              >
                Export CSV
              </Button>
            </ButtonGroup>
          </Flex>
          <Box overflowX="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th isNumeric>Calories</Th>
                  <Th isNumeric>Protein (g)</Th>
                  <Th isNumeric>Carbs (g)</Th>
                  <Th isNumeric>Fat (g)</Th>
                  <Th isNumeric>Fiber (g)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(stats.reversedDailyStats ?? []).map((day) => (
                  <Tr key={day.date}>
                    <Td>{day.date}</Td>
                    <Td isNumeric>{day.calories}</Td>
                    <Td isNumeric>{day.protein}</Td>
                    <Td isNumeric>{day.carbs}</Td>
                    <Td isNumeric>{day.fat}</Td>
                    <Td isNumeric>{day.fiber}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

export default NutritionalTrends;
