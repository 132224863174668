const isEnvironment = (origin: string): boolean => {
  const apiUrl = process.env.REACT_APP_API_URL;
  return !!apiUrl && apiUrl.includes(origin);
};

export const IS_PRODUCTION = isEnvironment("app-backend.speedback.com");

export const IS_SANDBOX = isEnvironment("sandbox-backend.speedback.com");

export const IS_STAGING = isEnvironment("staging-backend.speedback.com");

export const IS_DEVELOPMENT = isEnvironment("localhost");

export const getEnvironmentName = () => {
  if (IS_DEVELOPMENT) return "development";
  if (IS_SANDBOX) return "sandbox";
  if (IS_STAGING) return "staging";
  if (IS_PRODUCTION) return "production";
  return "unknown";
};
