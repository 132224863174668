import { Box, Text, useDisclosure, Button, Icon } from "@chakra-ui/react";
import { Chat } from "../../../types/chat";
import { formatLocalTime } from "../../../utils/date";
import { ChatMediaContent } from "./ChatMediaContent";
import { ChatMediaModal } from "./ChatMediaModal";
import { FiExternalLink } from "react-icons/fi";

interface ChatBubbleProps {
  message: Chat;
}

export const ChatBubble = ({ message }: ChatBubbleProps) => {
  const isOutgoing = message.direction === "outgoing";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (message.mediaUrl) {
      window.open(message.mediaUrl, "_blank");
    }
  };

  const handleOpenUrlSuffix = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (message.payload?.urlSuffix) {
      const baseUrl = window.location.origin;
      const fullUrl = message.payload.urlSuffix.startsWith("/")
        ? `${baseUrl}${message.payload.urlSuffix}`
        : `${baseUrl}/${message.payload.urlSuffix}`;

      window.open(fullUrl, "_blank");
    }
  };

  return (
    <Box
      alignSelf={isOutgoing ? "flex-end" : "flex-start"}
      maxW={{ base: "85%", md: "70%" }}
      position="relative"
    >
      <Box
        bg={isOutgoing ? "blue.700" : "gray.100"}
        color={isOutgoing ? "white" : "black"}
        px={4}
        py={3}
        borderRadius="2xl"
        borderBottomRightRadius={isOutgoing ? 0 : "2xl"}
        borderBottomLeftRadius={isOutgoing ? "2xl" : 0}
        whiteSpace="pre-wrap"
        wordBreak="break-word"
        shadow="sm"
      >
        {message.mediaType && message.mediaUrl && (
          <Box position="relative" mb={message.text ? 3 : 0}>
            <ChatMediaContent
              message={message}
              isOutgoing={isOutgoing}
              handleOpenLink={handleOpenLink}
              onOpen={onOpen}
            />
          </Box>
        )}
        {message.text && (
          <Text fontSize="sm" lineHeight="tall">
            {message.text}
          </Text>
        )}

        {message.payload?.urlSuffix && (
          <Box
            mt={2}
            borderTop="1px solid"
            borderColor={isOutgoing ? "blue.600" : "gray.300"}
            pt={2}
          >
            <Button
              size="sm"
              width="100%"
              colorScheme={isOutgoing ? "blue" : "teal"}
              bg={isOutgoing ? "blue.500" : "white"}
              color={isOutgoing ? "white" : "teal.600"}
              borderColor={isOutgoing ? "blue.400" : "teal.400"}
              variant={isOutgoing ? "solid" : "outline"}
              onClick={handleOpenUrlSuffix}
              textAlign="center"
              fontSize="sm"
              fontWeight="medium"
              borderRadius="md"
              py={1.5}
              _hover={{
                bg: isOutgoing ? "blue.600" : "gray.100",
              }}
              boxShadow="sm"
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
              letterSpacing="0.4px"
              height="auto"
            >
              Open Link
              <Icon as={FiExternalLink} boxSize={3.5} />
            </Button>
          </Box>
        )}
      </Box>
      <Text
        fontSize="xs"
        color="gray.500"
        mt={1}
        textAlign={isOutgoing ? "right" : "left"}
        px={1}
      >
        {formatLocalTime(message.createdAt)}
      </Text>

      {/* Full-size image modal */}
      {message.mediaType && message.mediaUrl && (
        <ChatMediaModal isOpen={isOpen} onClose={onClose} message={message} />
      )}
    </Box>
  );
};
