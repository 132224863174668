import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
} from "@chakra-ui/react";
import { Chat } from "../../../types/chat";

interface ChatMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: Chat;
}

export const ChatMediaModal = ({
  isOpen,
  onClose,
  message,
}: ChatMediaModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
      <ModalOverlay
        bg="blackAlpha.800"
        backdropFilter="blur(5px)"
        onClick={onClose}
      />
      <ModalContent
        bg="transparent"
        boxShadow="none"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalBody
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={0}
          cursor="zoom-out"
          onClick={onClose}
        >
          {message.mediaType === "image" && (
            <Image
              src={message.mediaUrl}
              alt="Message attachment"
              maxH="90vh"
              maxW="90vw"
              objectFit="contain"
              onClick={(e) => e.stopPropagation()}
              cursor="default"
              borderRadius="lg"
              draggable={false}
            />
          )}
          {message.mediaType === "audio" && (
            <audio src={message.mediaUrl} controls />
          )}
          {message.mediaType === "video" && (
            <video src={message.mediaUrl} controls />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
