import { Flex, Image } from "@chakra-ui/react";

export const LogoWithWords = () => {
  return (
    <Flex
      w={"full"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Image
        height={"40px"}
        src="/speedback_logo.svg"
        alt="Speedback"
      />
    </Flex>
  );
};
