import {
  Box,
  Heading,
  Text,
  HStack,
  Flex,
  VStack,
  Grid,
  GridItem,
  Button,
} from "@chakra-ui/react";
import { LabFoodDto } from "../../types/lab";
import { useMemo } from "react";
import {
  ChartBarIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { subDays } from "date-fns";
import { Link } from "react-router-dom";

interface NutritionalTrendsProps {
  foods: LabFoodDto[];
  isLoading?: boolean;
  patientId: string;
}

const COLORS = {
  protein: "#3182CE", // blue
  carbs: "#38A169", // green
  fat: "#DD6B20", // orange
  fiber: "#805AD5", // purple
};

const NutritionalTrendsPreview = ({
  foods,
  isLoading = false,
  patientId,
}: NutritionalTrendsProps) => {
  const stats = useMemo(() => {
    const cutoffDate = subDays(new Date(), 7); // Last week's data
    const filteredFoods = foods.filter(
      (food) => new Date(food.createdAt) >= cutoffDate
    );

    if (filteredFoods.length === 0) {
      return {
        averageCalories: 0,
        macros: [],
        totalProtein: 0,
        totalCarbs: 0,
        totalFat: 0,
        totalFiber: 0,
        hasRecentData: false,
        daysWithData: 0,
      };
    }

    // Get unique days with data
    const uniqueDays = new Set(
      filteredFoods.map(
        (food) => new Date(food.createdAt).toISOString().split("T")[0]
      )
    );
    const daysWithData = uniqueDays.size;

    const totalCalories = filteredFoods.reduce(
      (sum, food) => sum + food.calories,
      0
    );
    const totalProtein = filteredFoods.reduce(
      (sum, food) => sum + food.protein,
      0
    );
    const totalCarbs = filteredFoods.reduce(
      (sum, food) => sum + food.carbohydrates,
      0
    );
    const totalFat = filteredFoods.reduce((sum, food) => sum + food.fat, 0);
    const totalFiber = filteredFoods.reduce((sum, food) => sum + food.fiber, 0);
    const totalGrams = totalProtein + totalCarbs + totalFat;

    const macros = [
      {
        name: "Protein",
        value: totalProtein,
        percentage: Math.round((totalProtein / totalGrams) * 100),
      },
      {
        name: "Carbs",
        value: totalCarbs,
        percentage: Math.round((totalCarbs / totalGrams) * 100),
      },
      {
        name: "Fat",
        value: totalFat,
        percentage: Math.round((totalFat / totalGrams) * 100),
      },
      {
        name: "Fiber",
        value: totalFiber,
        percentage: Math.round((totalFiber / totalGrams) * 25), // Scaled for visibility
      },
    ];

    return {
      averageCalories: Math.round(totalCalories / daysWithData), // Daily average based on actual days
      weeklyAverageCalories: Math.round(totalCalories / 7), // 7-day average for reference
      macros,
      totalProtein,
      totalCarbs,
      totalFat,
      totalFiber,
      hasRecentData: true,
      daysWithData,
    };
  }, [foods]);

  if (isLoading) {
    return null;
  }

  return (
    <Box
      bg="white"
      borderRadius="lg"
      borderWidth="1px"
      borderColor="gray.200"
      overflow="hidden"
    >
      <Flex
        justify="space-between"
        align="center"
        px={6}
        py={4}
        borderBottomWidth="1px"
        borderColor="gray.100"
      >
        <HStack spacing={3}>
          <ChartBarIcon
            style={{ width: "20px", height: "20px", color: "#4A5568" }}
          />
          <Heading size="md">Nutritional Trends</Heading>
        </HStack>
        <Link to={`/labs/${patientId}/nutritional-trends`}>
          <Button
            size="sm"
            variant="ghost"
            color="gray.600"
            rightIcon={
              <ArrowTopRightOnSquareIcon
                style={{ width: "16px", height: "16px" }}
              />
            }
            _hover={{ bg: "gray.50" }}
          >
            View All
          </Button>
        </Link>
      </Flex>

      {stats.hasRecentData ? (
        <>
          <Text
            px={6}
            pt={4}
            pb={2}
            fontSize="sm"
            color="gray.500"
            fontWeight="medium"
          >
            Last 7 Days
          </Text>
          <Grid templateColumns="2fr 1fr" gap={6} px={6} pb={6}>
            <GridItem>
              <Box height="160px">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={stats.macros}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={35}
                      outerRadius={50}
                      strokeWidth={1}
                      labelLine={false}
                      label={({
                        name,
                        value,
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        percent,
                      }) => {
                        const RADIAN = Math.PI / 180;
                        const radius = outerRadius * 1.2;
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                          <text
                            x={x}
                            y={y}
                            fill={
                              COLORS[name.toLowerCase() as keyof typeof COLORS]
                            }
                            textAnchor={x > cx ? "start" : "end"}
                            dominantBaseline="central"
                            fontSize="12"
                          >
                            {`${name} ${Math.round(value)}g`}
                          </text>
                        );
                      }}
                    >
                      {stats.macros.map((entry, index) => (
                        <Cell
                          key={entry.name}
                          fill={Object.values(COLORS)[index]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </GridItem>
            <GridItem>
              <VStack align="stretch" spacing={2}>
                <Box>
                  <VStack spacing={1} mb={1} alignItems="baseline">
                    <Text fontSize="sm" color="gray.500">
                      Daily Average
                    </Text>
                    <Text fontSize="xs" color="gray.400">
                      {stats.daysWithData} of 7 days
                    </Text>
                  </VStack>
                  <Text fontSize="2xl" fontWeight="semibold">
                    {stats.averageCalories.toLocaleString()}
                  </Text>
                </Box>
                <Box>
                  <VStack align="stretch" spacing={1}>
                    <HStack justify="space-between">
                      <Text fontSize="sm" color="gray.500">
                        Protein
                      </Text>
                      <HStack spacing={1} align="baseline">
                        <Text
                          fontSize="sm"
                          fontWeight="semibold"
                          color={COLORS.protein}
                        >
                          {Math.round(stats.totalProtein / stats.daysWithData)}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          g/day
                        </Text>
                      </HStack>
                    </HStack>
                    <HStack justify="space-between">
                      <Text fontSize="sm" color="gray.500">
                        Carbs
                      </Text>
                      <HStack spacing={1} align="baseline">
                        <Text
                          fontSize="sm"
                          fontWeight="semibold"
                          color={COLORS.carbs}
                        >
                          {Math.round(stats.totalCarbs / stats.daysWithData)}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          g/day
                        </Text>
                      </HStack>
                    </HStack>
                    <HStack justify="space-between">
                      <Text fontSize="sm" color="gray.500">
                        Fat
                      </Text>
                      <HStack spacing={1} align="baseline">
                        <Text
                          fontSize="sm"
                          fontWeight="semibold"
                          color={COLORS.fat}
                        >
                          {Math.round(stats.totalFat / stats.daysWithData)}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          g/day
                        </Text>
                      </HStack>
                    </HStack>
                    <HStack justify="space-between">
                      <Text fontSize="sm" color="gray.500">
                        Fiber
                      </Text>
                      <HStack spacing={1} align="baseline">
                        <Text
                          fontSize="sm"
                          fontWeight="semibold"
                          color={COLORS.fiber}
                        >
                          {Math.round(stats.totalFiber / stats.daysWithData)}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          g/day
                        </Text>
                      </HStack>
                    </HStack>
                  </VStack>
                </Box>
              </VStack>
            </GridItem>
          </Grid>
        </>
      ) : (
        <Box py={8} px={6} textAlign="center">
          <Text color="gray.500" fontSize="sm">
            No nutritional data available from the past week
          </Text>
          <Text color="gray.400" fontSize="sm" mt={1}>
            Recent meal data will appear here automatically
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default NutritionalTrendsPreview;
