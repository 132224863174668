import {
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Box,
  List,
  ListItem,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { debounce } from "lodash";
import { useCallback, useRef, useState } from "react";
import { PatientDto } from "../../types/patient";

interface ClientHeaderProps {
  onSearch: (value: string) => void;
  children?: React.ReactNode;
  mode?: "filter" | "navigate";
  patients?: PatientDto[];
  onSelectPatient?: (patient: PatientDto) => void;
}

export const ClientHeader = ({
  onSearch,
  children,
  mode = "navigate",
  patients = [],
  onSelectPatient,
}: ClientHeaderProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setShowDropdown(false),
  });

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      onSearch(value);
    }, 300),
    [onSearch]
  );

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (mode === "filter") {
      setShowDropdown(true);
    }
    debouncedSearch(value);
  };

  const handleSelectPatient = (patient: PatientDto) => {
    if (onSelectPatient) {
      onSelectPatient(patient);
      setShowDropdown(false);
      setSearchValue("");
    }
  };

  const filteredPatients =
    mode === "filter"
      ? patients.filter((patient) =>
          patient.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : [];

  return (
    <Container maxW="9xl" py={6}>
      <VStack spacing={6} align="stretch">
        {children}
        <Box position="relative" ref={dropdownRef}>
          <InputGroup size="lg">
            <InputLeftElement pointerEvents="none">
              <FaSearch style={{ color: "var(--chakra-colors-gray-500)" }} />
            </InputLeftElement>
            <Input
              placeholder="Search for other Clients"
              onChange={(e) => handleSearchChange(e.target.value)}
              bg="white"
              borderRadius="md"
              fontSize="md"
              value={searchValue}
              _placeholder={{ color: "gray.500" }}
            />
          </InputGroup>

          {mode === "filter" && showDropdown && filteredPatients.length > 0 && (
            <List
              position="absolute"
              top="100%"
              left={0}
              right={0}
              bg="white"
              boxShadow="lg"
              borderRadius="md"
              mt={2}
              maxH="300px"
              overflowY="auto"
              zIndex={10}
            >
              {filteredPatients.map((patient) => (
                <ListItem
                  key={patient.id}
                  px={4}
                  py={2}
                  cursor="pointer"
                  _hover={{ bg: "gray.50" }}
                  onClick={() => handleSelectPatient(patient)}
                >
                  <Text>{patient.name}</Text>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </VStack>
    </Container>
  );
};
