import {
  Box,
  Container,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Badge,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPatientById, getPatientList } from "../../api/patients";
import Header from "../../components/Head";
import Activity from "../../components/Patients/Activity";
import ClientDetail from "../../components/Patients/ClientDetail";
import { ROUTES, theme } from "../../constants";
import { PatientDto } from "../../types/patient";
import { formatDateToReadableString } from "../../utils/date";
import { parseISO } from "date-fns";
import { HealthDataTracker } from "../../components/HealthDataTrackers";
import { ClientHeader } from "../../components/ClientHeader";
import {
  ArrowLeftIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import { ChatWindow } from "../../components/Chat/ChatWindow/index";
import { AiOutlineSelect } from "react-icons/ai";
import { MetricConfiguration } from "../../components/MetricConfiguration";

const TABS = [
  { id: "overview", label: "Overview", Component: ClientDetail },
  { id: "trends", label: "Trends", Component: HealthDataTracker },
  { id: "monitoring", label: "Monitoring", Component: MetricConfiguration },
  { id: "records", label: "Records", Component: Activity },
] as const;

const tabStyles = {
  bg: "gray.50",
  py: 3,
  px: 4,
  color: "gray.500",
  display: "flex",
  justifyContent: "flex-start",
  borderRadius: "4px",
  transition: "all 0.2s",
  _selected: {
    color: `${theme}.900`,
    bg: `${theme}.50`,
    fontWeight: "medium",
  },
};

export default function ViewPatientInfoPage() {
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState<PatientDto>();
  const [allPatients, setAllPatients] = useState<PatientDto[]>([]);
  const [filteredPatients, setFilteredPatients] = useState<PatientDto[]>([]);
  const [hasMessages, setHasMessages] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { clientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!clientId) return;
    fetchPatientById(clientId);
    fetchAllPatients();
  }, [clientId]);

  const fetchAllPatients = async () => {
    try {
      const resp = await getPatientList({
        size: 1000,
        page: 1,
      });
      if (resp.data) {
        setAllPatients(resp.data.data);
      }
    } catch (error) {
      console.error("Error fetching all patients", error);
    }
  };

  const fetchPatientById = async (patientId: string): Promise<boolean> => {
    try {
      setLoading(true);
      if (!patientId) {
        setLoading(false);
        return false;
      }
      const resp = await getPatientById(patientId);
      const patient = resp.data;
      if (patient) {
        setPatient({
          ...patient,
          dob:
            typeof patient.dob === "string"
              ? parseISO(patient.dob)
              : patient.dob,
        });
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error("Error fetching patient", error);
    } finally {
      setLoading(false);
      return false;
    }
  };

  if (loading || !patient) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header description="View and manage your clients" />
      <ClientHeader
        mode="filter"
        patients={allPatients}
        onSearch={(value: string) => {
          const filtered = allPatients.filter((p) =>
            p.name.toLowerCase().includes(value.toLowerCase())
          );
          setFilteredPatients(filtered);
        }}
        onSelectPatient={(selectedPatient: PatientDto) => {
          navigate(`${ROUTES.CLIENTS}/${selectedPatient.id}`);
        }}
      />

      <Container maxW="9xl">
        <VStack spacing={6} align="stretch">
          {/* Back Navigation */}
          <Box
            as="button"
            display="flex"
            alignItems="center"
            gap={2}
            onClick={() => navigate(ROUTES.CLIENTS)}
            color={`${theme}.700`}
            _hover={{ color: `gray.500` }}
            transition="all 0.2s"
            w="fit-content"
          >
            <ArrowLeftIcon
              style={{ width: "20px", height: "20px", strokeWidth: 2 }}
            />
          </Box>

          {/* Client Info Row */}
          <HStack w="full" justify="space-between" align="center">
            <Heading
              textTransform="uppercase"
              fontSize="4xl"
              color={`${theme}.700`}
            >
              {patient.name}
            </Heading>
            <HStack spacing={6} align="center">
              <VStack spacing={0} align="flex-end">
                <Text fontSize="sm" color={`${theme}.600`} fontWeight="medium">
                  Last Activity
                </Text>
                <Text fontSize="sm" color={`${theme}.900`}>
                  {formatDateToReadableString(patient.lastActivity!)}
                </Text>
              </VStack>

              <Box position="relative">
                <IconButton
                  aria-label="Open chat"
                  icon={
                    <ChatBubbleLeftRightIcon
                      style={{
                        width: "24px",
                        height: "24px",
                        strokeWidth: 1.5,
                      }}
                    />
                  }
                  onClick={onOpen}
                  size="lg"
                  rounded="full"
                  colorScheme="blue"
                  variant="solid"
                  bg={`${theme}.600`}
                  _hover={{
                    bg: `${theme}.700`,
                    transform: "scale(1.05)",
                  }}
                  _active={{
                    bg: `${theme}.800`,
                    transform: "scale(0.95)",
                  }}
                  transition="all 0.2s"
                />
                {unreadCount > 0 && (
                  <Badge
                    position="absolute"
                    top="-2px"
                    right="-2px"
                    colorScheme="red"
                    borderRadius="full"
                    fontSize="xs"
                    minW="18px"
                    h="18px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontWeight="bold"
                  >
                    {unreadCount}
                  </Badge>
                )}
              </Box>
            </HStack>
          </HStack>

          {/* Main Content Tabs */}
          <Tabs variant="unstyled" w="full" colorScheme={theme}>
            <TabList
              w="full"
              display="grid"
              gridTemplateColumns="repeat(4, 1fr)"
              gap={3}
              bg="white"
            >
              {TABS.map(({ id, label }) => (
                <Tab key={id} {...tabStyles}>
                  {label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {TABS.map(({ id, Component }) => (
                <TabPanel key={id} px={0}>
                  {id === "records" ? (
                    <Component
                      patient={patient}
                      refetchPatient={() => fetchPatientById(clientId!)}
                    />
                  ) : (
                    <Component patient={patient} />
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </VStack>
      </Container>

      {/* Chat Drawer */}

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex justify="space-between" align="center">
              <Text fontSize="lg" fontWeight="medium">
                Chat History
              </Text>
              {hasMessages && (
                <HStack
                  as="button"
                  spacing={2}
                  px={3}
                  py={2}
                  borderRadius="md"
                  color={`${theme}.700`}
                  transition="all 0.2s"
                  _hover={{
                    bg: `${theme}.50`,
                  }}
                  onClick={() => {
                    onClose();
                    navigate(`${ROUTES.CHATS}?patientId=${patient?.id}`);
                  }}
                >
                  <Text fontSize="sm">Open in Chats</Text>
                  <AiOutlineSelect style={{ width: "16px", height: "16px" }} />
                </HStack>
              )}
            </Flex>
          </DrawerHeader>
          <DrawerBody p={0}>
            <ChatWindow
              patientId={patient?.id || ""}
              patientName={patient?.name || ""}
              phoneNumber={patient?.whatsappPhoneNum || ""}
              showHeader={false}
              onMessagesLoaded={(messages) => {
                setHasMessages(messages.length > 0);
                const unread = messages.filter(
                  (msg) => msg.direction === "incoming" && !msg.lastViewedAt
                ).length;
                setUnreadCount(unread);
              }}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
