import { Textarea } from "@chakra-ui/react";
import { FormTypeField } from "../../types/form";
import { useEffect, useState } from "react";

interface LongTextFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
  value: string;
}

export const LongTextFormField = ({
  field,
  handleInputChange,
  value,
}: LongTextFormFieldProps) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleTextareaChange = (key: string, value: string) => {
    setLocalValue(value);
    handleInputChange(key, value);
  };

  return (
    <Textarea
      placeholder={"Enter your response..."}
      onChange={(e) => handleTextareaChange(field.key, e.target.value)}
      value={localValue}
    />
  );
};
