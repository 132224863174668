import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Switch,
  Textarea,
  Button,
  VStack,
  HStack,
  Grid,
  Select,
  useToast,
  Text,
  Flex,
  Spinner,
  Badge,
  Icon,
  useColorModeValue,
  Circle,
  FormErrorMessage,
  Radio,
} from "@chakra-ui/react";
import {
  AwayMessageMode,
  OrganizationChatConfigDto,
  WeeklyWorkingHours,
  defaultOrganizationChatConfig,
} from "../../types/organizationChatConfig";
import {
  getOrganizationChatConfig,
  updateOrganizationChatConfig,
  createOrganizationChatConfig,
} from "../../api/organizationChatConfig";
import { useStaffStore } from "../../store/staffStore";
import { FiSend, FiClock } from "react-icons/fi";
import { Formik, Form, Field, FormikProps } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  awayMessageEnabled: Yup.boolean(),
  awayMessageTemplate: Yup.string().when("awayMessageEnabled", {
    is: true,
    then: (schema) =>
      schema.required("Away message cannot be empty when enabled"),
    otherwise: (schema) => schema.optional(),
  }),
  timezone: Yup.string().required("Timezone is required"),
  weeklyWorkingHours: Yup.object().test(
    "valid-hours",
    "End time must be after start time for each enabled day",
    function (value) {
      if (!value) return true;

      const weeklyHours = value as unknown as WeeklyWorkingHours;

      const timeToMinutes = (timeString: string): number => {
        const [hours, minutes] = timeString.split(":").map(Number);
        return hours * 60 + minutes;
      };

      for (const day in weeklyHours) {
        if (Object.prototype.hasOwnProperty.call(weeklyHours, day)) {
          const dayConfig = weeklyHours[day as keyof WeeklyWorkingHours];
          if (dayConfig.enabled) {
            const startMinutes = timeToMinutes(dayConfig.startTime);
            const endMinutes = timeToMinutes(dayConfig.endTime);

            if (startMinutes >= endMinutes) {
              return false;
            }
          }
        }
      }
      return true;
    }
  ),
});

const ChatSettings: React.FC = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [configExists, setConfigExists] = useState(false);
  const [initialConfig, setInitialConfig] = useState<OrganizationChatConfigDto>(
    defaultOrganizationChatConfig
  );

  const { currentStaff } = useStaffStore();
  const organizationId = currentStaff?.organizationId;

  const sectionBg = useColorModeValue("gray.50", "gray.700");
  const subtitleColor = useColorModeValue("gray.600", "gray.400");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeDayBg = useColorModeValue("blue.500", "blue.400");
  const inactiveDayBg = useColorModeValue("gray.200", "gray.600");
  const activeDayColor = useColorModeValue("white", "white");
  const inactiveDayColor = useColorModeValue("gray.500", "gray.400");
  const inputBg = useColorModeValue("white", "gray.800");

  useEffect(() => {
    const fetchConfig = async () => {
      if (!organizationId) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await getOrganizationChatConfig(organizationId);

        if (response.data) {
          setInitialConfig(response.data);
          setConfigExists(true);
        } else {
          setInitialConfig(defaultOrganizationChatConfig);
          setConfigExists(false);
        }
      } catch (err: any) {
        toast({
          title: "Warning",
          description:
            "Could not load saved settings. Using default values instead.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        setInitialConfig(defaultOrganizationChatConfig);
        setConfigExists(false);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, [organizationId]);

  const handleSubmit = async (
    values: OrganizationChatConfigDto,
    { setSubmitting }: any
  ) => {
    if (!organizationId) {
      toast({
        title: "Error",
        description: "Organization ID is missing. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setSubmitting(false);
      return;
    }

    try {
      const orderedWeeklyHours = {
        monday: values.weeklyWorkingHours.monday,
        tuesday: values.weeklyWorkingHours.tuesday,
        wednesday: values.weeklyWorkingHours.wednesday,
        thursday: values.weeklyWorkingHours.thursday,
        friday: values.weeklyWorkingHours.friday,
        saturday: values.weeklyWorkingHours.saturday,
        sunday: values.weeklyWorkingHours.sunday,
      };

      const configData = {
        awayMessageEnabled: values.awayMessageEnabled,
        awayMessageTemplate: values.awayMessageTemplate,
        timezone: values.timezone,
        weeklyWorkingHours: orderedWeeklyHours,
        awayMessageMode: values.awayMessageMode,
      };

      let response;

      if (configExists) {
        response = await updateOrganizationChatConfig(
          organizationId,
          configData
        );
      } else {
        response = await createOrganizationChatConfig({
          organizationId,
          ...configData,
        });

        if (response.data) {
          setConfigExists(true);
        }
      }

      if (response.data) {
        toast({
          title: "Settings saved",
          description: "Your chat settings have been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setInitialConfig(values);
      } else {
        toast({
          title: "Error saving settings",
          description:
            response.message ||
            "There was a problem saving your settings. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      toast({
        title: "Error saving settings",
        description:
          err.message ||
          "There was a problem saving your settings. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const orderedDays: Array<keyof WeeklyWorkingHours & string> = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const dayAbbreviations: Record<string, string> = {
    monday: "M",
    tuesday: "T",
    wednesday: "W",
    thursday: "T",
    friday: "F",
    saturday: "S",
    sunday: "S",
  };

  const timeOptions: string[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      timeOptions.push(`${formattedHour}:${formattedMinute}`);
    }
  }

  const timezoneOptions = useMemo(
    () => [
      { value: "Asia/Singapore", label: "Singapore" },
      { value: "Australia/Sydney", label: "Sydney" },
      { value: "Australia/Melbourne", label: "Melbourne" },
      { value: "Australia/Perth", label: "Perth" },
      { value: "Pacific/Auckland", label: "Auckland (New Zealand)" },
      { value: "America/New_York", label: "New York (US Eastern)" },
      { value: "America/Los_Angeles", label: "Los Angeles (US Pacific)" },
    ],
    []
  );

  if (loading) {
    return (
      <Flex justify="center" align="center" minH="200px">
        <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
      </Flex>
    );
  }

  return (
    <Formik
      initialValues={initialConfig}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps: FormikProps<OrganizationChatConfigDto>) => {
        const {
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        } = formikProps;

        const handleDayToggle = (day: keyof WeeklyWorkingHours) => {
          setFieldValue(
            `weeklyWorkingHours.${day}.enabled`,
            !values.weeklyWorkingHours[day].enabled
          );
        };

        const handleTimeChange = (
          day: keyof WeeklyWorkingHours,
          field: "startTime" | "endTime",
          value: string
        ) => {
          setFieldValue(`weeklyWorkingHours.${day}.${field}`, value);
        };

        return (
          <Form onSubmit={handleSubmit}>
            <VStack spacing={4} align="stretch">
              <Flex justify="space-between" align="center">
                <Heading size="md">Chat Settings</Heading>
                <Button
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Saving"
                  type="submit"
                  size="md"
                >
                  Save Changes
                </Button>
              </Flex>

              <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6}>
                <Box bg={sectionBg} p={4} borderRadius="md">
                  <Flex align="center" justify="space-between" mb={3}>
                    <Flex align="center">
                      <Icon as={FiSend} mr={2} color="blue.500" />
                      <Heading size="sm">Away Message</Heading>
                    </Flex>
                    <Badge
                      colorScheme={values.awayMessageEnabled ? "green" : "gray"}
                      variant="subtle"
                      fontSize="sm"
                      px={2}
                      py={1}
                      borderRadius="md"
                    >
                      {values.awayMessageEnabled ? "Enabled" : "Disabled"}
                    </Badge>
                  </Flex>

                  <FormControl display="flex" alignItems="center" mb={3}>
                    <FormLabel
                      htmlFor="away-message-toggle"
                      mb="0"
                      fontSize="md"
                    >
                      Enable Away Message
                    </FormLabel>
                    <Field name="awayMessageEnabled">
                      {({ field }: any) => (
                        <Switch
                          id="away-message-toggle"
                          isChecked={field.value}
                          onChange={() =>
                            setFieldValue(
                              "awayMessageEnabled",
                              !values.awayMessageEnabled
                            )
                          }
                          colorScheme="blue"
                          size="md"
                        />
                      )}
                    </Field>
                  </FormControl>

                  <FormControl mb={3} isDisabled={!values.awayMessageEnabled}>
                    <FormLabel htmlFor="away-message-mode" fontSize="sm">
                      When should the away message be sent?
                    </FormLabel>
                    <Field name="awayMessageMode">
                      {({ field }: any) => (
                        <HStack spacing={6}>
                          <Radio
                            id="outside-hours"
                            value={AwayMessageMode.OUTSIDE_WORKING_HOURS}
                            isChecked={
                              field.value ===
                              AwayMessageMode.OUTSIDE_WORKING_HOURS
                            }
                            onChange={() =>
                              setFieldValue(
                                "awayMessageMode",
                                AwayMessageMode.OUTSIDE_WORKING_HOURS
                              )
                            }
                            colorScheme="blue"
                          >
                            <Text fontSize="sm">Outside office hours only</Text>
                          </Radio>
                          <Radio
                            id="always-send"
                            value={AwayMessageMode.ALWAYS}
                            isChecked={field.value === AwayMessageMode.ALWAYS}
                            onChange={() =>
                              setFieldValue(
                                "awayMessageMode",
                                AwayMessageMode.ALWAYS
                              )
                            }
                            colorScheme="blue"
                          >
                            <Text fontSize="sm">Always</Text>
                          </Radio>
                        </HStack>
                      )}
                    </Field>
                  </FormControl>

                  <FormControl
                    isDisabled={!values.awayMessageEnabled}
                    isInvalid={
                      !!(
                        errors.awayMessageTemplate &&
                        touched.awayMessageTemplate
                      )
                    }
                  >
                    <FormLabel htmlFor="away-message-template" fontSize="sm">
                      Message Template
                    </FormLabel>
                    <Field name="awayMessageTemplate">
                      {({ field }: any) => (
                        <Textarea
                          {...field}
                          id="away-message-template"
                          placeholder="Enter your away message here..."
                          size="sm"
                          rows={5}
                          bg={inputBg}
                          borderColor={borderColor}
                        />
                      )}
                    </Field>
                    <FormErrorMessage>
                      {errors.awayMessageTemplate}
                    </FormErrorMessage>
                    <Text mt={2} fontSize="xs" color={subtitleColor}>
                      Away messages will be sent automatically when patients
                      message outside office hours.
                    </Text>
                  </FormControl>
                </Box>

                <Box bg={sectionBg} p={4} borderRadius="md">
                  <Flex align="center" mb={3}>
                    <Icon as={FiClock} mr={2} color="blue.500" />
                    <Heading size="sm">Office Hours</Heading>
                  </Flex>
                  <Box position="relative">
                    {values.awayMessageMode === AwayMessageMode.ALWAYS && (
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        bg="white"
                        opacity="0.7"
                        backdropFilter="blur(3px)"
                        zIndex="1"
                        borderRadius="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text fontWeight="medium" color="gray.600">
                          Office hours not applicable when "Always" is selected
                        </Text>
                      </Box>
                    )}
                    <FormControl
                      mb={4}
                      isInvalid={!!(errors.timezone && touched.timezone)}
                      isDisabled={
                        values.awayMessageMode === AwayMessageMode.ALWAYS
                      }
                    >
                      <FormLabel htmlFor="timezone" fontSize="sm">
                        Timezone
                      </FormLabel>
                      <Field name="timezone">
                        {({ field }: any) => (
                          <Select
                            {...field}
                            id="timezone"
                            size="sm"
                            bg={inputBg}
                            borderColor={borderColor}
                          >
                            {timezoneOptions.map((tz) => (
                              <option key={tz.value} value={tz.value}>
                                {tz.label}
                              </option>
                            ))}
                          </Select>
                        )}
                      </Field>
                      <FormErrorMessage>{errors.timezone}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mb={4}
                      isInvalid={
                        !!(
                          errors.weeklyWorkingHours &&
                          touched.weeklyWorkingHours
                        )
                      }
                      isDisabled={
                        values.awayMessageMode === AwayMessageMode.ALWAYS
                      }
                    >
                      <FormLabel htmlFor="working-days" fontSize="sm" mb={2}>
                        Working Days
                      </FormLabel>

                      <HStack spacing={1} mb={4} justify="left">
                        {orderedDays.map((day) => (
                          <Circle
                            key={day}
                            size="30px"
                            bg={
                              values.weeklyWorkingHours[day].enabled
                                ? activeDayBg
                                : inactiveDayBg
                            }
                            color={
                              values.weeklyWorkingHours[day].enabled
                                ? activeDayColor
                                : inactiveDayColor
                            }
                            fontWeight="bold"
                            fontSize="sm"
                            cursor="pointer"
                            onClick={() => handleDayToggle(day)}
                            _hover={{ opacity: 0.8 }}
                          >
                            {dayAbbreviations[day]}
                          </Circle>
                        ))}
                      </HStack>
                      <FormErrorMessage>
                        {errors.weeklyWorkingHours as string}
                      </FormErrorMessage>
                    </FormControl>

                    <Text fontSize="sm" fontWeight="medium" mb={2}>
                      Working Hours
                    </Text>

                    <VStack spacing={2} align="stretch">
                      {orderedDays
                        .filter((day) => values.weeklyWorkingHours[day].enabled)
                        .map((day) => (
                          <Grid
                            key={day}
                            templateColumns="100px 1fr 30px 1fr"
                            gap={2}
                            alignItems="center"
                            p={2}
                            borderRadius="md"
                            bg={inputBg}
                            borderWidth="1px"
                            borderColor={borderColor}
                          >
                            <Text
                              textTransform="capitalize"
                              fontSize="sm"
                              fontWeight="medium"
                            >
                              {day}
                            </Text>

                            <Select
                              value={values.weeklyWorkingHours[day].startTime}
                              onChange={(e) =>
                                handleTimeChange(
                                  day,
                                  "startTime",
                                  e.target.value
                                )
                              }
                              size="sm"
                              aria-label={`${day} start time`}
                            >
                              {timeOptions.map((time) => (
                                <option
                                  key={`start-${day}-${time}`}
                                  value={time}
                                >
                                  {time}
                                </option>
                              ))}
                            </Select>

                            <Text textAlign="center">to</Text>

                            <Select
                              value={values.weeklyWorkingHours[day].endTime}
                              onChange={(e) =>
                                handleTimeChange(day, "endTime", e.target.value)
                              }
                              size="sm"
                              aria-label={`${day} end time`}
                            >
                              {timeOptions.map((time) => (
                                <option key={`end-${day}-${time}`} value={time}>
                                  {time}
                                </option>
                              ))}
                            </Select>
                          </Grid>
                        ))}
                    </VStack>

                    {orderedDays.filter(
                      (day) => values.weeklyWorkingHours[day].enabled
                    ).length === 0 && (
                      <Text
                        fontSize="sm"
                        color={subtitleColor}
                        textAlign="center"
                        mt={2}
                      >
                        Select days above to set working hours
                      </Text>
                    )}
                  </Box>
                </Box>
              </Grid>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChatSettings;
