import { VitalType } from "../types/llm";

export const convertMetricDetailTitleToVitalType = (title: string) => {
    switch(title) {
        case 'Weight':
            return VitalType.WEIGHT
        case 'Blood Pressure':
            return VitalType.BLOOD_PRESSURE
        case 'Blood Glucose':
            return VitalType.BLOOD_GLUCOSE
    }
    return undefined;
}