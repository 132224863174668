import {
  Box,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  HStack,
  Badge,
} from "@chakra-ui/react";
import { TimeIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { MessagePurpose, MESSAGE_PURPOSE_CONFIG } from "./constants";

interface MessagePurposeSelectorProps {
  selectedPurpose: MessagePurpose;
  onChange: (purpose: MessagePurpose) => void;
  isWithinWindow: boolean;
}

export const MessagePurposeSelector = ({
  selectedPurpose,
  onChange,
  isWithinWindow,
}: MessagePurposeSelectorProps) => {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const menuBg = useColorModeValue("white", "gray.800");
  const menuHoverBg = useColorModeValue("gray.50", "gray.700");
  const selectedBg = useColorModeValue("blue.50", "blue.900");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const mutedColor = useColorModeValue("gray.500", "gray.400");
  // Update the config based on conversation window and internal access
  const config = {
    ...MESSAGE_PURPOSE_CONFIG,
    [MessagePurpose.REGULAR]: {
      ...MESSAGE_PURPOSE_CONFIG[MessagePurpose.REGULAR],
      isDisabled: !isWithinWindow,
      disabledReason: !isWithinWindow
        ? "The patient has not sent you a message in the last 24 hours"
        : undefined,
      icon: !isWithinWindow ? TimeIcon : undefined,
    },
  };

  const selectedConfig = config[selectedPurpose];

  // Filter out options that require internal access if user doesn't have it
  const availablePurposes = Object.entries(config).filter(
    ([_, value]) => !value.requiresInternalAccess
  );

  return (
    <Box>
      <Menu placement="top-end" offset={[0, 16]} gutter={0} autoSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          variant="solid"
          px={2}
          py={1}
          height="auto"
          fontSize="xs"
          color={textColor}
          borderRadius="lg"
          bg={useColorModeValue("whiteAlpha.900", "blackAlpha.700")}
          backdropFilter="blur(8px)"
          _hover={{ bg: useColorModeValue("gray.100", "whiteAlpha.100") }}
          _active={{ bg: useColorModeValue("gray.200", "whiteAlpha.200") }}
        >
          <HStack spacing={2} align="center">
            <Text fontWeight="medium">{selectedConfig.label}</Text>
            {selectedConfig.badgeText && (
              <Badge
                colorScheme={selectedConfig.badgeColor}
                variant="subtle"
                fontSize="2xs"
                px={1.5}
                py={0.5}
                borderRadius="full"
              >
                {selectedConfig.badgeText}
              </Badge>
            )}
          </HStack>
        </MenuButton>
        <MenuList
          bg={menuBg}
          borderColor={borderColor}
          boxShadow="lg"
          py={2}
          px={2}
          minW="240px"
          borderRadius="xl"
          overflow="hidden"
          transform="translateY(-8px)"
        >
          {availablePurposes.map(([key, value]) => (
            <MenuItem
              key={key}
              onClick={() =>
                !value.isDisabled && onChange(key as MessagePurpose)
              }
              bg={key === selectedPurpose ? selectedBg : "transparent"}
              _hover={{
                bg: key === selectedPurpose ? selectedBg : menuHoverBg,
              }}
              isDisabled={value.isDisabled}
              position="relative"
              px={3}
              py={2}
              borderRadius="lg"
              mb={1}
              _last={{ mb: 0 }}
            >
              <HStack spacing={2} width="100%">
                <Box flex={1}>
                  <Text
                    fontSize="sm"
                    color={value.isDisabled ? mutedColor : textColor}
                    fontWeight={key === selectedPurpose ? "medium" : "normal"}
                  >
                    {value.label}
                  </Text>
                  <Text fontSize="xs" color={mutedColor} mt={0.5}>
                    {value.isDisabled
                      ? value.disabledReason
                      : value.description}
                  </Text>
                </Box>
                {value.badgeText && (
                  <Badge
                    colorScheme={value.badgeColor}
                    variant="subtle"
                    fontSize="2xs"
                    px={1.5}
                    py={0.5}
                    borderRadius="full"
                  >
                    {value.badgeText}
                  </Badge>
                )}
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
