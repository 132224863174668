import axiosNoAuth from "./axios/noAuth";
import {
  CreateFormDto,
  RespondFormDto,
  FormDto,
  CreateFormSendDto,
} from "../types/form";
import axiosAuth from "./axios/staffAuth";
import axiosClientAuth from "./axios/clientAuth";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

async function createForm(formDto: CreateFormDto) {
  const res = await axiosAuth.post<FormDto>(`${API_URL}/form`, formDto);
  return res.data;
}

async function getFormList() {
  const res = await axiosAuth.get<FormDto[]>(`${API_URL}/form/list`);
  return res.data;
}

async function getFormById(formId: string) {
  const res = await axiosNoAuth.get<FormDto>(
    `${API_URL}/form?formId=${formId}`
  );
  return res.data;
}

async function deprecateFormById(formId: string) {
  const res = await axiosAuth.put<FormDto>(
    `${API_URL}/form/deprecate/${formId}`
  );
  return res.data;
}

async function getFormsByPatientId(patientId: string) {
  const res = await axiosAuth.get<FormDto[]>(
    `${API_URL}/form/patient/${patientId}`
  );
  return res.data;
}

/**
 * Get outstanding forms for a client
 * @param clientId The ID of the client
 * @returns A list of outstanding forms that need to be completed
 */
async function getOutstandingForms(clientId: string) {
  try {
    const res = await axiosClientAuth.get(
      `${API_URL}/form/outstanding/${clientId}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

async function sendForm(req: CreateFormSendDto): Promise<CreateFormSendDto> {
  try {
    const res = await axiosAuth.post(`${API_URL}/form/send`, req);
    return res.data;
  } catch (error) {
    throw error;
  }
}

async function respondToForm(req: RespondFormDto): Promise<RespondFormDto> {
  try {
    const res = await axiosClientAuth.post<RespondFormDto>(
      `${API_URL}/form/response`,
      req
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

export {
  createForm,
  deprecateFormById,
  getFormById,
  getFormList,
  getFormsByPatientId,
  getOutstandingForms,
  respondToForm,
  sendForm,
};
