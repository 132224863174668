import { StaffWithOrganizationDto } from "./staff";

export type RegisterParams = {
  email: string;
  password: string;
  name: string;
  organizationId: string;
};

export type LoginDto = {
  accessToken: string;
  staff: StaffWithOrganizationDto;
};

export type RefreshDto = {
  accessToken: string;
};

export type ResetPasswordDto = {
  accessToken: string;
  password: string;
};

export type LoginClientResp = {
  error?: string;
  accessToken?: string;
  clientId?: string;
  organizationId?: string;
};

export type RegisterClientResp = {
  error?: string;
  accessToken?: string;
  clientId?: string;
  organizationId?: string;
};

export type GetClientRegAuthMethods = {
  authMethods?: AuthMethod[];
  error?: string;
};

export type SendMsgResp = {
  success?: boolean;
  error?: string;
};

export enum AuthMethod {
  PASSCODE = "PASSCODE",
  WEBAUTHN = "WEBAUTHN",
}
