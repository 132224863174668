import axios from "./axios/staffAuth";
import {
  OrganizationChatConfigDto,
  CreateOrganizationChatConfigDto,
  UpdateOrganizationChatConfigDto,
} from "../types/organizationChatConfig";

const API_URL = process.env.REACT_APP_API_URL || "";
const BASE_ENDPOINT = `${API_URL}/organization-chat-config`;

export interface ApiResponse<T> {
  data?: T;
  message?: string;
  error?: any;
}

export const getOrganizationChatConfig = async (
  organizationId: string
): Promise<ApiResponse<OrganizationChatConfigDto | null>> => {
  try {
    const response = await axios.get<OrganizationChatConfigDto | null>(
      `${BASE_ENDPOINT}/${organizationId}`
    );
    return { data: response.data };
  } catch (error: any) {
    return {
      message:
        error.response?.data?.message ||
        "Failed to fetch organization chat configuration",
      error,
    };
  }
};

export const createOrganizationChatConfig = async (
  data: CreateOrganizationChatConfigDto
): Promise<ApiResponse<OrganizationChatConfigDto>> => {
  try {
    const response = await axios.post<OrganizationChatConfigDto>(
      BASE_ENDPOINT,
      data
    );
    return { data: response.data };
  } catch (error: any) {
    return {
      message:
        error.response?.data?.message ||
        "Failed to create organization chat configuration",
      error,
    };
  }
};

export const updateOrganizationChatConfig = async (
  organizationId: string,
  data: UpdateOrganizationChatConfigDto
): Promise<ApiResponse<OrganizationChatConfigDto>> => {
  try {
    const response = await axios.put<OrganizationChatConfigDto>(
      `${BASE_ENDPOINT}/${organizationId}`,
      data
    );
    return { data: response.data };
  } catch (error: any) {
    return {
      message:
        error.response?.data?.message ||
        "Failed to update organization chat configuration",
      error,
    };
  }
};

export const deleteOrganizationChatConfig = async (
  organizationId: string
): Promise<ApiResponse<void>> => {
  try {
    await axios.delete(`${BASE_ENDPOINT}/${organizationId}`);
    return { message: "Organization chat configuration deleted successfully" };
  } catch (error: any) {
    return {
      message:
        error.response?.data?.message ||
        "Failed to delete organization chat configuration",
      error,
    };
  }
};
