import { parseISO, format, formatDistanceToNow } from "date-fns";

export function calculateAge(dateOfBirth?: Date): number | string {
  if (!dateOfBirth) {
    return "";
  }
  const currentDate = new Date();
  const dob = new Date(dateOfBirth);

  let age = currentDate.getFullYear() - dob.getFullYear();

  // Check if the birthday has occurred this year
  const currentMonth = currentDate.getMonth();
  const dobMonth = dob.getMonth();
  const currentDay = currentDate.getDate();
  const dobDay = dob.getDate();

  if (
    currentMonth < dobMonth ||
    (currentMonth === dobMonth && currentDay < dobDay)
  ) {
    age--;
  }

  return age;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatDate(inputDate: string): string {
  const date = new Date(parseInt(inputDate));

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthName = months[monthIndex];

  return `${day} ${monthName} ${year}`;
}

export function getMonthStr(date: Date): string {
  const monthIndex = new Date(date).getMonth();
  const monthName = months[monthIndex];
  return monthName;
}

export const formatDateToReadableString = (inputDate: Date): string => {
  if (!inputDate) {
    return "";
  }
  const date = new Date(inputDate);

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hour = date.getHours() % 12 === 0 ? 12 : date.getHours() % 12;
  const minute = date.getMinutes().toString().padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${day} ${month} ${year}, ${hour}:${minute}${ampm}`;
};

export const convDateTimeToISO = (date: Date): string => {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.getTime() - tzoffset).toISOString().slice(0, 16);
};

/**
 * Converts an ISO date string to local time
 * @param isoString ISO date string from the backend
 * @returns Date object in local time
 */
export const toLocalTime = (isoString: string): Date => {
  try {
    const date = parseISO(isoString);
    return new Date(date);
  } catch (error) {
    console.error(`Error parsing date: ${isoString}`, error);
    return new Date(); // Return current date as fallback
  }
};

/**
 * Formats a date string to a readable time format in local timezone
 * @param isoString ISO date string from the backend
 * @returns Formatted time string (e.g., "2:30 PM")
 */
export const formatLocalTime = (isoString: string): string => {
  try {
    const localDate = toLocalTime(isoString);
    return format(localDate, "h:mm a");
  } catch (error) {
    console.error(`Error formatting date: ${isoString}`, error);
    return "Invalid time";
  }
};

/**
 * Gets relative time from now in local timezone
 * @param isoString ISO date string from the backend
 * @returns Relative time string (e.g., "2 hours ago")
 */
export const getLocalRelativeTime = (isoString: string): string => {
  try {
    const localDate = toLocalTime(isoString);
    return formatDistanceToNow(localDate, { addSuffix: true });
  } catch (error) {
    console.error(`Error getting relative time: ${isoString}`, error);
    return "Invalid time";
  }
};
