export interface DayWorkingHours {
  enabled: boolean;
  startTime: string; // Format: "HH:MM" (24-hour)
  endTime: string; // Format: "HH:MM" (24-hour)
}

export interface WeeklyWorkingHours {
  monday: DayWorkingHours;
  tuesday: DayWorkingHours;
  wednesday: DayWorkingHours;
  thursday: DayWorkingHours;
  friday: DayWorkingHours;
  saturday: DayWorkingHours;
  sunday: DayWorkingHours;
}

export enum AwayMessageMode {
  OUTSIDE_WORKING_HOURS = "OUTSIDE_WORKING_HOURS",
  ALWAYS = "ALWAYS",
}

export interface OrganizationChatConfigDto {
  id?: string;
  organizationId?: string;
  awayMessageEnabled: boolean;
  awayMessageTemplate: string;
  awayMessageMode: AwayMessageMode;
  timezone: string;
  weeklyWorkingHours: WeeklyWorkingHours;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CreateOrganizationChatConfigDto {
  organizationId: string;
  awayMessageEnabled?: boolean;
  awayMessageTemplate?: string;
  timezone?: string;
  weeklyWorkingHours?: WeeklyWorkingHours;
}

export interface UpdateOrganizationChatConfigDto {
  awayMessageEnabled?: boolean;
  awayMessageTemplate?: string;
  timezone?: string;
  weeklyWorkingHours?: WeeklyWorkingHours;
}

export const defaultWorkingHours: WeeklyWorkingHours = {
  monday: { enabled: true, startTime: "09:00", endTime: "17:00" },
  tuesday: { enabled: true, startTime: "09:00", endTime: "17:00" },
  wednesday: { enabled: true, startTime: "09:00", endTime: "17:00" },
  thursday: { enabled: true, startTime: "09:00", endTime: "17:00" },
  friday: { enabled: true, startTime: "09:00", endTime: "17:00" },
  saturday: { enabled: false, startTime: "09:00", endTime: "17:00" },
  sunday: { enabled: false, startTime: "09:00", endTime: "17:00" },
};

export const defaultOrganizationChatConfig: OrganizationChatConfigDto = {
  awayMessageEnabled: false,
  awayMessageTemplate:
    "Thank you for your message. Our team is currently unavailable. We will respond as soon as possible during our regular office hours.",
  timezone: "Asia/Singapore",
  weeklyWorkingHours: defaultWorkingHours,
  awayMessageMode: AwayMessageMode.OUTSIDE_WORKING_HOURS,
};
