import {
  Box,
  Drawer,
  DrawerContent,
  Hide,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import MobileNav from "./MobileNav";
import SidebarContent from "./SidebarContent";
import { SIDEBAR_TRANSITION_VIEWPORT_WIDTH } from "./const";
import { ROUTES } from "../../constants";

export default function SidebarWithHeader({
  children,
}: {
  children: ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const isChatsPage = location.pathname === ROUTES.CHATS;

  return (
    <Box minH="100vh" position="relative">
      <Hide below={SIDEBAR_TRANSITION_VIEWPORT_WIDTH}>
        <SidebarContent onClose={() => onClose} />
      </Hide>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box
        ml={{ base: 0, md: "72px" }}
        py={isChatsPage ? 0 : 4}
        px={isChatsPage ? 0 : 8}
      >
        {children}
      </Box>
    </Box>
  );
}
