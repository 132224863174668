import {
  Box,
  Heading,
  Text,
  HStack,
  Badge,
  Image,
  Flex,
  Button,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import { LabFoodDto } from "../../types/lab";
import { useMemo } from "react";
import { format, isToday, isYesterday } from "date-fns";
import {
  ArrowTopRightOnSquareIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { theme } from "../../constants";

interface FoodHistoryPreviewProps {
  foods: LabFoodDto[];
  isLoading?: boolean;
  patientId: string;
}

const FoodHistoryPreview = ({
  foods,
  isLoading = false,
  patientId,
}: FoodHistoryPreviewProps) => {
  const latestFoods = useMemo(() => {
    return [...foods]
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .slice(0, 3);
  }, [foods]);

  const formatDate = (date: Date) => {
    const d = new Date(date);
    if (isToday(d)) {
      return `Today, ${format(d, "h:mm a")}`;
    }
    if (isYesterday(d)) {
      return `Yesterday, ${format(d, "h:mm a")}`;
    }
    return format(d, "MMM d, h:mm a");
  };

  if (isLoading) {
    return null; // We'll skip showing the preview while loading
  }

  return (
    <Box
      bg="white"
      borderRadius="lg"
      borderWidth="1px"
      borderColor="gray.200"
      overflow="hidden"
    >
      <Flex
        justify="space-between"
        align="center"
        px={6}
        py={4}
        borderBottomWidth="1px"
        borderColor="gray.100"
      >
        <HStack spacing={3}>
          <ClipboardDocumentListIcon
            style={{ width: "24px", height: "24px", color: "#4A5568" }}
          />
          <Heading size="md">Food History</Heading>
        </HStack>
        <Link to={`/labs/${patientId}/food-history`}>
          <Button
            size="sm"
            variant="ghost"
            color="gray.600"
            rightIcon={
              <ArrowTopRightOnSquareIcon
                style={{ width: "16px", height: "16px" }}
              />
            }
            _hover={{ bg: "gray.50" }}
          >
            View All
          </Button>
        </Link>
      </Flex>

      <VStack
        spacing={0}
        align="stretch"
        divider={<Box h="1px" bg="gray.100" />}
      >
        {latestFoods.length > 0 ? (
          latestFoods.map((food) => (
            <Box
              key={food.id}
              p={4}
              transition="all 0.2s"
              _hover={{ bg: "gray.50" }}
            >
              <HStack spacing={3} align="flex-start">
                <Tooltip label={food.description} placement="top">
                  <Image
                    src={food.mediaUrl}
                    alt={food.dishName}
                    boxSize="48px"
                    objectFit="cover"
                    borderRadius="md"
                    fallbackSrc="https://via.placeholder.com/48"
                  />
                </Tooltip>
                <Box flex="1">
                  <HStack justify="space-between" align="flex-start" mb={1}>
                    <Text fontWeight="medium" fontSize="sm">
                      {food.dishName}
                    </Text>
                    <Text fontSize="xs" color="gray.500">
                      {formatDate(food.createdAt)}
                    </Text>
                  </HStack>
                  <HStack spacing={3}>
                    <Text fontSize="sm" color="gray.600">
                      {food.calories} cal
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      {food.protein}g protein
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      {food.carbohydrates}g carbs
                    </Text>
                  </HStack>
                </Box>
              </HStack>
            </Box>
          ))
        ) : (
          <Box py={8} px={4} textAlign="center">
            <Text color="gray.500">No food history available</Text>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default FoodHistoryPreview;
