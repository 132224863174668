import { Input } from "@chakra-ui/react";
import { FormTypeField } from "../../types/form";
import { useState } from "react";

interface ShortTextFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
  value: string;
}

export const ShortTextFormField = ({
  field,
  handleInputChange,
  value,
}: ShortTextFormFieldProps) => {
  const [localValue, setLocalValue] = useState(value);

  const handleChange = (newValue: string) => {
    setLocalValue(newValue);
    handleInputChange(field.key, newValue);
  };
  
  return (
    <Input
      type="text"
      value={localValue}
      placeholder={"Enter your response..."}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};
