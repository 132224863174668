import { useEffect } from "react";
import flagsmith, { IFlags } from "flagsmith"; // You'll need to install and initialize flagsmith
import { useClientStore } from "../store/clientStore";
import { useStaffStore } from "../store/staffStore";
import { useFeatureStore, FeatureData } from "../store/featureStore";

const parseFeatureMetadata = (
  key: string,
  value: string | number | boolean | null | undefined
) => {
  let metadata = undefined;
  if (!!value) {
    try {
      metadata = JSON.parse(value as string);
    } catch (error) {
      console.error("Error parsing metadata for feature", key, error);
    }
  }
  return metadata;
};

const parseFeatures = (flags: IFlags) => {
  return Object.keys(flags).reduce(
    (acc: Record<string, FeatureData>, key: string) => {
      const metadata = parseFeatureMetadata(key, flags[key].value);
      acc[key] = {
        enabled: flags[key].enabled,
        metadata: metadata,
      };
      return acc;
    },
    {}
  );
};

export function useFlagsmithFeatures() {
  const { clientId, organizationId } = useClientStore();
  const { currentStaff } = useStaffStore();
  const {
    setClientFeatures,
    setStaffFeatures,
    clearClientFeatures,
    clearStaffFeatures,
  } = useFeatureStore();

  // Watch for client ID changes
  useEffect(() => {
    const identifyClient = async () => {
      if (clientId && organizationId) {
        try {
          // Client identified
          await flagsmith.identify(`client-${clientId}`, {
            organizationId: organizationId,
          });
          const flags = await flagsmith.getAllFlags();
          const featureFlags = parseFeatures(flags);
          setClientFeatures(featureFlags);
        } catch (error) {
          console.error("Error identifying client with Flagsmith:", error);
        }
      } else {
        // No client identified, clear client flags
        clearClientFeatures();
      }
    };

    identifyClient();
  }, [clientId, organizationId, setClientFeatures, clearClientFeatures]);

  // Watch for staff changes
  useEffect(() => {
    const identifyStaff = async () => {
      if (currentStaff?.id) {
        try {
          // Staff identified
          await flagsmith.identify(`staff-${currentStaff.id}`, {
            organizationId: currentStaff.organizationId,
            email: currentStaff.email,
          });
          const flags = await flagsmith.getAllFlags();
          const featureFlags = parseFeatures(flags);
          setStaffFeatures(featureFlags);
        } catch (error) {
          console.error("Error identifying staff with Flagsmith:", error);
        }
      } else {
        // No staff identified, clear staff flags
        clearStaffFeatures();
      }
    };

    identifyStaff();
  }, [currentStaff?.id, setStaffFeatures, clearStaffFeatures]);
}
