import { useFeatureEnabled } from "./useFeatureEnabled";
import { MetricConfWithDetail } from "../types/clientpass";
import { FeatureName } from "../components/FeatureToggle/constants";
import { useMemo } from "react";
import { FeatureData } from "../store/featureStore";

const getExcludedMetrics = (featureData: FeatureData[]) => {
  const excludedMetrics: string[] = featureData
    .filter(({ enabled, metadata }) => !enabled && !!metadata?.metrics)
    .map(({ metadata }) => metadata?.metrics)
    .flat();

  return excludedMetrics;
};

/**
 * Hook that filters metrics to be displayed based on feature flag
 */
export const useFilteredMetrics = (
  metrics: MetricConfWithDetail[] | undefined
) => {
  const wearableFeature = useFeatureEnabled(FeatureName.WEARABLES);

  // Filter metrics if wearables is disabled and has metrics metadata
  const filteredMetrics = useMemo(
    () =>
      metrics?.filter((metric) => {
        // Get excluded metrics based on feature flags and metadata
        const excludedMetrics = getExcludedMetrics([wearableFeature]);
        const metricTitle = metric.detail.title.toLowerCase();
        return !excludedMetrics.some((excludedMetric) =>
          metricTitle.includes(excludedMetric.toLowerCase())
        );
      }),
    [metrics, wearableFeature.enabled]
  );

  return filteredMetrics;
};
