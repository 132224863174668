import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Text,
  Box,
  Input,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import {
  MessageTemplate,
  CreateMessageTemplateDto,
} from "../../../types/messageTemplate";
import { TEMPLATE_CATEGORIES, TemplateCategory } from "./constants";
import { theme } from "../../../constants";

interface TemplateManagerProps {
  isOpen: boolean;
  onClose: () => void;
  templates: MessageTemplate[];
  onEdit?: (template: MessageTemplate) => void;
  onDelete?: (templateId: string) => void;
  onCreateTemplate: (template: CreateMessageTemplateDto) => Promise<void>;
  isCreating: boolean;
}

interface ExpandableTextProps {
  content: string;
  color?: string;
}

const ExpandableText = ({ content, color }: ExpandableTextProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const isTruncated =
          textRef.current.scrollHeight > textRef.current.clientHeight;
        setIsTextTruncated(isTruncated);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [content]);

  return (
    <Box>
      <Text
        ref={textRef}
        fontSize="sm"
        color={color || "gray.600"}
        noOfLines={isExpanded ? undefined : 2}
        mb={isTextTruncated ? 1 : 0}
      >
        {content}
      </Text>
      {isTextTruncated && (
        <Button
          size="xs"
          variant="link"
          color={`${theme}.500`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Show less" : "Show more"}
        </Button>
      )}
    </Box>
  );
};

export const TemplateManager = ({
  isOpen,
  onClose,
  templates,
  onEdit,
  onDelete,
  onCreateTemplate,
  isCreating,
}: TemplateManagerProps) => {
  const [selectedCategory, setSelectedCategory] = useState<
    TemplateCategory | "all"
  >("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [editingTemplate, setEditingTemplate] =
    useState<MessageTemplate | null>(null);
  const [deleteTemplateId, setDeleteTemplateId] = useState<string | null>(null);
  const [formData, setFormData] = useState<CreateMessageTemplateDto>({
    name: "",
    content: "",
    category: "Health Management",
  });

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();

  const cancelRef = useRef<HTMLButtonElement>(null);
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const filteredTemplates = templates.filter((template) => {
    const matchesSearch =
      template.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      template.content.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory =
      selectedCategory === "all" || template.category === selectedCategory;

    return matchesSearch && matchesCategory;
  });

  const handleEditClick = (template: MessageTemplate) => {
    setEditingTemplate(template);
    onEditOpen();
  };

  const handleDeleteClick = (templateId: string) => {
    setDeleteTemplateId(templateId);
    onDeleteOpen();
  };

  const handleEditSubmit = async () => {
    if (editingTemplate && onEdit) {
      await onEdit(editingTemplate);
      onEditClose();
      setEditingTemplate(null);
    }
  };

  const handleDeleteConfirm = async () => {
    if (deleteTemplateId && onDelete) {
      await onDelete(deleteTemplateId);
      onDeleteClose();
      setDeleteTemplateId(null);
    }
  };

  const handleCreateTemplate = async () => {
    await onCreateTemplate(formData);
    setFormData({ name: "", content: "", category: "Health Management" });
    onCreateClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
        scrollBehavior="inside"
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent maxW="1200px" h="80vh">
          <ModalHeader>
            <HStack justify="space-between" align="center">
              <Text>Manage Templates</Text>
              <HStack>
                <Button variant="ghost" size="sm" onClick={onCreateOpen}>
                  + New Template
                </Button>
                <Button variant="ghost" size="sm" onClick={onClose}>
                  Close
                </Button>
              </HStack>
            </HStack>
          </ModalHeader>

          <ModalBody px={2} display="flex">
            <Box
              w="20%"
              minW="220px"
              maxW="300px"
              borderRight="1px"
              borderColor={borderColor}
              p={4}
              position="sticky"
              top={0}
              h="fit-content"
              bg={useColorModeValue("white", "gray.800")}
            >
              <Text fontWeight="medium" mb={4}>
                Categories
              </Text>
              <VStack align="stretch" spacing={2}>
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  size="sm"
                  fontWeight={selectedCategory === "all" ? "medium" : "normal"}
                  _hover={{ bg: `${theme}.50` }}
                  onClick={() => setSelectedCategory("all")}
                  bg={
                    selectedCategory === "all" ? `${theme}.50` : "transparent"
                  }
                  whiteSpace="normal"
                  textAlign="left"
                  height="auto"
                  p={2}
                  w="full"
                >
                  All Templates
                </Button>
                {TEMPLATE_CATEGORIES.map((category: TemplateCategory) => (
                  <Button
                    key={category}
                    variant="ghost"
                    justifyContent="flex-start"
                    size="sm"
                    fontWeight={
                      selectedCategory === category ? "medium" : "normal"
                    }
                    _hover={{ bg: `${theme}.50` }}
                    onClick={() => setSelectedCategory(category)}
                    bg={
                      selectedCategory === category
                        ? `${theme}.50`
                        : "transparent"
                    }
                    whiteSpace="normal"
                    textAlign="left"
                    height="auto"
                    p={2}
                    w="full"
                  >
                    {category}
                  </Button>
                ))}
              </VStack>
            </Box>
            <VStack align="flex-start">
              <Box px={4} w="full">
                <Input
                  placeholder="Search templates..."
                  size="md"
                  maxW="400px"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Box>

              <VStack
                flex={1}
                align="stretch"
                spacing={4}
                p={4}
                pb={8}
                overflowY="auto"
              >
                {filteredTemplates.map((template) => (
                  <Box
                    key={template.id}
                    p={4}
                    borderWidth={1}
                    borderColor={borderColor}
                    borderRadius="md"
                    _hover={{ borderColor: `${theme}.500` }}
                  >
                    <HStack justify="space-between" mb={2}>
                      <VStack align="flex-start" spacing={0}>
                        <Text fontWeight="medium">{template.name}</Text>
                        <Text fontSize="sm" color="gray.500">
                          {template.category}
                        </Text>
                      </VStack>
                      <HStack>
                        <Button
                          size="sm"
                          variant="ghost"
                          colorScheme={theme}
                          onClick={() => handleEditClick(template)}
                        >
                          Edit
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => handleDeleteClick(template.id)}
                        >
                          Delete
                        </Button>
                      </HStack>
                    </HStack>
                    <ExpandableText content={template.content} />
                  </Box>
                ))}
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Template Name</FormLabel>
                <Input
                  value={editingTemplate?.name || ""}
                  onChange={(e) =>
                    setEditingTemplate((prev) =>
                      prev ? { ...prev, name: e.target.value } : null
                    )
                  }
                  placeholder="Enter template name"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Category</FormLabel>
                <Select
                  value={editingTemplate?.category || ""}
                  onChange={(e) =>
                    setEditingTemplate((prev) =>
                      prev
                        ? {
                            ...prev,
                            category: e.target.value as TemplateCategory,
                          }
                        : null
                    )
                  }
                >
                  {TEMPLATE_CATEGORIES.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Content</FormLabel>
                <Textarea
                  value={editingTemplate?.content || ""}
                  onChange={(e) =>
                    setEditingTemplate((prev) =>
                      prev ? { ...prev, content: e.target.value } : null
                    )
                  }
                  placeholder="Enter template content"
                  rows={4}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onEditClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleEditSubmit}
              isLoading={isCreating}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Template
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Template Name</FormLabel>
                <Input
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  placeholder="Enter template name"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Category</FormLabel>
                <Select
                  value={formData.category}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      category: e.target.value as TemplateCategory,
                    })
                  }
                >
                  {TEMPLATE_CATEGORIES.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Content</FormLabel>
                <Textarea
                  value={formData.content}
                  onChange={(e) =>
                    setFormData({ ...formData, content: e.target.value })
                  }
                  placeholder="Enter template content"
                  rows={4}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCreateClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleCreateTemplate}
              isLoading={isCreating}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
