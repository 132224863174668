import {
  VStack,
  Text,
  useToast,
  Spinner,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Icon,
  Center,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PatientDto } from "../../types/patient";
import { getPatientById } from "../../api/patients";
import { getClientPassConfig } from "../../api/clientpass";
import { MetricCard } from "../../components/MetricCard";
import { MetricConfWithDetail } from "../../types/clientpass";
import { ShareAndroid, Copy, Camera } from "react-coolicons";
import { ROUTES } from "../../constants";
import { extractImageData } from "../../api/llm";
import { ImageExtractedMeasurement, VitalType } from "../../types/llm";
import _ from "lodash";
import { convertMetricDetailTitleToVitalType } from "../../utils/metricDetail";
import { Logo } from "../../Logo";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { generateAuth } from "../../api/terra";
import { FeatureName } from "../../components/FeatureToggle/constants";
import { useFeatureEnabled } from "../../hooks/useFeatureEnabled";
import { useFilteredMetrics } from "../../hooks/useFilteredMetrics";

const ClientPass = () => {
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState<PatientDto>();
  const [metrics, setMetrics] = useState<MetricConfWithDetail[]>();
  const toast = useToast();
  const { clientId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleShare = async () => {
    onOpen();
  };

  const handleCopy = async () => {
    const viewVitalsUrl = window.location.href.replace(
      ROUTES.CLIENT_PASS,
      ROUTES.VIEW_METRIC_DATA
    );
    const message =
      "I've started recording my health information on Speedback, my digital health passport. Click here to view my information: " +
      viewVitalsUrl;

    try {
      await navigator.clipboard.writeText(message);
      toast({
        title: "Message copied!",
        description: "You can now share this with your trusted caregiver",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to copy message",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchClientById = async (id: string) => {
      try {
        setLoading(true);
        const resp = await getPatientById(id);
        if (resp.data) {
          setPatient(resp.data);
          setLoading(false);
          return;
        }
      } catch (error: any) {
        toast({
          title: "Failed to fetch patient",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
    };

    const fetchClientPassConfig = async (id: string) => {
      setLoading(true);
      const resp = await getClientPassConfig(id);
      if (resp.message || !resp.data) {
        toast({
          title: "Failed to fetch vitals",
          description: resp.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      setMetrics(resp.data.metrics);
      setLoading(false);
    };
    if (clientId) {
      fetchClientById(clientId);
      fetchClientPassConfig(clientId);
    }
  }, [clientId]);

  return (
    <Box minH="100vh" bg="gray.50">
      <Box pt={4} px={4} pb={0} maxW="container.sm" mx="auto">
        <Logo />
        {loading ? (
          <Center h="80vh">
            <Spinner size="xl" color="blue.500" />
          </Center>
        ) : (
          <>
            <ClientPassBody
              patient={patient}
              metrics={metrics}
              clientId={clientId as string}
              onShare={handleShare}
            />
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Share with Caregivers</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <Text mb={4}>
                    Invite caregivers to your health passport so they know how
                    you're doing, and can add measurements on your behalf.
                  </Text>
                  <Alert status="warning" mb={4}>
                    <AlertIcon />
                    Please only share this link and your PIN with trusted
                    caregivers.
                  </Alert>
                  <Button
                    leftIcon={<Copy />}
                    onClick={handleCopy}
                    colorScheme="blue"
                    size="lg"
                    width="full"
                  >
                    Copy Link
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}
      </Box>
    </Box>
  );
};

interface ClientPassBodyProps {
  patient?: PatientDto;
  metrics?: MetricConfWithDetail[];
  clientId: string;
  onShare: () => void;
}

const ClientPassBody = (props: ClientPassBodyProps) => {
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const toast = useToast();
  const imageUploadRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { enabled: isWearablesEnabled } = useFeatureEnabled(
    FeatureName.WEARABLES
  );
  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    imageUploadRef.current?.click();
  };
  const filteredMetrics = useFilteredMetrics(props.metrics);
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type.startsWith("image/")) {
        await handleImageUpload(file);
      } else {
        console.error("Please upload a valid image file.");

        toast({
          title: "Invalid file type",
          description: "Please upload a valid image file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      setIsImageProcessing(true);
      const imageExtractedMeasurement = (await extractImageData(
        file
      )) as ImageExtractedMeasurement;
      console.log(imageExtractedMeasurement);

      let metricToNavigate: MetricConfWithDetail[] | undefined;
      switch (imageExtractedMeasurement.measurement.type) {
        case VitalType.BLOOD_GLUCOSE:
        case VitalType.BLOOD_PRESSURE:
        case VitalType.WEIGHT:
          metricToNavigate = filteredMetrics?.filter(
            (metric) =>
              convertMetricDetailTitleToVitalType(metric.detail.title) ===
              imageExtractedMeasurement.measurement.type
          );
          if (metricToNavigate?.length) {
            navigate(
              `${ROUTES.METRIC_FORM}/${metricToNavigate[0].detail.id}/${props.clientId}`,
              {
                state: { imageExtractedMeasurement },
              }
            );
          }
          toast({
            title: "Scanning complete!",
            description: `We've detected a ${_.startCase(
              imageExtractedMeasurement.measurement.type
            )} reading, which we have auto-filled. Please review it before submitting.`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          break;
        default:
          toast({
            title: "An error occurred",
            description:
              "Unable to process the image. Please fill in the values manually.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
      }
    } catch (error) {
      console.error("Error processing image:", error);
      toast({
        title: "An error occurred",
        description:
          "Unable to process the image. Please fill in the values manually.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsImageProcessing(false);
    }
  };

  return (
    <Box w="full">
      {isImageProcessing && <LoadingOverlay />}

      <Box w="full" my={6}>
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          w="full"
          mb={2}
        >
          <Box>
            <Heading fontSize="2xl" fontWeight="semibold" mb={1}>
              Hello, {props.patient?.preferredName || props.patient?.name} 👋
            </Heading>
            <Text fontSize="md" color="gray.600">
              What are you recording today?
            </Text>
          </Box>
          <Button
            onClick={handleButtonClick}
            colorScheme="blue"
            borderRadius="full"
            width="40px"
            height="40px"
            p={0}
            boxShadow="md"
          >
            <Icon as={Camera} boxSize={5} />
          </Button>
        </Flex>
      </Box>

      <input
        type="file"
        id="imageUpload"
        accept="image/*"
        ref={imageUploadRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      <VStack spacing={4} w="full" align="stretch">
        {filteredMetrics?.map(
          (metric) =>
            metric.enabled && (
              <MetricCard
                key={metric.detail.id}
                metricDetail={metric.detail}
                adminView={false}
                clientId={props.clientId}
              />
            )
        )}
        <Button
          w="full"
          onClick={() =>
            (window.location.href = window.location.href.replace(
              ROUTES.CLIENT_PASS,
              ROUTES.VIEW_METRIC_DATA
            ))
          }
          colorScheme="blue"
          fontSize="md"
          size="lg"
        >
          View Trends
        </Button>
        {isWearablesEnabled && (
          <Button
            w="full"
            onClick={async () => {
              try {
                if (props.clientId) {
                  const successRedirectUrl = `${window.location.origin}${ROUTES.CLIENTS}/${props.clientId}${ROUTES.TERRA_AUTH_SUCCESS}`;
                  const failureRedirectUrl = `${window.location.origin}${ROUTES.CLIENTS}/${props.clientId}${ROUTES.TERRA_AUTH_ERROR}`;
                  const terraWidget = await generateAuth(
                    props.clientId,
                    successRedirectUrl,
                    failureRedirectUrl
                  );
                  console.log(terraWidget);

                  if (terraWidget && terraWidget.data && terraWidget.data.url) {
                    window.location.href = terraWidget.data.url;
                  } else {
                    toast({
                      title: "Error connecting with Terra",
                      description:
                        "Unable to generate authentication URL. Please try again later.",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  }
                }
              } catch (error) {
                console.error("Error redirecting to Terra widget:", error);
              }
            }}
            colorScheme="blue"
            fontSize="md"
            size="lg"
          >
            Integrate Wearable
          </Button>
        )}
      </VStack>

      <Box w="full" textAlign="center" my={4}>
        <Button
          leftIcon={<ShareAndroid />}
          onClick={props.onShare}
          colorScheme="blue"
          variant="ghost"
          fontSize="md"
        >
          Share with caregiver
        </Button>
      </Box>
    </Box>
  );
};

export default ClientPass;
