import { ROUTES } from "../../../constants";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { whoAmIClient } from "../../../api/whoAmI";
import { useEffect } from "react";
import { useClientStore } from "../../../store/clientStore";
import { useQuery, useQueryClient } from "react-query";
import { Spinner } from "@chakra-ui/react";
const QUERY_KEY = "whoAmIClient";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ClientProtectedRoute = ({ children }: any) => {
  const { clientId } = useParams();
  const { pathname, search } = useLocation();
  const accessToken = useClientStore((state) => state.accessToken);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const loginPath = `${ROUTES.CLIENTS}/${clientId}${
    ROUTES.LOGIN
  }?redirectPath=${pathname + search}`;

  const { isLoading, isError, isSuccess } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      if (!accessToken) {
        throw new Error("No access token");
      }
      return await whoAmIClient();
    },
    // Refresh the validation when window gets focus
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 120,
    // Don't retry on error - just redirect to login
    retry: false,
    // Skip the query if there's no token
    enabled: !!accessToken,
  });

  useEffect(() => {
    if (!accessToken) {
      navigate(loginPath);
      return;
    }
    if (isError) {
      navigate(loginPath);
      return;
    }
    queryClient.invalidateQueries([QUERY_KEY]);
  }, [accessToken, isError, navigate, loginPath]);

  if (isLoading) {
    return <Spinner />;
  }
  if (isSuccess) {
    return children;
  }
  return null;
};
