import { Box, Grid, theme, useToast, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ChatSidebar } from "../../components/Chat/ChatSidebar";
import { ChatWindow } from "../../components/Chat/ChatWindow";
import { getChatPreviews } from "../../api/chat";
import { ChatPreview } from "../../types/chat";
import { ReactComponent as Illustration1 } from "../../assets/illustration_1.svg";

export default function ChatsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [conversations, setConversations] = useState<ChatPreview[]>([]);
  const [selectedPatientId, setSelectedPatientId] = useState<string>();
  const [searchParams] = useSearchParams();
  const toast = useToast();

  useEffect(() => {
    fetchConversations();
  }, []);

  // Handle patientId from query params
  useEffect(() => {
    const patientId = searchParams.get("patientId");
    if (patientId) {
      setSelectedPatientId(patientId);
    }
  }, [searchParams]);

  const fetchConversations = async () => {
    try {
      setIsLoading(true);
      const response = await getChatPreviews();
      setConversations(response.data);
    } catch (error) {
      console.error("Error fetching conversations:", error);
      toast({
        title: "Error fetching conversations",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectPatient = (patientId: string) => {
    setSelectedPatientId(patientId);
  };

  const handleNewChat = (newChatPreview: ChatPreview) => {
    setConversations((prev) => [newChatPreview, ...prev]);
    setSelectedPatientId(newChatPreview.patientId);
  };

  const selectedPatient = conversations.find(
    (conv) => conv.patientId === selectedPatientId
  );

  return (
    <>
      <Grid templateColumns="350px 1fr" overflow="hidden" bg="white" p={0}>
        <ChatSidebar
          conversations={conversations}
          selectedId={selectedPatientId}
          onSelect={handleSelectPatient}
          isLoading={isLoading}
          onNewChat={handleNewChat}
        />
        {selectedPatient ? (
          <ChatWindow
            key={selectedPatient.patientId}
            patientId={selectedPatient.patientId}
            patientName={selectedPatient.patientName}
            phoneNumber={selectedPatient.phoneNumber}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            h="full"
            gap={4}
          >
            <Illustration1 width="200px" height="200px" />
            <VStack spacing={1}>
              <Text fontSize="lg" fontWeight="medium" color={`${theme}.900`}>
                Select a conversation to start chatting
              </Text>
              <Text fontSize="md" color="gray.500">
                Choose a patient from the list to view and send messages
              </Text>
            </VStack>
          </Box>
        )}
      </Grid>
    </>
  );
}
