import {
  Box,
  Button,
  FormControl,
  Modal,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllPatients } from "../../api/patients";
import { respondToForm } from "../../api/forms";
import FormField from "../../components/Form/FormField";
import { PatientDto } from "../../types/patient";
import { RespondFormDto, FormDto } from "../../types/form";
import { checkFormValid } from "../../utils/validators";

interface AddRecordModalProps {
  form: FormDto;
  isOpen: boolean;
  patientId: string;
  onClose: () => void;
}

const AddRecordModal = ({
  form,
  isOpen,
  onClose,
  patientId,
}: AddRecordModalProps) => {
  const [patients, setPatients] = useState<PatientDto[]>([]);
  const generateInitialState = () => {
    const initialState: { [key: string]: string } = {};
    form?.fields.forEach((field, idx) => {
      initialState[`q_${idx + 1}`] = "";
    });
    return initialState;
  };

  const [formValues, setFormValues] = useState(generateInitialState()); // Initialize formValues with the generated initial state
  const toast = useToast();

  useEffect(() => {
    const fetchPatients = async () => {
      // Fetch patients from the server
      const resp = await getAllPatients();
      if (resp.data) {
        setPatients(resp.data.data);
      }
    };
    fetchPatients();
  }, []);

  const handleInputChange = (name: string, value: string) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!form) {
      return;
    }

    const { success, errorMsg } = checkFormValid(form, formValues);
    if (!success) {
      toast({
        title: "Invalid form.",
        description: errorMsg,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const dto: RespondFormDto = {
        formId: form.id,
        rawData: formValues,
        patientId: patientId,
        sendAcknowledge: false,
      };
      const createdDto = await respondToForm(dto);
      if (createdDto) {
        toast({
          title: "Record created.",
          description: "Your response has been recorded.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to submit form, please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit}>
        <Box m={4}>
          <FormControl isRequired></FormControl>
          {form?.fields.map((field, index) => {
            return (
              <FormField
                key={index}
                questionNum={index + 1}
                field={field}
                handleInputChange={handleInputChange}
                mb={8}
                value={formValues[field.key]}
              />
            );
          })}
          <Button mt="4" type="submit">
            Submit
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default AddRecordModal;
