import axios from "./axios/noAuth";
import { AxiosHeaders } from "axios";
import {
  LoginDto,
  RegisterParams,
  ResetPasswordDto,
  LoginClientResp,
  RegisterClientResp,
  GetClientRegAuthMethods,
  SendMsgResp,
} from "../types/auth";

const API_URL = process.env.REACT_APP_API_URL || "";

// Note these do not use axiosAuth as they are not authenticated
export const login = (email: string, password: string) => {
  return axios.post<LoginDto>(
    `${API_URL}/auth/login`,
    { email, password },
    { withCredentials: true }
  );
};

export const register = (params: RegisterParams) => {
  return axios.post<LoginDto>(`${API_URL}/auth/register`, params, {
    withCredentials: true,
  });
};

export const logout = () => {
  return axios.post(`${API_URL}/auth/logout`, {}, { withCredentials: true });
};

export const forgotPassword = (email: string) => {
  try {
    return axios.post(`${API_URL}/auth/forgot-password`, { email });
  } catch (error) {
    throw error;
  }
};

export const resetPassword = (accessToken: string, password: string) => {
  return axios.post<ResetPasswordDto>(`${API_URL}/auth/reset-password`, {
    accessToken,
    password,
  });
};

export const loginClient = async (
  clientId: string,
  password: string
): Promise<LoginClientResp> => {
  try {
    let resp = await axios.post<LoginClientResp>(
      `${API_URL}/auth/login_client`,
      { clientId, password },
      { withCredentials: true }
    );
    return resp.data;
  } catch (err: any) {
    return {
      error: err.response ? err.response.data.message : err.message,
    };
  }
};

export const registerClient = async (
  registerToken: string,
  password: string
): Promise<RegisterClientResp> => {
  try {
    let headers = new AxiosHeaders({
      Authorization: `Bearer ${registerToken}`,
    });
    let resp = await axios.post<LoginDto>(
      `${API_URL}/auth/register_client`,
      {
        password,
      },
      {
        withCredentials: true,
        headers,
      }
    );
    return resp.data;
  } catch (error: any) {
    return {
      error: error.response ? error.response.data.message : error.message,
    };
  }
};

export const resetClientPasscode = async (
  resetToken: string,
  passcode: string
): Promise<{
  message?: string;
  error?: string;
}> => {
  try {
    let headers = new AxiosHeaders({
      Authorization: `Bearer ${resetToken}`,
    });
    const resp = await axios.post<{ message: string }>(
      `${API_URL}/auth/reset_client_passcode`,
      { passcode },
      { headers }
    );
    return resp.data;
  } catch (error: any) {
    return {
      error: error.response ? error.response.data.message : error.message,
    };
  }
};

export const getClientRegisteredAuthMethods = async (
  clientId: string
): Promise<GetClientRegAuthMethods> => {
  try {
    const resp = await axios.get<GetClientRegAuthMethods>(
      `${API_URL}/auth/client_registered_auth_metods`,
      { params: { clientId } }
    );
    return resp.data;
  } catch (error: any) {
    return {
      error: error.response ? error.response.data.message : error.message,
    };
  }
};

export const sendRegisterClientMessage = async (
  clientId: string,
  redirectPath: string
): Promise<SendMsgResp> => {
  try {
    const resp = await axios.post<SendMsgResp>(
      `${API_URL}/auth/send_register_client_message`,
      { clientId, redirectPath }
    );
    return resp.data;
  } catch (error: any) {
    return {
      error: error.response ? error.response.data.message : error.message,
    };
  }
};

export const sendResetClientPasscodeMessage = async (
  clientId: string,
  redirectPath: string
): Promise<SendMsgResp> => {
  try {
    const resp = await axios.post<SendMsgResp>(
      `${API_URL}/auth/send_reset_client_passcode_message`,
      { clientId, redirectPath }
    );
    return resp.data;
  } catch (error: any) {
    return {
      error: error.response ? error.response.data.message : error.message,
    };
  }
};
