import { saveAs } from "file-saver";
import { FormStatus } from "../types/form";
import { ProviderType } from "../constants/provider";
import {
  ClientSchemaFieldDto,
  ClientSchemaFieldType,
} from "../types/organization";

export function toTitleCase(str: string | undefined) {
  if (!str) return "";
  return str.replaceAll("_", " ").replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const getStatusColor = (status: FormStatus) => {
  switch (status) {
    case FormStatus.DEPRECATED:
      return "red";
    case FormStatus.PUBLISHED:
      return "green";
    default:
      return "gray";
  }
};

export const getProviderFriendlyName = (provider: ProviderType) => {
  switch (provider) {
    case ProviderType.FB_MESSENGER:
      return "Facebook Messenger";
    case ProviderType.WHATSAPP:
      return "WhatsApp";
    case ProviderType.TELEGRAM:
      return "Telegram";
    default:
      return "Unknown";
  }
};

export const getChannelColor = (channel: ProviderType) => {
  switch (channel) {
    case ProviderType.FB_MESSENGER:
      return "purple";
    case ProviderType.WHATSAPP:
      return "green";
    default:
      return "gray";
  }
};

/**
 * Exports data to a CSV file.
 *
 * @param {Array.<Array.<string>>} dataRows Array of data rows, where each row is an array of strings.
 * @param {Array.<string>} headers Array of header strings.
 * @param {string} fileName Name of the file to save.
 */
export const exportToCSV = (
  dataRows: Array<Array<string>>,
  headers: Array<string>,
  fileName: string
) => {
  const csvRows = [
    headers.map((header) => escapeCSVField(header)).join(","), // Prepare header row
    ...dataRows.map((row) =>
      row.map((field) => escapeCSVField(field)).join(",")
    ), // Prepare data rows
  ];
  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv" });
  saveAs(blob, fileName);
};

export const expectedColumnsForBulkPatientUpload = [
  "clientId",
  "name",
  "preferredName",
  "dob",
  "sex",
  "address",
  "email",
  "whatsappPhoneNum",
];

export const downloadClientTemplate = (
  orgSchema: ClientSchemaFieldDto[] = []
) => {
  // Combine base columns with org-specific schema columns
  const headers = [
    ...expectedColumnsForBulkPatientUpload,
    ...orgSchema.map((field) => field.key),
  ];

  const exampleData = [
    "CLIENT123",
    "Max Tan",
    "Max",
    "01/01/1980",
    "Male",
    "123 Main Street",
    "max.tan@example.com",
    "6512345678",
    // Add example values for org schema fields
    ...orgSchema.map((field) => {
      switch (field.responseType) {
        case ClientSchemaFieldType.NUMBER:
          return field.numberOptions?.min?.toString() || "0";
        case ClientSchemaFieldType.CHECKBOX:
          return "false";
        case ClientSchemaFieldType.DROPDOWN:
          return field.dropdownOptions?.[0] || "";
        default:
          return field.key === "preferredLanguage" ? "English" : "";
      }
    }),
  ];

  const csvContent = [headers.join(","), exampleData.join(",")].join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, "speedback_bulk_upload_template.csv");
};

export const validateCSVForBulkUpload = async (
  file: File,
  orgSchema: ClientSchemaFieldDto[] = []
): Promise<{ isValid: boolean; error?: string }> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvContent = event.target?.result as string;
      const lines = csvContent.split("\n");
      const headers = lines[0].split(",").map((h) => h.trim());

      // Check if all required columns are present
      const requiredColumns = ["name", "whatsappPhoneNum", "clientId"];
      const missingColumns = requiredColumns.filter(
        (col) => !headers.includes(col)
      );

      if (missingColumns.length > 0) {
        return resolve({
          isValid: false,
          error: `Missing required columns: ${missingColumns.join(", ")}`,
        });
      }

      // Validate each row
      for (let i = 1; i < lines.length; i++) {
        const line = lines[i].trim();
        if (!line) continue; // Skip empty lines
        if (line.startsWith("Note:")) continue; // Skip note lines

        const values = line.split(",").map((v) => v.trim());

        // Basic validation for required fields
        const name = values[headers.indexOf("name")];
        const phoneNum = values[headers.indexOf("whatsappPhoneNum")];
        const clientId = values[headers.indexOf("clientId")];

        if (!name || !phoneNum || !clientId) {
          return resolve({
            isValid: false,
            error: `Row ${i}: Missing required fields (name, phone number, or client ID)`,
          });
        }

        // Validate phone number format
        if (!/^\+?\d+$/.test(phoneNum)) {
          return resolve({
            isValid: false,
            error: `Row ${i}: Invalid phone number format. Must contain only numbers, optionally starting with +`,
          });
        }

        // Validate org schema fields
        for (const field of orgSchema) {
          const value = values[headers.indexOf(field.key)];
          if (!value) continue; // Skip empty values

          try {
            switch (field.responseType) {
              case ClientSchemaFieldType.NUMBER:
                const num = Number(value);
                if (isNaN(num)) {
                  throw new Error(`Invalid number for ${field.key}`);
                }
                if (field.numberOptions) {
                  if (
                    field.numberOptions.max !== undefined &&
                    num > field.numberOptions.max
                  ) {
                    throw new Error(
                      `Value exceeds maximum ${field.numberOptions.max}`
                    );
                  }
                  if (
                    field.numberOptions.min !== undefined &&
                    num < field.numberOptions.min
                  ) {
                    throw new Error(
                      `Value below minimum ${field.numberOptions.min}`
                    );
                  }
                }
                break;
              case ClientSchemaFieldType.CHECKBOX:
                if (value !== "true" && value !== "false") {
                  throw new Error(`Must be 'true' or 'false'`);
                }
                break;
              case ClientSchemaFieldType.DROPDOWN:
                if (
                  field.dropdownOptions &&
                  !field.dropdownOptions.includes(value)
                ) {
                  throw new Error(
                    `Must be one of: ${field.dropdownOptions.join(", ")}`
                  );
                }
                break;
            }
          } catch (error) {
            return resolve({
              isValid: false,
              error: `Row ${i}: Invalid value for ${field.key}: ${
                error instanceof Error ? error.message : "Unknown error"
              }`,
            });
          }
        }

        // Validate date format if DOB is present
        const dobIndex = headers.indexOf("dob");
        if (dobIndex !== -1 && values[dobIndex]) {
          const dateStr = values[dobIndex];
          const date = new Date(dateStr);
          if (isNaN(date.getTime())) {
            return resolve({
              isValid: false,
              error: `Row ${i}: Invalid date format in DOB column. Use YYYY-MM-DD or MM/DD/YYYY format`,
            });
          }
        }
      }

      resolve({ isValid: true });
    };
    reader.readAsText(file);
  });
};

function escapeCSVField(field: string) {
  if (field === null || field === undefined) {
    return '""'; // Represent null or undefined as empty string in CSV
  }

  let fieldStr = String(field); // Convert non-string fields to string

  // Escape double quotes by doubling them
  fieldStr = fieldStr.replace(/"/g, '""');

  // Enclose field in double quotes if it contains commas, double quotes, or newlines
  if (
    fieldStr.includes(",") ||
    fieldStr.includes("\n") ||
    fieldStr.includes("\r") ||
    fieldStr.includes('"')
  ) {
    fieldStr = `"${fieldStr}"`;
  }

  return fieldStr;
}

export function deepEquals<T>(obj1: T, obj2: T): boolean {
  // If both objects are identical, return true
  if (obj1 === obj2) {
    return true;
  }

  // If either object is null or not an object, return false
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1) as (keyof T)[];
  const keys2 = Object.keys(obj2) as (keyof T)[];

  // If the number of keys differs, objects are not equal
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check each key and value recursively
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEquals(obj1[key], obj2[key])) {
      return false;
    }
  }

  // Objects are deeply equal
  return true;
}
