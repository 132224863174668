import { Center, Heading, Text, VStack, Button, Image, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useParams } from "react-router-dom";
import { FC } from "react";

const TerraAuthError: FC = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();

  return (
    <Center mt={20} w={"full"}>
      <VStack spacing={4} ml={5} mr={5}>
        <Heading as="h1" size="lg" color="red.500">
          Authentication Failed
        </Heading>
        <Text>We couldn't connect your wearable health data at this time.</Text>

        <Box boxSize="300px" display="flex" justifyContent="center" alignItems="center">
          <Image 
            src="/error_icon.png" 
            alt="Error" 
            fallback={
              <Box 
                width="100px" 
                height="100px" 
                borderRadius="50%" 
                bg="red.100" 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
              >
                <Text fontSize="4xl" color="red.500">!</Text>
              </Box>
            }
          />
        </Box>

        <Text fontSize="sm" color="gray.600" textAlign="center" mt={2}>
          There was a problem connecting your wearable device. This could be due to:
        </Text>
        
        <Box 
          w="full" 
          bg="gray.50" 
          p={4} 
          borderRadius="md" 
          borderLeft="4px" 
          borderColor="red.500"
        >
          <Text fontSize="sm" mb={2}>• Permission was denied</Text>
          <Text fontSize="sm" mb={2}>• Connection timed out</Text>
          <Text fontSize="sm">• Service is temporarily unavailable</Text>
        </Box>

        <Button
          variant={"outline"}
          width={"full"}
          onClick={() => {
            navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_PASS}`);
          }}
        >
          Back to Health Passport
        </Button>

        <Button
          colorScheme="blue"
          width={"full"}
          onClick={() => {
            // Try authentication again - navigate back to ClientPass where they can trigger the connection again
            navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_PASS}`);
          }}
        >
          Try Again
        </Button>
      </VStack>
    </Center>
  );
};

export default TerraAuthError;