import { AxiosResponse } from "axios";
import staffAuth from "./axios/staffAuth";
import {
  MessageTemplate,
  CreateMessageTemplateDto,
  UpdateMessageTemplateDto,
} from "../types/messageTemplate";

const API_URL = process.env.REACT_APP_API_URL || "";

export const getMessageTemplates = async (): Promise<
  AxiosResponse<MessageTemplate[]>
> => {
  return staffAuth.get(`${API_URL}/chat/template`);
};

export const getMessageTemplate = async (
  id: string
): Promise<AxiosResponse<MessageTemplate>> => {
  return staffAuth.get(`${API_URL}/chat/template/${id}`);
};

export const createMessageTemplate = async (
  template: CreateMessageTemplateDto
): Promise<AxiosResponse<MessageTemplate>> => {
  return staffAuth.post(`${API_URL}/chat/template`, template);
};

export const updateMessageTemplate = async (
  id: string,
  template: UpdateMessageTemplateDto
): Promise<AxiosResponse<MessageTemplate>> => {
  return staffAuth.patch(`${API_URL}/chat/template/${id}`, template);
};

export const deleteMessageTemplate = async (
  id: string
): Promise<AxiosResponse<boolean>> => {
  return staffAuth.delete(`${API_URL}/chat/template/${id}`);
};
