import { Navigate } from "react-router-dom";
import { FeatureData } from "../../store/featureStore";
import { ROUTES } from "../../constants";
import {
  useClientFeatureEnabled,
  useFeatureEnabled,
  useStaffFeatureEnabled,
} from "../../hooks/useFeatureEnabled";

interface FeatureToggleProps {
  name: string;
  isFeatureEnabled: boolean;
  children: React.ReactNode;
  fallbackComponent?: React.ReactNode;
  fallbackPath?: string;
}

/**
 * Use this component to guard features that are only available to staff users.
 */
export const StaffFeatureToggle = (
  props: Omit<FeatureToggleProps, "isFeatureEnabled">
) => {
  const { enabled: isFeatureEnabled } = useStaffFeatureEnabled(props.name);
  return <Toggle {...props} isFeatureEnabled={isFeatureEnabled} />;
};

/**
 * Use this component to guard features that are only available to client users.
 */
export const ClientFeatureToggle = (
  props: Omit<FeatureToggleProps, "isFeatureEnabled">
) => {
  const { enabled: isFeatureEnabled } = useClientFeatureEnabled(props.name);
  return <Toggle {...props} isFeatureEnabled={isFeatureEnabled} />;
};

/**
 * Use this component to guard features that are available to either staff or client users.
 */
export const FeatureToggle = (
  props: Omit<FeatureToggleProps, "isFeatureEnabled">
) => {
  const { enabled: isFeatureEnabled } = useFeatureEnabled(props.name);

  return <Toggle {...props} isFeatureEnabled={isFeatureEnabled} />;
};

const Toggle = ({
  isFeatureEnabled,
  children,
  fallbackComponent,
  fallbackPath,
}: FeatureToggleProps): JSX.Element => {
  if (!isFeatureEnabled) {
    if (fallbackComponent) {
      return <>{fallbackComponent}</>;
    }

    return <Navigate to={fallbackPath ?? ROUTES.CLIENTS} />;
  }
  return <>{children}</>;
};
