import { FeatureData, useFeatureStore } from "../store/featureStore";

export const useFeatureEnabled = (featureName: string): FeatureData => {
  const { staffFeatures, clientFeatures } = useFeatureStore();
  if (staffFeatures[featureName]) {
    return staffFeatures[featureName];
  }
  if (clientFeatures[featureName]) {
    return clientFeatures[featureName];
  }
  return { enabled: false };
};

export const useStaffFeatureEnabled = (featureName: string): FeatureData => {
  const staffFeatures = useFeatureStore((state) => state.staffFeatures);
  if (staffFeatures[featureName]) {
    return staffFeatures[featureName];
  }
  return { enabled: false };
};

export const useClientFeatureEnabled = (featureName: string): FeatureData => {
  const clientFeatures = useFeatureStore((state) => state.clientFeatures);
  if (clientFeatures[featureName]) {
    return clientFeatures[featureName];
  }
  return { enabled: false };
};
