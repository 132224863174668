import * as Sentry from "@sentry/react";
import { getEnvironmentName, IS_PRODUCTION, IS_SANDBOX } from "../constants";

export function initSentry() {
  Sentry.init({
    dsn:
      IS_PRODUCTION || IS_SANDBOX
        ? "https://20f3c8227af7081e5711acfab1c6d5ca@o4508841007382528.ingest.us.sentry.io/4508841271951360"
        : undefined,
    environment: getEnvironmentName(),
    integrations: [],
  });
}
