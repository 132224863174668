import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Container,
  useColorModeValue,
  Text,
  SimpleGrid,
  Divider,
  Flex,
  Icon,
  VStack,
  Spinner,
  Badge,
  Link,
  useToast,
} from "@chakra-ui/react";
import {
  FiMessageSquare,
  FiInfo,
  FiGlobe,
  FiMail,
  FiPhone,
} from "react-icons/fi";
import ChatSettings from "./ChatSettings";
import { useStaffStore } from "../../store/staffStore";
import { getOrganizations } from "../../api/organization";
import { OrganizationDto } from "../../types/organization";

const SettingsPage: React.FC = () => {
  const subtitleColor = useColorModeValue("gray.600", "gray.400");
  const sectionBg = useColorModeValue("gray.50", "gray.700");
  const toast = useToast();

  const { currentStaff } = useStaffStore();
  const organizationId = currentStaff?.organizationId;
  const organizationName =
    currentStaff?.organizationName || "Your Organization";

  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState<OrganizationDto | null>(
    null
  );

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      if (!organizationId) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await getOrganizations();

        if (response.data) {
          const orgData = response.data.find(
            (org) => org.id === organizationId
          );
          if (orgData) {
            setOrganization(orgData);
          }
        }
      } catch (error: any) {
        toast({
          title: "Error",
          description: "Failed to fetch organization details",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationDetails();
  }, [organizationId]);

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <Box mb={2}>
          <Heading size="lg" mb={2}>
            Settings
          </Heading>
          <Text color={subtitleColor}>
            Configure settings for {organizationName}
          </Text>
        </Box>

        <Divider />

        <Box>
          <Flex align="center" mb={4}>
            <Icon as={FiInfo} mr={2} boxSize={5} color="blue.500" />
            <Heading size="md">Organization Information</Heading>
          </Flex>

          <Box bg={sectionBg} p={6} borderRadius="md">
            {loading ? (
              <Flex justify="center" py={4}>
                <Spinner size="md" color="blue.500" />
              </Flex>
            ) : (
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                <Box>
                  <Text fontWeight="medium" color={subtitleColor}>
                    Organization Name
                  </Text>
                  <Text fontSize="lg">
                    {organization?.name || organizationName}
                  </Text>
                </Box>

                <Box>
                  <Text fontWeight="medium" color={subtitleColor}>
                    Organization ID
                  </Text>
                  <Text fontSize="md" fontFamily="mono">
                    {organizationId || "Not available"}
                  </Text>
                </Box>

                <Box>
                  <Flex align="center">
                    <Icon as={FiMail} mr={2} color="blue.500" />
                    <Text fontWeight="medium" color={subtitleColor}>
                      Email
                    </Text>
                  </Flex>
                  <Link href={`mailto:${organization?.email}`} color="blue.500">
                    {organization?.email || "Not available"}
                  </Link>
                </Box>

                <Box>
                  <Flex align="center">
                    <Icon as={FiGlobe} mr={2} color="blue.500" />
                    <Text fontWeight="medium" color={subtitleColor}>
                      Website
                    </Text>
                  </Flex>
                  <Link
                    href={organization?.website}
                    isExternal
                    color="blue.500"
                  >
                    {organization?.website || "Not available"}
                  </Link>
                </Box>

                <Box>
                  <Flex align="center">
                    <Icon as={FiPhone} mr={2} color="blue.500" />
                    <Text fontWeight="medium" color={subtitleColor}>
                      WhatsApp Integration
                    </Text>
                  </Flex>
                  <Badge
                    colorScheme={
                      organization?.whatsappPhoneNumberId ? "green" : "gray"
                    }
                  >
                    {organization?.whatsappPhoneNumberId
                      ? "Configured"
                      : "Not Configured"}
                  </Badge>
                </Box>

                <Box>
                  <Text fontWeight="medium" color={subtitleColor}>
                    Client Schema
                  </Text>
                  <Badge
                    colorScheme={
                      organization?.clientSchema?.length ? "blue" : "gray"
                    }
                  >
                    {organization?.clientSchema?.length
                      ? `${organization.clientSchema.length} fields defined`
                      : "No custom fields"}
                  </Badge>
                </Box>
              </SimpleGrid>
            )}
          </Box>
        </Box>

        <Divider />

        <VStack spacing={2} align="stretch">
          <Flex align="center">
            <Icon as={FiMessageSquare} mr={2} boxSize={5} color="blue.500" />
            <Heading size="md">Communication Settings</Heading>
          </Flex>

          <Text color={subtitleColor}>
            Configure how your organization communicates with patients when
            staff members are unavailable.
          </Text>

          <ChatSettings />
        </VStack>
      </VStack>
    </Container>
  );
};

export default SettingsPage;
