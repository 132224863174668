import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import { IS_SANDBOX } from "../../utils/constants";

export const SANDBOX_BANNER_HEIGHT = "32px";

export const SandboxBanner = () => {
  const fontSize = useBreakpointValue({ base: "xs", md: "sm" });
  const iconSize = useBreakpointValue({ base: "0.7em", md: "0.8em" });
  const py = useBreakpointValue({ base: 0.5, md: 1 });

  if (!IS_SANDBOX) return null;

  return (
    <Box
      bg="rgba(253, 240, 219, 0.95)"
      opacity={0.75}
      color="gray.700"
      py={py}
      px={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={2}
      width="100%"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={9999}
      height={{ base: "24px", md: SANDBOX_BANNER_HEIGHT }}
      borderBottom="1px solid"
      borderColor="orange.200"
      backdropFilter="blur(8px)"
    >
      <FaExclamationTriangle color="#ED8936" size={iconSize} />
      <Text fontSize={fontSize} fontWeight="medium">
        Sandbox Environment
      </Text>
    </Box>
  );
};

export default SandboxBanner;
