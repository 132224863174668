import { create } from "zustand";
import { persist } from "zustand/middleware";

export type FeatureData = {
  enabled: boolean;
  metadata?: Record<string, any>;
};

export type FeatureState = {
  staffFeatures: Record<string, FeatureData>;
  clientFeatures: Record<string, FeatureData>;
  setStaffFeatures: (features: Record<string, FeatureData>) => void;
  setClientFeatures: (features: Record<string, FeatureData>) => void;
  clearStaffFeatures: () => void;
  clearClientFeatures: () => void;
  clearAllFeatures: () => void;
};

export const useFeatureStore = create<FeatureState>()(
  persist(
    (set) => ({
      staffFeatures: {},
      clientFeatures: {},
      setStaffFeatures: (staffFeatures) => set({ staffFeatures }),
      setClientFeatures: (clientFeatures) => set({ clientFeatures }),
      clearStaffFeatures: () => set({ staffFeatures: {} }),
      clearClientFeatures: () => set({ clientFeatures: {} }),
      clearAllFeatures: () => set({ staffFeatures: {}, clientFeatures: {} }),
    }),
    { name: "feature-storage" }
  )
);
