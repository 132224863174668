import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Text,
  Center,
  Spinner,
  IconButton,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  InputRightElement,
  Button,
  Flex,
  Avatar,
  useToast,
} from "@chakra-ui/react";
import { SearchIcon, AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useState, useMemo, useCallback } from "react";
import { ChatPreview } from "../../../types/chat";
import { ChatPreviewItem } from "./ChatPreviewItem";
import { theme } from "../../../constants";
import { getPatientList } from "../../../api/patients";
import { PatientDto } from "../../../types/patient";
import debounce from "lodash/debounce";

interface ChatSidebarProps {
  conversations: ChatPreview[];
  selectedId?: string;
  onSelect: (patientId: string) => void;
  isLoading?: boolean;
  onNewChat: (preview: ChatPreview) => void;
}

export const ChatSidebar = ({
  conversations,
  selectedId,
  onSelect,
  isLoading = false,
  onNewChat,
}: ChatSidebarProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [newChatSearchQuery, setNewChatSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<PatientDto[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const filteredConversations = useMemo(() => {
    if (!searchQuery.trim()) return conversations;

    const query = searchQuery.toLowerCase();
    return conversations.filter((chat) =>
      chat.patientName.toLowerCase().includes(query)
    );
  }, [conversations, searchQuery]);

  const handleNewChatSearch = useCallback(
    debounce(async (query: string) => {
      if (!query.trim()) {
        setSearchResults([]);
        return;
      }

      setIsSearching(true);
      try {
        const response = await getPatientList({ search: query });
        // Filter out patients that already have conversations
        const filteredResults = response.data.data.filter(
          (patient: PatientDto) =>
            !conversations.some((conv) => conv.patientId === patient.id)
        );
        setSearchResults(filteredResults);
      } catch (error) {
        console.error("Error searching patients:", error);
        toast({
          title: "Error searching patients",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSearching(false);
      }
    }, 300),
    [conversations, toast]
  );

  const handleStartNewChat = (patient: PatientDto) => {
    const newChatPreview: ChatPreview = {
      patientId: patient.id,
      patientName: patient.name,
      phoneNumber: patient.whatsappPhoneNum,
      unreadCount: 0,
    };

    onClose();
    setNewChatSearchQuery("");
    setSearchResults([]);

    onNewChat(newChatPreview);
    onSelect(patient.id);

    toast({
      title: "Started new conversation",
      description: `with ${patient.name}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box
      borderRight="1px"
      borderColor="gray.200"
      h="100vh"
      maxW="350px"
      w="full"
    >
      <Box
        p={4}
        borderBottom="1px"
        borderColor="gray.200"
        position="sticky"
        top={0}
        bg="white"
        zIndex={1}
      >
        <Flex gap={2}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.400" />
            </InputLeftElement>
            <Input
              placeholder="Search conversations..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="md"
              variant="filled"
              _focus={{
                bg: "white",
                borderColor: "blue.500",
              }}
            />
            {searchQuery && (
              <InputRightElement>
                <IconButton
                  aria-label="Clear search"
                  icon={<CloseIcon />}
                  size="sm"
                  variant="ghost"
                  onClick={() => setSearchQuery("")}
                />
              </InputRightElement>
            )}
          </InputGroup>
          <Tooltip label="Start new chat" placement="bottom">
            <IconButton
              aria-label="New chat"
              icon={<AddIcon />}
              onClick={onOpen}
              colorScheme="blue"
            />
          </Tooltip>
        </Flex>
      </Box>

      <Box
        overflowY="auto"
        h="calc(100vh - 82px)"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.200",
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "gray.300",
          },
        }}
      >
        {isLoading ? (
          <Center h="full">
            <Spinner size="lg" color="blue.500" />
          </Center>
        ) : filteredConversations.length > 0 ? (
          <VStack spacing={0} align="stretch">
            {filteredConversations.map((chat) => (
              <ChatPreviewItem
                key={chat.patientId}
                preview={chat}
                isSelected={selectedId === chat.patientId}
                onClick={() => onSelect(chat.patientId)}
              />
            ))}
          </VStack>
        ) : searchQuery ? (
          <Center h="full">
            <Text color="gray.500">
              No conversations found matching "{searchQuery}"
            </Text>
          </Center>
        ) : (
          <Center h="full">
            <Text color="gray.500">No conversations yet</Text>
          </Center>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(2px)" />
        <ModalContent maxH="85vh" overflow="hidden">
          <ModalHeader
            borderBottom="1px"
            borderColor="gray.100"
            py={4}
            color={`${theme}.700`}
            fontSize="lg"
          >
            Start New Chat
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Box px={4} py={3} borderBottom="1px" borderColor="gray.100">
              <InputGroup size="md">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  placeholder="Search by patient name..."
                  value={newChatSearchQuery}
                  onChange={(e) => {
                    setNewChatSearchQuery(e.target.value);
                    handleNewChatSearch(e.target.value);
                  }}
                  autoFocus
                  bg="gray.50"
                  _focus={{
                    bg: "white",
                    borderColor: "blue.500",
                    boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)",
                  }}
                  _hover={{
                    bg: "gray.100",
                  }}
                />
                {newChatSearchQuery && (
                  <InputRightElement>
                    <IconButton
                      aria-label="Clear search"
                      icon={<CloseIcon />}
                      size="sm"
                      variant="ghost"
                      onClick={() => {
                        setNewChatSearchQuery("");
                        setSearchResults([]);
                      }}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </Box>

            <Box
              maxH="60vh"
              overflowY="auto"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "gray.200",
                  borderRadius: "24px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "gray.300",
                },
              }}
            >
              {isSearching ? (
                <Flex py={8} px={4} align="center" gap={3}>
                  <Spinner color="blue.500" />
                  <Text color="gray.500" fontSize="sm">
                    Searching patients...
                  </Text>
                </Flex>
              ) : searchResults.length > 0 ? (
                <VStack align="stretch" spacing={0}>
                  {searchResults.map((patient) => (
                    <Button
                      key={patient.id}
                      variant="ghost"
                      justifyContent="flex-start"
                      p={4}
                      h="auto"
                      onClick={() => handleStartNewChat(patient)}
                      _hover={{ bg: "gray.50" }}
                      borderRadius={0}
                      transition="all 0.2s"
                      role="group"
                      w="full"
                    >
                      <Flex align="center" w="full" gap={3}>
                        <Avatar
                          size="sm"
                          name={patient.name}
                          bg="blue.50"
                          color="blue.700"
                        />
                        <Box flex={1}>
                          <Text
                            fontSize="sm"
                            fontWeight="medium"
                            color="gray.700"
                            textAlign="left"
                          >
                            {patient.name}
                          </Text>
                          <Text fontSize="xs" color="gray.500" textAlign="left">
                            {patient.whatsappPhoneNum}
                          </Text>
                        </Box>
                      </Flex>
                    </Button>
                  ))}
                </VStack>
              ) : (
                <Box p={4}>
                  {newChatSearchQuery ? (
                    <Text color="gray.500" fontSize="sm" textAlign="left">
                      No patients found matching "{newChatSearchQuery}"
                    </Text>
                  ) : (
                    <Text color="gray.500" fontSize="sm" textAlign="left">
                      Enter a patient name to start a conversation
                    </Text>
                  )}
                </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
