import {
  Box,
  Container,
  Flex,
  Heading,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  HStack,
  Badge,
  Image,
  Select,
  Input,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { format, subDays } from "date-fns";
import { LabFoodDto } from "../../types/lab";
import { getLabFoods } from "../../api/lab";
import { theme } from "../../constants";

type SortField =
  | "date"
  | "calories"
  | "protein"
  | "carbohydrates"
  | "fat"
  | "fiber";
type SortOrder = "asc" | "desc";
type TimeRange = "7" | "14" | "30" | "all";

const FoodHistory = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [foods, setFoods] = useState<LabFoodDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<SortField>("date");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [timeRange, setTimeRange] = useState<TimeRange>("all");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchFoods = async () => {
      if (!clientId) return;
      try {
        setLoading(true);
        const response = await getLabFoods(clientId);
        setFoods(response);
      } catch (error) {
        console.error("Failed to fetch food history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFoods();
  }, [clientId]);

  const sortedAndFilteredFoods = useMemo(() => {
    let filtered = [...foods];

    // Apply time range filter
    if (timeRange !== "all") {
      const cutoffDate = subDays(new Date(), parseInt(timeRange));
      filtered = filtered.filter(
        (food) => new Date(food.createdAt) >= cutoffDate
      );
    }

    // Apply search filter
    filtered = filtered.filter(
      (food) =>
        food.dishName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        food.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Apply sorting
    return filtered.sort((a, b) => {
      let comparison = 0;
      switch (sortField) {
        case "date":
          comparison =
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          break;
        case "calories":
          comparison = b.calories - a.calories;
          break;
        case "protein":
          comparison = b.protein - a.protein;
          break;
        case "carbohydrates":
          comparison = b.carbohydrates - a.carbohydrates;
          break;
        case "fat":
          comparison = b.fat - a.fat;
          break;
        case "fiber":
          comparison = b.fiber - a.fiber;
          break;
      }
      return sortOrder === "asc" ? -comparison : comparison;
    });
  }, [foods, searchTerm, sortField, sortOrder, timeRange]);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("desc");
    }
  };

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    onOpen();
  };

  if (loading) {
    return (
      <Container maxW="container.xl" py={8}>
        <Text>Loading...</Text>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <Box mb={8}>
        <IconButton
          aria-label="Back"
          icon={<ArrowLeftIcon style={{ width: "20px", height: "20px" }} />}
          onClick={() => navigate(-1)}
          variant="ghost"
          mb={4}
        />
        <VStack align="flex-start" spacing={2} width="full">
          <Heading size="lg">Food History</Heading>
          <Text color="gray.600" fontSize="md" lineHeight="1.6">
            Food entries are automatically collected through natural chat
            conversations with clients. Our AI analyzes food images to extract
            detailed nutritional information, making food logging effortless and
            accurate.
          </Text>
        </VStack>
      </Box>

      <VStack spacing={6} align="stretch">
        {/* Filters */}
        <Flex justify="space-between" align="center" gap={4} wrap="wrap">
          <Input
            placeholder="Search foods..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            maxW="300px"
          />
          <Select
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value as TimeRange)}
            width="200px"
          >
            <option value="7">Last 7 days</option>
            <option value="14">Last 14 days</option>
            <option value="30">Last 30 days</option>
            <option value="all">All time</option>
          </Select>
        </Flex>

        {/* Table */}
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Food</Th>
                <Th onClick={() => handleSort("date")} cursor="pointer">
                  <HStack spacing={1}>
                    <Text>Date</Text>
                    <ArrowsUpDownIcon
                      style={{ width: "12px", height: "12px" }}
                    />
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("calories")} cursor="pointer">
                  <HStack spacing={1}>
                    <Text>Calories</Text>
                    <ArrowsUpDownIcon
                      style={{ width: "12px", height: "12px" }}
                    />
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("protein")} cursor="pointer">
                  <HStack spacing={1}>
                    <Text>Protein (g)</Text>
                    <ArrowsUpDownIcon
                      style={{ width: "12px", height: "12px" }}
                    />
                  </HStack>
                </Th>
                <Th
                  onClick={() => handleSort("carbohydrates")}
                  cursor="pointer"
                >
                  <HStack spacing={1}>
                    <Text>Carbs (g)</Text>
                    <ArrowsUpDownIcon
                      style={{ width: "12px", height: "12px" }}
                    />
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("fat")} cursor="pointer">
                  <HStack spacing={1}>
                    <Text>Fat (g)</Text>
                    <ArrowsUpDownIcon
                      style={{ width: "12px", height: "12px" }}
                    />
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("fiber")} cursor="pointer">
                  <HStack spacing={1}>
                    <Text>Fiber (g)</Text>
                    <ArrowsUpDownIcon
                      style={{ width: "12px", height: "12px" }}
                    />
                  </HStack>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedAndFilteredFoods.map((food) => (
                <Tr key={food.id}>
                  <Td>
                    <HStack spacing={3}>
                      <Image
                        src={food.mediaUrl}
                        alt={food.dishName}
                        boxSize="48px"
                        objectFit="cover"
                        borderRadius="md"
                        fallbackSrc="https://via.placeholder.com/48"
                        cursor="pointer"
                        onClick={() => handleImageClick(food.mediaUrl)}
                        _hover={{
                          transform: "scale(1.05)",
                          transition: "all 0.2s",
                        }}
                      />
                      <Box>
                        <Text fontWeight="medium">{food.dishName}</Text>
                        <Text fontSize="sm" color="gray.600">
                          {food.description}
                        </Text>
                      </Box>
                    </HStack>
                  </Td>
                  <Td>{format(new Date(food.createdAt), "MMM d, h:mm a")}</Td>
                  <Td fontWeight="medium">{food.calories}</Td>
                  <Td>{food.protein}</Td>
                  <Td>{food.carbohydrates}</Td>
                  <Td>{food.fat}</Td>
                  <Td>{food.fiber}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </VStack>

      {/* Image Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Image
              src={selectedImage || ""}
              alt="Food"
              width="100%"
              height="auto"
              maxH="80vh"
              objectFit="contain"
              borderRadius="md"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default FoodHistory;
