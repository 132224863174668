import { AxiosInstance } from "axios";
import axiosClientAuth from "./axios/clientAuth";
import axiosStaffAuth from "./axios/staffAuth";
const API_URL = process.env.REACT_APP_API_URL || "";

interface WhoAmIParams {
  staff?: boolean;
}
interface WhoAmIResponse {
  id: string;
}

export const whoAmIStaff = (): Promise<WhoAmIResponse> => {
  return whoAmI(axiosStaffAuth);
};

export const whoAmIClient = (): Promise<WhoAmIResponse> => {
  return whoAmI(axiosClientAuth);
};

const whoAmI = async (axiosAuth: AxiosInstance): Promise<WhoAmIResponse> => {
  const resp = await axiosAuth.get<{
    id: string;
  }>(`${API_URL}/auth/who_am_i`);
  return resp?.data;
};
