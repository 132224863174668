import { Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { OptionsLayout, FormTypeField } from "../../types/form";

interface RadioFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
  value: string;
}
const RadioFormField = ({ field, handleInputChange, value }: RadioFormFieldProps) => {
  return (
    <RadioGroup onChange={(value) => handleInputChange(field.key, value)} value={value}>
      <Stack
        direction={field.layout === OptionsLayout.HORIZONTAL ? "row" : "column"}
        align="left"
        spacing={2}
      >
        <Text>{field.rangeLabels?.low}</Text>
        {field.options &&
          Object.entries(field.options).map(([optionValue, optionLabel], i) => {
            return (
              <Stack
                key={`${field.key}-radio-option-${i}`}
                direction={
                  field.layout === OptionsLayout.HORIZONTAL ? "column" : "row"
                }
              >
                <Radio id={`radio-${i + 1}`} value={optionValue}></Radio>
                <Text>{optionLabel}</Text>
              </Stack>
            );
          })}
        {field.rangeLabels?.high && <Text>{field.rangeLabels.high}</Text>}
      </Stack>
    </RadioGroup>
  );
};

export default RadioFormField;
