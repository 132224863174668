import {
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Skeleton,
  useColorModeValue,
  Box,
  HStack,
  useBreakpointValue,
  Text,
  TableContainer,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ROUTES, theme } from "../../../constants";
import { PatientDto } from "../../../types/patient";
import { TagDto } from "../../../types/protocol";
import { calculateAge, formatDateToReadableString } from "../../../utils/date";
import { TagPill } from "../../Tags/TagPill";

export type DataSorting = {
  column: "name" | "lastActivity";
  direction: "asc" | "desc";
};

interface PatientTableProps {
  patients: PatientDto[];
  loading?: boolean;
  sorting: DataSorting;
  setSorting: React.Dispatch<React.SetStateAction<DataSorting>>;
  onEdit?: (patientId: string) => void;
  onDelete?: (patientId: string) => void;
}

export const PatientTable = ({
  patients,
  loading,
  sorting,
  setSorting,
  onEdit,
  onDelete,
}: PatientTableProps) => {
  const navigate = useNavigate();
  const hoverBackground = useColorModeValue(`${theme}.100`, `${theme}.700`);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const viewPatient = (patientId: string) => {
    navigate(`${ROUTES.CLIENTS}/${patientId}`);
  };

  const renderTags = (tags: TagDto[]) => {
    if (!tags || tags.length === 0) {
      return <TagPill key={"notags"} tagColor={"grey"} name={"No tags yet"} />;
    }
    return (
      <Flex flexWrap={"wrap"} justifyContent={"center"} gap={1}>
        {tags.map((tag) => (
          <TagPill key={tag.id} tagColor={tag.tagColor} name={tag.name} />
        ))}
      </Flex>
    );
  };

  return (
    <Box border="2px solid" borderRadius={"md"} borderColor={"gray.200"} p={4}>
      <TableContainer>
        <Table
          variant="unstyled"
          size={{ base: "md", "2xl": "lg" }}
          fontSize={{ base: "md", "2xl": "lg" }}
        >
          <Thead>
            <Tr borderBottom={"2px solid"} borderColor={"gray.200"}>
              <Th
                textAlign={"center"}
                cursor="pointer"
                onClick={() =>
                  setSorting((prev) => ({
                    column: "name",
                    direction:
                      prev.column === "name" && prev.direction === "asc"
                        ? "desc"
                        : "asc",
                  }))
                }
              >
                <HStack justify="center" spacing={2}>
                  <Text>Name</Text>
                  {sorting.column === "name" ? (
                    sorting.direction === "asc" ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )
                  ) : (
                    <ChevronDownIcon color="gray.400" />
                  )}
                </HStack>
              </Th>
              {!isMobile && (
                <>
                  <Th textAlign={"center"}>Age</Th>
                  <Th textAlign={"center"}>Sex</Th>
                  <Th textAlign={"center"}>Phone Number</Th>
                  <Th
                    textAlign={"center"}
                    cursor="pointer"
                    onClick={() =>
                      setSorting((prev) => ({
                        column: "lastActivity",
                        direction:
                          prev.column === "lastActivity" &&
                          prev.direction === "asc"
                            ? "desc"
                            : "asc",
                      }))
                    }
                  >
                    <HStack justify="center" spacing={2}>
                      <Text>Last Activity</Text>
                      {sorting.column === "lastActivity" ? (
                        sorting.direction === "asc" ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        )
                      ) : (
                        <ChevronDownIcon color="gray.400" />
                      )}
                    </HStack>
                  </Th>
                </>
              )}
              <Th textAlign={"center"}>Tags</Th>
              {!isMobile && <Th textAlign={"center"}>Actions</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {patients.map((patient) => (
              <Tr
                key={patient.id}
                onClick={() => viewPatient(patient.id)}
                _hover={{
                  bg: hoverBackground,
                  cursor: "pointer",
                }}
              >
                <Td textAlign={"center"}>
                  <Skeleton isLoaded={!loading}>
                    <Text fontWeight="medium">{patient.name}</Text>
                    {isMobile && (
                      <Text fontSize="sm" color="gray.600" mt={1}>
                        {patient.whatsappPhoneNum}
                      </Text>
                    )}
                  </Skeleton>
                </Td>
                {!isMobile && (
                  <>
                    <Td textAlign={"center"}>
                      <Skeleton isLoaded={!loading}>
                        {calculateAge(patient.dob)}
                      </Skeleton>
                    </Td>
                    <Td textAlign={"center"}>
                      <Skeleton isLoaded={!loading}>{patient.sex}</Skeleton>
                    </Td>
                    <Td textAlign={"center"}>
                      <Skeleton isLoaded={!loading}>
                        {patient.whatsappPhoneNum}
                      </Skeleton>
                    </Td>
                    <Td textAlign={"center"}>
                      <Skeleton isLoaded={!loading}>
                        {formatDateToReadableString(patient.lastActivity!)}
                      </Skeleton>
                    </Td>
                  </>
                )}
                <Td textAlign={"center"}>
                  <Skeleton isLoaded={!loading}>
                    {renderTags(patient.tags!)}
                  </Skeleton>
                </Td>
                {!isMobile && (
                  <Td textAlign={"center"}>
                    <Skeleton isLoaded={!loading}>
                      <HStack justifyContent="center">
                        {onEdit && (
                          <IconButton
                            aria-label="Edit patient"
                            variant={"ghost"}
                            onClick={(e) => {
                              e.stopPropagation();
                              onEdit(patient.id);
                            }}
                            icon={<FaEdit />}
                          >
                            Edit
                          </IconButton>
                        )}
                        {/* {onDelete && (
                        <IconButton
                          aria-label="Delete patient"
                          variant={"ghost"}
                          onClick={(e) => {
                            e.stopPropagation();
                            onDelete(patient.id);
                          }}
                          icon={<FaTrash />}
                        >
                          Delete
                        </IconButton>
                      )} */}
                        {/* Delete button temporarily hidden due to complications with table relationships.
                          Will be re-implemented with soft delete in the future */}
                      </HStack>
                    </Skeleton>
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
