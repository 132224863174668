import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
  useToast,
  IconButton,
  Button,
  useDisclosure,
  HStack,
  VStack,
  Card,
  CardBody,
  Badge,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Input,
  List,
  ListItem,
  FormControl,
  Icon,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ROUTES, theme } from "../../constants";
import { PatientDto } from "../../types/patient";
import { useEffect, useState } from "react";
import { getPatientById } from "../../api/patients";
import {
  getLabFoods,
  getLabReports,
  getLabSummary,
  uploadLabReport,
  generateLabSummary,
  deleteLabReport,
} from "../../api/lab";
import { LabFoodDto, LabReportDto, LabSummaryDto } from "../../types/lab";
import {
  ArrowLeftIcon,
  SparklesIcon,
  DocumentTextIcon,
  ChatBubbleLeftRightIcon,
  TrashIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { AiOutlineSelect } from "react-icons/ai";
import { ChatWindow } from "../../components/Chat/ChatWindow";
import { FiFile, FiUpload } from "react-icons/fi";
import { format } from "date-fns";
import FoodHistoryPreview from "../../components/Labs/FoodHistoryPreview";
import NutritionalTrendsPreview from "../../components/Labs/NutritionalTrendsPreview";
import { SpinnerIcon } from "@chakra-ui/icons";
import React from "react";

const LabsPatientView = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [generatingSummary, setGeneratingSummary] = useState(false);
  const [patient, setPatient] = useState<PatientDto | null>(null);
  const [foods, setFoods] = useState<LabFoodDto[]>([]);
  const [reports, setReports] = useState<LabReportDto[]>([]);
  const [summary, setSummary] = useState<LabSummaryDto | null>(null);
  const [summaryError, setSummaryError] = useState<string | null>(null);
  const [hasMessages, setHasMessages] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [deletingReport, setDeletingReport] = useState<LabReportDto | null>(
    null
  );
  const deleteModalDisclosure = useDisclosure();

  // Drawer controls
  const {
    isOpen: isChatDrawerOpen,
    onOpen: onChatDrawerOpen,
    onClose: onChatDrawerClose,
  } = useDisclosure();

  const fetchData = async () => {
    if (!clientId) return;

    try {
      setLoading(true);
      setSummaryError(null);
      const [patientRes, foodsRes, reportsRes, summaryRes] = await Promise.all([
        getPatientById(clientId),
        getLabFoods(clientId),
        getLabReports(clientId),
        getLabSummary(clientId),
      ]);

      setPatient(patientRes.data);
      setFoods(foodsRes);
      setReports(reportsRes);
      if (summaryRes.success && summaryRes.data) {
        setSummary(summaryRes.data);
        setSummaryError(null);
      } else {
        setSummary(null);
        setSummaryError(summaryRes.message || "Unable to generate lab summary");
      }
    } catch (error: any) {
      if (error.response?.status === 401) return;
      setSummaryError("Failed to fetch lab summary");
      toast({
        title: "Failed to fetch data",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !clientId) return;

    try {
      setIsUploading(true);
      await uploadLabReport(clientId, selectedFile);
      toast({
        title: "Report uploaded successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setSelectedFile(null);
      fetchData(); // Refresh data
    } catch (error: any) {
      toast({
        title: "Failed to upload report",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleGenerateSummary = async () => {
    if (!clientId) return;

    try {
      setGeneratingSummary(true);
      const result = await generateLabSummary(clientId);

      if (result.success && result.data) {
        setSummary(result.data);
        setSummaryError(null);
        toast({
          title: "Summary generated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setSummary(null);
        setSummaryError(result.message || "Unable to generate lab summary");
        toast({
          title: "Failed to generate summary",
          description: result.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      setSummaryError("Failed to generate lab summary");
      toast({
        title: "Failed to generate summary",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setGeneratingSummary(false);
    }
  };

  const handleDeleteReport = async (report: LabReportDto) => {
    setDeletingReport(report);
    deleteModalDisclosure.onOpen();
  };

  const confirmDelete = async () => {
    if (!deletingReport || !clientId) return;

    try {
      setIsUploading(true);
      await deleteLabReport(clientId, deletingReport.id);
      toast({
        title: "Report deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      const reports = await getLabReports(clientId);
      setReports(reports);
    } catch (error: any) {
      toast({
        title: "Failed to delete report",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
      setDeletingReport(null);
      deleteModalDisclosure.onClose();
    }
  };

  const renderLabSummary = () => {
    if (loading) {
      return (
        <Card mb={4} variant="outline">
          <CardBody>
            <VStack spacing={4} align="stretch">
              <HStack>
                <Icon as={SparklesIcon} boxSize={5} color="gray.500" />
                <Text fontSize="lg" fontWeight="medium" color="gray.700">
                  Smart Summary
                </Text>
              </HStack>
              <Text>Loading lab summary...</Text>
            </VStack>
          </CardBody>
        </Card>
      );
    }

    const canGenerateSummary = foods.length > 0 && reports.length > 0;

    return (
      <Card mb={4} variant="outline">
        <CardBody>
          <Flex justify="space-between" align="center" mb={6}>
            <HStack>
              <SparklesIcon
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#4299E1",
                }}
              />
              <Heading size="md">Smart Summary</Heading>
            </HStack>
            <HStack spacing={4}>
              {summary && (
                <Text color="gray.500" fontSize="sm">
                  Last generated:{" "}
                  {format(new Date(summary.lastGenerated), "MMM d, h:mm a")}
                </Text>
              )}
              <Button
                size="sm"
                colorScheme="blue"
                leftIcon={
                  generatingSummary ? (
                    <SpinnerIcon />
                  ) : (
                    <SparklesIcon style={{ width: "16px", height: "16px" }} />
                  )
                }
                onClick={handleGenerateSummary}
                isLoading={generatingSummary}
                loadingText="Generating..."
                isDisabled={!canGenerateSummary || generatingSummary}
                title={
                  !canGenerateSummary
                    ? "Please add both food entries and lab reports to generate a summary"
                    : "Generate new summary"
                }
              >
                Generate Summary
              </Button>
            </HStack>
          </Flex>

          {generatingSummary ? (
            <VStack py={8} spacing={4}>
              <Spinner size="lg" color="blue.500" />
              <Text color="gray.600">
                Generating your personalized summary...
              </Text>
            </VStack>
          ) : !summary ? (
            <Box
              p={6}
              bg="gray.50"
              borderRadius="md"
              borderWidth="1px"
              borderColor="gray.200"
            >
              {summaryError?.includes("diet information") ? (
                <VStack spacing={2} align="stretch">
                  <Text color="gray.600">Waiting for food entries</Text>
                  <Text color="gray.500" fontSize="sm">
                    Add some food entries to get personalized insights about
                    your lab results and diet.
                  </Text>
                </VStack>
              ) : summaryError?.includes("lab report") ? (
                <VStack spacing={2} align="stretch">
                  <Text color="gray.600">Waiting for lab report</Text>
                  <Text color="gray.500" fontSize="sm">
                    Upload a lab report to get personalized insights about your
                    health and diet.
                  </Text>
                </VStack>
              ) : summaryError?.includes("no lab report content available") ? (
                <VStack spacing={3} align="stretch">
                  <Text color="gray.600">Ready to generate your summary</Text>
                  <Text color="gray.500" fontSize="sm">
                    Click the "Generate Summary" button above to create a
                    personalized analysis of your lab results and diet.
                  </Text>
                  {!canGenerateSummary && (
                    <Text color="red.500" fontSize="sm">
                      Note: You'll need both food entries and lab reports before
                      generating a summary.
                    </Text>
                  )}
                </VStack>
              ) : summaryError ? (
                <Text color="gray.600">{summaryError}</Text>
              ) : (
                <Text color="gray.600">No summary available</Text>
              )}
            </Box>
          ) : (
            <Grid
              templateColumns={{
                base: "1fr",
                lg: "minmax(250px, 1fr) minmax(300px, 1.5fr) minmax(250px, 1fr)",
              }}
              gap={4}
            >
              <Box>
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  color="gray.700"
                  mb={3}
                  textTransform="uppercase"
                  letterSpacing="wide"
                >
                  Trends
                </Text>
                <Text fontSize="sm" color="gray.600">
                  {summary.trends}
                </Text>
              </Box>

              <Box
                borderLeft="1px"
                borderRight="1px"
                borderColor="gray.100"
                px={8}
              >
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  color="gray.700"
                  mb={3}
                  textTransform="uppercase"
                  letterSpacing="wide"
                >
                  Analysis
                </Text>
                <VStack align="stretch" spacing={4}>
                  <Box>
                    <HStack spacing={2} mb={2}>
                      <Badge colorScheme="green" variant="subtle">
                        Strengths
                      </Badge>
                    </HStack>
                    <Text fontSize="sm" color="gray.600">
                      {summary.analysis.strengths}
                    </Text>
                  </Box>
                  <Box>
                    <HStack spacing={2} mb={2}>
                      <Badge colorScheme="orange" variant="subtle">
                        Areas for Improvement
                      </Badge>
                    </HStack>
                    <Text fontSize="sm" color="gray.600">
                      {summary.analysis.improvements}
                    </Text>
                  </Box>
                </VStack>
              </Box>

              <Box>
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  color="gray.700"
                  mb={3}
                  textTransform="uppercase"
                  letterSpacing="wide"
                >
                  Recommendations
                </Text>
                <Text fontSize="sm" color="gray.600" whiteSpace="pre-line">
                  {summary.recommendations}
                </Text>
              </Box>
            </Grid>
          )}
        </CardBody>
      </Card>
    );
  };

  if (loading) {
    return (
      <Container maxW="container.xl" py={8}>
        <Text>Loading...</Text>
      </Container>
    );
  }

  if (!patient) {
    return (
      <Container maxW="container.xl" py={8}>
        <Text>Patient not found</Text>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={2}>
      {/* Header Section */}
      <Box mb={8} pb={6} borderBottom="1px" borderColor="gray.200">
        <IconButton
          aria-label="Back to labs"
          icon={<ArrowLeftIcon style={{ width: "20px", height: "20px" }} />}
          onClick={() => navigate(ROUTES.LABS)}
          variant="ghost"
          mb={4}
        />
        <Flex justify="space-between" align="center">
          <VStack align="flex-start" spacing={1}>
            <Heading size="lg">{patient.name}</Heading>
            <Text color="gray.600">Lab Reports & Food Analysis</Text>
          </VStack>
          <HStack spacing={2}>
            <Box position="relative">
              <IconButton
                aria-label="Open chat"
                icon={
                  <ChatBubbleLeftRightIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      strokeWidth: 1.5,
                    }}
                  />
                }
                onClick={onChatDrawerOpen}
                size="md"
                variant="ghost"
                color={`${theme}.500`}
                _hover={{
                  color: `${theme}.600`,
                  bg: `${theme}.50`,
                }}
              />
              {unreadCount > 0 && (
                <Badge
                  position="absolute"
                  top="-2px"
                  right="-2px"
                  colorScheme="red"
                  borderRadius="full"
                  fontSize="xs"
                  minW="16px"
                  h="16px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="bold"
                >
                  {unreadCount}
                </Badge>
              )}
            </Box>

            <Link to={`${ROUTES.CLIENTS}/${patient.id}`}>
              <HStack
                spacing={3}
                bg="white"
                py={2}
                px={4}
                borderRadius="full"
                borderWidth="1px"
                borderColor="gray.200"
                transition="all 0.2s"
                _hover={{
                  borderColor: `${theme}.200`,
                  bg: `${theme}.50`,
                  transform: "translateY(-1px)",
                  boxShadow: "sm",
                }}
              >
                <Text color={`${theme}.600`} fontWeight="medium">
                  View full profile
                </Text>
                <Icon
                  as={ArrowTopRightOnSquareIcon}
                  boxSize={4}
                  color={`${theme}.500`}
                />
              </HStack>
            </Link>
          </HStack>
        </Flex>
      </Box>

      <Flex gap={4}>
        {/* Main Content - 75% width */}
        <Box flex="3">
          {renderLabSummary()}

          <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
            <Box>
              <FoodHistoryPreview
                foods={foods}
                isLoading={loading}
                patientId={patient.id}
              />
            </Box>
            <Box>
              <NutritionalTrendsPreview
                foods={foods}
                isLoading={loading}
                patientId={patient.id}
              />
            </Box>
          </Grid>
        </Box>

        {/* Lab Reports Sidebar - 25% width */}
        <Box
          width="25%"
          minWidth="280px"
          position="sticky"
          top="24px"
          alignSelf="flex-start"
          h="calc(100vh - 200px)"
          bg="white"
          borderWidth="1px"
          borderRadius="lg"
        >
          <Flex
            justify="space-between"
            align="center"
            py={4}
            px={5}
            borderBottomWidth="1px"
          >
            <HStack spacing={2}>
              <DocumentTextIcon
                style={{ width: "20px", height: "20px", color: "#4299E1" }}
              />
              <Heading size="md">Lab Reports</Heading>
            </HStack>
            <Text color="gray.500" fontSize="sm">
              {reports.length} {reports.length === 1 ? "report" : "reports"}
            </Text>
          </Flex>

          <Box px={5} py={4} borderBottomWidth="1px">
            <FormControl cursor="pointer">
              <VStack
                spacing={3}
                borderWidth="2px"
                borderRadius="lg"
                borderStyle="dashed"
                borderColor={selectedFile ? "green.300" : "gray.200"}
                bg={selectedFile ? "green.50" : "gray.50"}
                p={5}
                position="relative"
                transition="all 0.2s"
                _hover={{
                  borderColor: isUploading
                    ? "gray.200"
                    : selectedFile
                    ? "green.400"
                    : `${theme}.500`,
                }}
                opacity={isUploading ? 0.7 : 1}
              >
                <Input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileSelect}
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  opacity={0}
                  cursor={isUploading ? "not-allowed" : "pointer"}
                  disabled={isUploading}
                  height="100%"
                  width="100%"
                />

                {isUploading ? (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="md"
                  />
                ) : (
                  <Icon
                    as={selectedFile ? FiFile : FiUpload}
                    boxSize={6}
                    color={selectedFile ? "green.500" : "gray.400"}
                  />
                )}

                <VStack spacing={1}>
                  <Text
                    fontWeight="medium"
                    color={selectedFile ? "green.600" : "gray.600"}
                  >
                    {isUploading
                      ? "Uploading..."
                      : selectedFile
                      ? selectedFile.name
                      : "Upload New Report"}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {selectedFile
                      ? `${(selectedFile.size / (1024 * 1024)).toFixed(2)} MB`
                      : "PDF files up to 10MB"}
                  </Text>
                </VStack>

                {selectedFile && !isUploading && (
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={handleUpload}
                    width="full"
                  >
                    Upload Report
                  </Button>
                )}
              </VStack>
            </FormControl>
          </Box>

          <Box flex={1} overflowY="auto" maxH="calc(100% - 220px)">
            {reports.length > 0 ? (
              <List>
                {reports.map((report, index) => (
                  <ListItem
                    key={report.id}
                    borderBottomWidth="1px"
                    borderColor="gray.100"
                    transition="all 0.2s"
                    _hover={{ bg: "gray.50" }}
                    position="relative"
                    bg={index === 0 ? "blue.50" : "white"}
                  >
                    {index === 0 && (
                      <Badge
                        position="absolute"
                        top={2}
                        right={2}
                        px={2}
                        colorScheme="blue"
                        variant="subtle"
                        fontSize="xs"
                        borderRadius="full"
                      >
                        Latest
                      </Badge>
                    )}
                    <Flex p={4} align="center" justify="space-between">
                      <Box flex={1} mr={4}>
                        <Text
                          fontSize="sm"
                          fontWeight={index === 0 ? "semibold" : "medium"}
                          mb={1}
                          color={index === 0 ? "blue.700" : "inherit"}
                        >
                          {report.reportName}
                        </Text>
                        <VStack spacing={0.5} align="flex-start">
                          <Text
                            fontSize="xs"
                            color={index === 0 ? "blue.600" : "gray.500"}
                          >
                            {new Date(report.createdAt).toLocaleString()}
                          </Text>
                          {index === 0 && (
                            <Text
                              fontSize="xs"
                              color="blue.600"
                              fontStyle="italic"
                            >
                              Used for analysis
                            </Text>
                          )}
                        </VStack>
                      </Box>
                      <HStack spacing={2}>
                        <IconButton
                          aria-label="View report"
                          icon={
                            <ArrowTopRightOnSquareIcon
                              style={{ width: "16px", height: "16px" }}
                            />
                          }
                          size="sm"
                          variant="ghost"
                          color={index === 0 ? "blue.500" : "gray.500"}
                          _hover={{ color: "blue.500", bg: "blue.50" }}
                          onClick={() => window.open(report.mediaUrl, "_blank")}
                        />
                        <IconButton
                          aria-label="Delete report"
                          icon={
                            <TrashIcon
                              style={{ width: "16px", height: "16px" }}
                            />
                          }
                          size="sm"
                          variant="ghost"
                          color="gray.500"
                          _hover={{ color: "red.500", bg: "red.50" }}
                          onClick={() => handleDeleteReport(report)}
                          isDisabled={isUploading}
                        />
                      </HStack>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            ) : (
              <VStack py={8} px={4} spacing={2} color="gray.500">
                <DocumentTextIcon
                  style={{ width: "32px", height: "32px", opacity: 0.5 }}
                />
                <Text fontSize="sm" textAlign="center">
                  No reports uploaded yet
                </Text>
                <Text fontSize="xs" textAlign="center" color="gray.400">
                  Upload a report to get started
                </Text>
              </VStack>
            )}
          </Box>
        </Box>
      </Flex>

      <Drawer
        isOpen={isChatDrawerOpen}
        placement="right"
        onClose={onChatDrawerClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex justify="space-between" align="center">
              <Text fontSize="lg" fontWeight="medium">
                Chat History
              </Text>
              {hasMessages && (
                <HStack
                  as="button"
                  spacing={2}
                  px={3}
                  py={2}
                  borderRadius="md"
                  color={`${theme}.700`}
                  transition="all 0.2s"
                  _hover={{
                    bg: `${theme}.50`,
                  }}
                  onClick={() => {
                    onChatDrawerClose();
                    navigate(`${ROUTES.CHATS}?patientId=${patient.id}`);
                  }}
                >
                  <Text fontSize="sm">Open in Chats</Text>
                  <AiOutlineSelect style={{ width: "16px", height: "16px" }} />
                </HStack>
              )}
            </Flex>
          </DrawerHeader>
          <DrawerBody p={0}>
            <ChatWindow
              patientId={patient.id}
              patientName={patient.name}
              phoneNumber={patient.whatsappPhoneNum}
              showHeader={false}
              onMessagesLoaded={(messages) => {
                setHasMessages(messages.length > 0);
                const unread = messages.filter(
                  (msg) => msg.direction === "incoming" && !msg.lastViewedAt
                ).length;
                setUnreadCount(unread);
              }}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Modal
        isOpen={deleteModalDisclosure.isOpen}
        onClose={deleteModalDisclosure.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Lab Report</ModalHeader>
          <ModalBody>
            Are you sure you want to delete{" "}
            <Text as="span" fontWeight="bold">
              {deletingReport?.reportName}
            </Text>
            ? This action cannot be undone.
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={deleteModalDisclosure.onClose}
              isDisabled={isUploading}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={confirmDelete}
              isLoading={isUploading}
              loadingText="Deleting..."
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default LabsPatientView;
