import axios from "./axios/staffAuth";
import {
  LabReportDto,
  LabFoodDto,
  LabSummaryDto,
  LabReportUploadResponse,
} from "../types/lab";
import { mockLabReport, mockLabFoods, mockLabSummary } from "./mockData/lab";

const API_URL = process.env.REACT_APP_API_URL || "";
const USE_MOCK = false; // Toggle this to switch between mock and real data

export const uploadLabReport = async (
  patientId: string,
  file: File
): Promise<LabReportUploadResponse> => {
  if (USE_MOCK) {
    return Promise.resolve({
      success: true,
      report: mockLabReport,
    });
  }

  const formData = new FormData();
  formData.append("report", file);
  return axios.post(`${API_URL}/lab/patient/${patientId}/report`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getLabReports = async (
  patientId: string
): Promise<LabReportDto[]> => {
  if (USE_MOCK) {
    return Promise.resolve([mockLabReport]);
  }

  const response = await axios.get<LabReportDto[]>(
    `${API_URL}/lab/patient/${patientId}/report`
  );
  return response.data;
};

interface LabSummaryResponse {
  success: boolean;
  data?: LabSummaryDto;
  message?: string;
}

export const getLabSummary = async (
  patientId: string
): Promise<LabSummaryResponse> => {
  if (USE_MOCK) {
    return Promise.resolve({
      success: true,
      data: mockLabSummary,
    });
  }

  const response = await axios.get<LabSummaryResponse>(
    `${API_URL}/lab/patient/${patientId}/summary`
  );
  return response.data;
};

export const generateLabSummary = async (
  patientId: string
): Promise<LabSummaryResponse> => {
  if (USE_MOCK) {
    return Promise.resolve({
      success: true,
      data: mockLabSummary,
    });
  }

  const response = await axios.post<LabSummaryResponse>(
    `${API_URL}/lab/patient/${patientId}/summary`
  );
  return response.data;
};

export const getLabFoods = async (patientId: string): Promise<LabFoodDto[]> => {
  if (USE_MOCK) {
    return Promise.resolve(mockLabFoods);
  }

  const response = await axios.get<LabFoodDto[]>(
    `${API_URL}/lab/patient/${patientId}/foods`
  );
  return response.data;
};

export const deleteLabReport = async (
  patientId: string,
  reportId: string
): Promise<boolean> => {
  if (USE_MOCK) {
    return Promise.resolve(true);
  }

  const response = await axios.delete<boolean>(
    `${API_URL}/lab/patient/${patientId}/report/${reportId}`
  );
  return response.data;
};
