import { FC, useEffect, useRef, useState } from "react";
import { MetricDetail } from "../../types/metricDetail";
import { getMetricDetail } from "../../api/metricDetail";
import {
  useToast,
  Spinner,
  Center,
  VStack,
  Divider,
  Container,
  Box,
  Button,
  Text,
  Heading,
  Icon,
  FormLabel,
  FormControl,
  Input,
  Image,
  Flex,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import FormField from "../../components/Form/FormField";
import { FormTypeField, ResponseType } from "../../types/form";
import { ArrowLeftMd, Camera } from "react-coolicons";
import { useNavigate } from "react-router-dom";
import { createAllPatientMetrics } from "../../api/patientMetric";
import { PatientMetricDto } from "../../types/form";
import { ROUTES } from "../../constants";
import { convDateTimeToISO } from "../../utils/date";
import {
  BloodPressureMeasurement,
  BloodSugarMeasurement,
  ImageExtractedMeasurement,
  VitalType,
  WeightMeasurement,
} from "../../types/llm";
import { Property } from "../../types/protocol";
import { extractImageData } from "../../api/llm";
import { convertMetricDetailTitleToVitalType } from "../../utils/metricDetail";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { ConversionInfo } from "../../types/llm";
import { InfoIcon } from "@chakra-ui/icons";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { Logo } from "../../Logo";

const MetricForm: FC = () => {
  const toast = useToast();
  const [metricDetail, setMetricDetail] = useState<MetricDetail>();
  const [loading, setLoading] = useState(true);
  const [propertyValues, setPropertyValues] = useState<Record<string, string>>(
    {}
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [recordedDate, setRecordedDate] = useState(new Date());
  const { metricId, clientId } = useParams();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  const [imageExtractionIsLoading, setImageExtractionIsLoading] =
    useState(false);
  const imageUploadRef = useRef<HTMLInputElement | null>(null);
  const [conversionInfo, setConversionInfo] = useState<ConversionInfo | null>(
    null
  );

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    imageUploadRef.current?.click();
    console.log(metricDetail);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type.startsWith("image/")) {
        await handleImageUpload(file);
      } else {
        toast({
          title: "Invalid file type",
          description: "Please upload a valid image file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const hasImageExtractedState = !!locationState?.imageExtractedMeasurement;
  const showImageUploadButton =
    metricDetail && !!convertMetricDetailTitleToVitalType(metricDetail?.title);

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    if (!metricDetail) return;

    try {
      setImageExtractionIsLoading(true);
      const vitalType = convertMetricDetailTitleToVitalType(
        metricDetail?.title
      );
      const imageExtractedMeasurement = (await extractImageData(
        file,
        vitalType
      )) as ImageExtractedMeasurement;
      if (vitalType !== imageExtractedMeasurement.measurement.type) {
        toast({
          title: "Unexpected measurement",
          description:
            "The image you have uploaded does not match the type of measurement you selected.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      switch (imageExtractedMeasurement.measurement.type) {
        case VitalType.BLOOD_GLUCOSE:
        case VitalType.BLOOD_PRESSURE:
        case VitalType.WEIGHT:
          setPropertyValuesFromImageExtractedMeasurement(
            imageExtractedMeasurement
          );
          toast({
            title: "Scanning complete!",
            description: `Your reading was auto-filled. Please review it before submitting.`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          break;
        default:
          toast({
            title: "An error occurred",
            description:
              "Unable to process the image. Please fill in the values manually.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          break;
      }
    } catch (error) {
      toast({
        title: "An error occurred",
        description:
          "Unable to process the image. Please fill in the values manually.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setImageExtractionIsLoading(false);
    }
  };

  const setInitialPropertyValues = () => {
    const imageExtractedMeasurement =
      locationState?.imageExtractedMeasurement as ImageExtractedMeasurement;
    if (imageExtractedMeasurement) {
      setPropertyValuesFromImageExtractedMeasurement(imageExtractedMeasurement);
    }
  };

  const setPropertyValuesFromImageExtractedMeasurement = (
    imageExtractedMeasurement: ImageExtractedMeasurement
  ) => {
    // Store conversion info if exists
    setConversionInfo(imageExtractedMeasurement.conversion || null);

    switch (imageExtractedMeasurement.measurement.type) {
      case VitalType.BLOOD_GLUCOSE:
        const bloodSugarMeasurement =
          imageExtractedMeasurement.measurement as BloodSugarMeasurement;
        setPropertyValues({
          [Property.BLOOD_GLUCOSE_RANDOM]:
            bloodSugarMeasurement.glucoseLevel.toString(),
        });
        break;
      case VitalType.BLOOD_PRESSURE:
        const bloodPressureMeasurement =
          imageExtractedMeasurement.measurement as BloodPressureMeasurement;
        setPropertyValues({
          [Property.BLOOD_PRESSURE_SYSTOLIC]: Number(
            bloodPressureMeasurement.systolic
          ).toString(),
          [Property.BLOOD_PRESSURE_DIASTOLIC]: Number(
            bloodPressureMeasurement.diastolic
          ).toString(),
          [Property.HEART_RATE]: Number(
            bloodPressureMeasurement.heartRate
          ).toString(),
        });
        break;
      case VitalType.WEIGHT:
        const weightMeasurement =
          imageExtractedMeasurement.measurement as WeightMeasurement;
        setPropertyValues({
          [Property.WEIGHT]: Number(weightMeasurement.weight).toString(),
        });
        break;
    }
  };

  const onPropertyChange = (key: string, value: string) => {
    setPropertyValues({ ...propertyValues, [key]: value });
  };
  const fetchMetricDetail = async () => {
    setLoading(true);
    let resp = await getMetricDetail(metricId as string);
    if (resp.message || !resp.data) {
      toast({
        title: "Failed to fetch metric detail",
        description: resp.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setMetricDetail(resp.data);
    setLoading(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let patientMetrics: PatientMetricDto[] = [];
    for (let propertyId in propertyValues) {
      patientMetrics.push({
        id: "", // empty id for creating new patient metric
        patientId: clientId as string,
        propertyId: propertyId,
        value: parseFloat(propertyValues[propertyId]),
        timestamp: recordedDate.getTime().toString(),
      });
    }
    try {
      await createAllPatientMetrics(patientMetrics);
      toast({
        title: "Success",
        description: "Data saved successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsSubmitted(true);
    } catch (error: any) {
      toast({
        title: "Failed to save data",
        description: error?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchMetricDetail();
    setInitialPropertyValues();
  }, [metricId]);

  if (loading) {
    return (
      <Center h={"100vh"}>
        <Spinner />
      </Center>
    );
  }
  if (isSubmitted) {
    return <ThankYouSection />;
  }
  return (
    <>
      {imageExtractionIsLoading && <LoadingOverlay />}

      <VStack spacing={0} my={5}>
        <Logo />
        <Box w="full" px={4} py={3} position="relative">
          <Flex w="full" align="center" position="relative" minH="40px">
            <Box position="absolute" left={0} zIndex={1}>
              <Icon
                as={ArrowLeftMd}
                color={"blue.900"}
                fontSize={"2xl"}
                onClick={() => {
                  navigate(-1);
                }}
                cursor="pointer"
              />
            </Box>
            <Box w="full" textAlign="center">
              <Heading as="h1" size="lg">
                {metricDetail?.title}
              </Heading>
              <Text fontSize="md" mt={1} color="gray.600">
                {metricDetail?.unitDescription}
              </Text>
            </Box>
          </Flex>
        </Box>

        <Divider my={4} />
        {metricDetail?.htmlDescription && (
          <Box w="full">
            <Button
              w="full"
              variant="ghost"
              onClick={() => setShowExample(!showExample)}
              rightIcon={showExample ? <ChevronUpIcon /> : <ChevronDownIcon />}
              mb={2}
            >
              {showExample ? "Hide Example" : "Show Example"}
            </Button>
            <Box
              display={showExample ? "block" : "none"}
              dangerouslySetInnerHTML={{
                __html: metricDetail?.htmlDescription || "",
              }}
              w="full"
              px={4}
              py={2}
              bg="gray.50"
              borderRadius="md"
            />
          </Box>
        )}
        <Container maxW="full" textAlign={"left"}>
          <Box as="form" onSubmit={onSubmit}>
            {metricDetail?.properties.map((property, index) => {
              let field: FormTypeField = {
                key: property.propertyId,
                question: property.question,
                responseType: ResponseType.PROPERTY,
                label: property.propertyId,
                isMultiSelect: false,
                required: property.required,
              };
              return (
                <FormField
                  key={property.propertyId}
                  questionNum={index + 1}
                  field={field}
                  handleInputChange={onPropertyChange}
                  mb={8}
                  value={propertyValues[property.propertyId]}
                />
              );
            })}

            {conversionInfo && <ConversionInfoDisplay info={conversionInfo} />}

            <FormControl isRequired>
              <FormLabel>{"Date & Time of recording"}</FormLabel>
              <Input
                width={"full"}
                type="datetime-local"
                onChange={(e) => {
                  setRecordedDate(new Date(e.target.value));
                }}
                value={convDateTimeToISO(recordedDate)}
              />
            </FormControl>

            <Divider mb={4} />

            {showImageUploadButton && (
              <FormControl>
                <FormLabel>Auto-fill from picture (optional)</FormLabel>
                <input
                  type="file"
                  id="imageUpload"
                  ref={imageUploadRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <Button
                  rightIcon={<Camera />}
                  onClick={handleButtonClick}
                  colorScheme="blue"
                  variant="outline"
                  fontSize="md"
                  size="lg"
                  width="full"
                >
                  {hasImageExtractedState
                    ? "Take another photo"
                    : "Take photo to auto-fill"}
                </Button>
              </FormControl>
            )}

            <Button mt="4" type="submit" width={"full"}>
              Submit
            </Button>
          </Box>
        </Container>
      </VStack>
    </>
  );
};

const ThankYouSection: FC = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  return (
    <Center mt={20} w={"full"}>
      <VStack spacing={4} ml={5} mr={5}>
        <Heading as="h1" size="lg">
          Complete!
        </Heading>
        <Text>One step goes a long way!</Text>

        <Image src="/thank_you.png" alt="Thank you" width="300" />

        <Button
          variant={"outline"}
          width={"full"}
          onClick={() => {
            navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_PASS}`);
          }}
        >
          Record More
        </Button>

        <Button
          width={"full"}
          onClick={() => {
            navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.VIEW_METRIC_DATA}`);
          }}
        >
          View Trends
        </Button>
      </VStack>
    </Center>
  );
};

const ConversionInfoDisplay: FC<{ info: ConversionInfo }> = ({ info }) => (
  <Box p={3} bg="blue.50" borderRadius="md" mb={4}>
    <Text fontSize="sm" color="blue.600">
      <Icon as={InfoIcon} mr={2} />
      We detected {info.originalValue}
      {info.originalUnit} and converted it to {info.convertedValue}
      {info.convertedUnit}
    </Text>
  </Box>
);

export default MetricForm;
