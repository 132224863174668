import { Button, HStack, Text, Flex } from "@chakra-ui/react";
import { FormTypeField } from "../../types/form";
import { theme } from "../../constants";
import { useEffect, useState } from "react";

interface LinearScaleFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
  value: string;
}

export const LinearScaleFormField = ({
  field,
  handleInputChange,
  value,
}: LinearScaleFormFieldProps) => {
  const [selectedValue, setSelectedValue] = useState(Number(value));

  useEffect(() => {
    setSelectedValue(Number(value));
  }, [value]);

  const handleClick = (index: number) => {
    setSelectedValue(index);
    handleInputChange(field.key, index.toString());
  };

  return (
    <HStack>
      <Flex wrap="wrap" gap={1}>
        {[...Array(11)].map((_, index) => (
          <Button
            key={index}
            height="30px"
            width="30px"
            bg={selectedValue === index ? `${theme}.700` : "white"}
            color={selectedValue === index ? "white" : `${theme}.800`}
            border="1px solid"
            borderColor={selectedValue === index ? `${theme}.700` : "gray.300"}
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => handleClick(index)}
            _hover={{ bg: selectedValue === index ? `${theme}.700` : "gray.100" }}
            _active={{ bg: selectedValue === index ? `${theme}.700` : "gray.200" }}
          >
            <Text fontSize={{ base: "xs", '2xl': 'md', 'sm': 'xs' }}>{index}</Text>
          </Button>
        ))}
      </Flex>

    </HStack>
  );
};
