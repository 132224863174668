import { Box, Flex, Text, useToast, Button } from "@chakra-ui/react";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chat } from "../../../types/chat";
import {
  getAllMessagesByPatient,
  getIsPatientWithinConversationWindow,
  sendTextMessage,
  sendTemplateMessage,
} from "../../../api/chat";
import { MessageList } from "./MessageList";
import { ChatInput } from "./ChatInput";
import { ROUTES } from "../../../constants";

const POLLING_INTERVALS = {
  ACTIVE: 3000, // 3 seconds - when user is actively chatting
  IDLE: 10000, // 10 seconds - when user is in chat but idle
  INACTIVE: 30000, // 30 seconds - when user has switched away
} as const;

type PollingInterval =
  (typeof POLLING_INTERVALS)[keyof typeof POLLING_INTERVALS];

const ACTIVITY_TIMEOUTS = {
  IDLE: 30000, // 30 seconds without activity = idle
  INACTIVE: 60000, // 1 minute without activity = inactive
} as const;

interface ChatWindowProps {
  patientId: string;
  patientName: string;
  phoneNumber: string;
  showHeader?: boolean;
  onMessagesLoaded?: (messages: Chat[]) => void;
}

export const ChatWindow = ({
  patientId,
  patientName,
  phoneNumber,
  showHeader = true,
  onMessagesLoaded,
}: ChatWindowProps) => {
  const [messages, setMessages] = useState<Chat[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputMessage, setInputMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isWithinWindow, setIsWithinWindow] = useState<boolean | null>(null);
  const [isCheckingWindow, setIsCheckingWindow] = useState(true);
  const [hasNewMessages, setHasNewMessages] = useState(false);
  const [userHasScrolled, setUserHasScrolled] = useState(false);

  // Activity tracking state
  const [isTabFocused, setIsTabFocused] = useState(true);
  const [currentPollingInterval, setCurrentPollingInterval] =
    useState<PollingInterval>(POLLING_INTERVALS.ACTIVE);
  const lastActivityRef = useRef(Date.now());
  const pollIntervalRef = useRef<NodeJS.Timeout>();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const lastMessageCountRef = useRef<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const toast = useToast();
  const navigate = useNavigate();

  const isNewChat = messages.length === 0 && !isLoading;

  // Initial data fetch
  useEffect(() => {
    if (!patientId) return;

    const initializeChat = async () => {
      try {
        setIsLoading(true);
        await Promise.all([fetchMessages(), checkConversationWindow()]);
      } catch (error) {
        console.error("Error initializing chat:", error);
        toast({
          title: "Error loading chat",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    initializeChat();
  }, [patientId]);

  // Track tab focus
  useEffect(() => {
    const handleVisibilityChange = () => {
      const isFocused = document.visibilityState === "visible";
      setIsTabFocused(isFocused);
      setCurrentPollingInterval(
        isFocused ? POLLING_INTERVALS.IDLE : POLLING_INTERVALS.INACTIVE
      );
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Track user activity
  const updateUserActivity = () => {
    lastActivityRef.current = Date.now();
    if (currentPollingInterval !== POLLING_INTERVALS.ACTIVE) {
      setCurrentPollingInterval(POLLING_INTERVALS.ACTIVE);
    }
  };

  // Check for inactivity
  useEffect(() => {
    const checkActivity = () => {
      const timeSinceLastActivity = Date.now() - lastActivityRef.current;

      if (timeSinceLastActivity > ACTIVITY_TIMEOUTS.INACTIVE && isTabFocused) {
        setCurrentPollingInterval(POLLING_INTERVALS.INACTIVE);
      } else if (
        timeSinceLastActivity > ACTIVITY_TIMEOUTS.IDLE &&
        isTabFocused
      ) {
        setCurrentPollingInterval(POLLING_INTERVALS.IDLE);
      }
    };

    const activityInterval = setInterval(checkActivity, 10000);
    return () => clearInterval(activityInterval);
  }, [isTabFocused]);

  // Setup polling with dynamic intervals
  useEffect(() => {
    if (!patientId) return;

    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
    }

    const pollMessages = async () => {
      try {
        const response = await getAllMessagesByPatient(patientId);
        const newMessages = response.data;

        if (newMessages.length > lastMessageCountRef.current) {
          setMessages(newMessages);
          updateUserActivity();

          const { scrollTop, scrollHeight, clientHeight } =
            scrollContainerRef.current || {};
          const isNearBottom =
            scrollHeight && clientHeight && scrollTop
              ? scrollHeight - clientHeight - scrollTop < 100
              : false;

          if (!userHasScrolled || isNearBottom) {
            setTimeout(() => scrollToBottom(), 100);
          } else {
            setHasNewMessages(true);
          }
        }

        lastMessageCountRef.current = newMessages.length;
      } catch (error) {
        console.error("Error polling messages:", error);
      }
    };

    // Start new polling interval
    pollIntervalRef.current = setInterval(pollMessages, currentPollingInterval);

    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [patientId, currentPollingInterval, userHasScrolled]);

  // Track user interactions
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const handleInteraction = () => updateUserActivity();

    container.addEventListener("mousedown", handleInteraction);
    container.addEventListener("keydown", handleInteraction);
    container.addEventListener("touchstart", handleInteraction);
    container.addEventListener("wheel", handleInteraction);

    return () => {
      container.removeEventListener("mousedown", handleInteraction);
      container.removeEventListener("keydown", handleInteraction);
      container.removeEventListener("touchstart", handleInteraction);
      container.removeEventListener("wheel", handleInteraction);
    };
  }, []);

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } =
      scrollContainerRef.current;
    const scrollPosition = scrollHeight - clientHeight - scrollTop;
    const isAtBottom = scrollPosition < 100;

    setUserHasScrolled(!isAtBottom);
    if (isAtBottom) {
      setHasNewMessages(false);
    }
  };

  const scrollToBottom = (behavior: "auto" | "smooth" = "auto") => {
    if (scrollContainerRef.current) {
      const scrollHeight = scrollContainerRef.current.scrollHeight;
      scrollContainerRef.current.scrollTo({
        top: scrollHeight,
        behavior,
      });
    }
    setHasNewMessages(false);
    setUserHasScrolled(false);
  };

  const checkConversationWindow = async () => {
    try {
      setIsCheckingWindow(true);
      if (isNewChat) {
        setIsWithinWindow(false);
        return;
      }
      const response = await getIsPatientWithinConversationWindow(patientId);
      setIsWithinWindow(response.data);
    } catch (error) {
      console.error("Error checking conversation window:", error);
      toast({
        title: "Error checking conversation window",
        description: "Some features may be limited",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      setIsWithinWindow(false);
    } finally {
      setIsCheckingWindow(false);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await getAllMessagesByPatient(patientId);
      setMessages(response.data);
      lastMessageCountRef.current = response.data.length;
      onMessagesLoaded?.(response.data);
      setTimeout(() => scrollToBottom(), 100);
      return response.data; // Return the data for Promise.all
    } catch (error) {
      console.error("Error fetching messages:", error);
      toast({
        title: "Error fetching messages",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      throw error; // Re-throw for Promise.all
    }
  };

  const handleSendMessage = async (templateId?: string) => {
    if (!inputMessage.trim()) return;

    try {
      setIsSending(true);
      updateUserActivity();

      const response = await (templateId
        ? sendTemplateMessage(patientId, { templateId, message: inputMessage })
        : sendTextMessage(patientId, { message: inputMessage }));

      setMessages((prev) => [...prev, response.data]);
      lastMessageCountRef.current = messages.length + 1;

      setInputMessage("");
      await fetchMessages();
      scrollToBottom();
      await checkConversationWindow();
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error sending message",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleViewProfile = () => {
    navigate(`${ROUTES.CLIENTS}/${patientId}`);
  };

  return (
    <Flex direction="column" h="100%" maxH="100vh" overflow="hidden">
      {showHeader && (
        <Box
          p={4}
          borderBottom="1px"
          borderColor="gray.200"
          bg="white"
          position="sticky"
          top={0}
          zIndex={1}
        >
          <Flex justify="space-between" align="center">
            <Box>
              <Text fontSize="lg" fontWeight="medium">
                {patientName}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {phoneNumber}
              </Text>
            </Box>
            <Button
              variant="outline"
              size="sm"
              borderWidth="1px"
              borderColor={`blue.600`}
              color={`blue.600`}
              bg="white"
              px={4}
              fontWeight="medium"
              transition="all 0.2s ease"
              _hover={{
                bg: `blue.700`,
                color: "white",
                borderColor: `blue.700`,
              }}
              onClick={handleViewProfile}
            >
              View Profile
            </Button>
          </Flex>
        </Box>
      )}

      <Box
        ref={scrollContainerRef}
        flex={1}
        position="relative"
        overflowY="auto"
        onScroll={handleScroll}
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.200",
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "gray.300",
          },
        }}
      >
        <MessageList
          messages={messages}
          isLoading={isLoading}
          isNewChat={isNewChat}
          ref={messagesEndRef}
        />

        {hasNewMessages && (
          <Flex
            position="fixed"
            bottom={24}
            left="50%"
            transform="translateX(-50%)"
            zIndex={2}
          >
            <Button
              size="sm"
              bg="blue.50"
              color="blue.700"
              leftIcon={<ArrowDownIcon />}
              onClick={() => scrollToBottom("smooth")}
              shadow="sm"
              border="1px"
              borderColor="blue.100"
              rounded="full"
              px={4}
              py={1}
              fontSize="sm"
              fontWeight="medium"
              _hover={{
                bg: "blue.100",
                transform: "translateY(-1px)",
              }}
              _active={{
                bg: "blue.200",
                transform: "translateY(0)",
              }}
              transition="all 0.2s"
            >
              New Messages
            </Button>
          </Flex>
        )}
      </Box>

      <Box
        borderTop="1px"
        borderColor="gray.200"
        bg="white"
        position="sticky"
        bottom={0}
        zIndex={1}
      >
        <ChatInput
          value={inputMessage}
          onChange={setInputMessage}
          onSend={handleSendMessage}
          isDisabled={isLoading || isCheckingWindow}
          isLoading={isSending}
          isWithinWindow={isWithinWindow ?? false}
        />
      </Box>
    </Flex>
  );
};
