import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ClientLoginResp } from "./store.types";

export type ClientState = {
  accessToken: string;
  setAccessToken: (accessToken: string) => void;
  clientId: string | null;
  organizationId: string | null;
  login: (data: ClientLoginResp) => void;
  logout: () => void;
};

export const useClientStore = create<ClientState>()(
  persist(
    (set) => ({
      accessToken: "",
      setAccessToken: (accessToken: string) => set({ accessToken }),
      clientId: null,
      organizationId: null,
      login: ({ accessToken, clientId, organizationId }: ClientLoginResp) => {
        set({
          accessToken,
          clientId,
          organizationId,
        });
      },
      logout: () =>
        set({ accessToken: "", clientId: null, organizationId: null }),
    }),
    {
      name: "client-storage",
    }
  )
);
