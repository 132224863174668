export enum VitalType {
  BLOOD_PRESSURE = "BLOOD_PRESSURE",
  BLOOD_GLUCOSE = "BLOOD_GLUCOSE",
  WEIGHT = "WEIGHT",
}

export interface ConversionInfo {
  originalValue: number;
  originalUnit: string;
  convertedValue: number;
  convertedUnit: string;
}

export interface ImageExtractedMeasurement {
  measurement:
    | BloodPressureMeasurement
    | WeightMeasurement
    | BloodSugarMeasurement;
  conversion?: ConversionInfo;
}

export interface BloodPressureMeasurement {
  type: VitalType;
  systolic: number;
  diastolic: number;
  heartRate: number;
}

export interface BloodSugarMeasurement {
  type: VitalType;
  glucoseLevel: number;
}

export interface WeightMeasurement {
  type: VitalType;
  weight: number;
}
