import {
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormTypeField } from "../../types/form";
import { checkNumberFieldInvalid } from "../../utils/validators";

interface NumberFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
  value: string;
}

export const NumberFormField = ({
  field,
  handleInputChange,
  value,
}: NumberFormFieldProps) => {
  const [localValue, setLocalValue] = useState<string>(String(value));
  
  useEffect(() => {
    setLocalValue(String(value));
  }, [value]);

  const handleChange = (valueString: string) => {
    setLocalValue(valueString);
    handleInputChange(field.key, valueString);
  };
  
  return (
    <>
      {(field.numberOptions?.max || field.numberOptions?.min) && (
        <FormHelperText>
          (
          {field.numberOptions?.min !== undefined &&
            `Min: ${field.numberOptions.min}, `}
          {field.numberOptions?.max !== undefined &&
            `Max: ${field.numberOptions.max}`}
          )
        </FormHelperText>
      )}
      <NumberInput
        isRequired={field.required}
        isInvalid={checkNumberFieldInvalid(field, localValue)}
        onChange={(valueString) => { handleChange(valueString); }}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </>
  );
};

export default NumberFormField;
