export enum ClientSchemaFieldType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  CHECKBOX = "CHECKBOX",
  DROPDOWN = "DROPDOWN",
}

export type ClientSchemaFieldDto = {
  key: string;
  label: string;
  responseType: ClientSchemaFieldType;
  numberOptions?: {
    min?: number;
    max?: number;
  };
  dropdownOptions?: string[];
  isMultiSelect?: boolean;
};

export interface CreateOrganizationDto {
  name: string;
  website: string;
  email: string;
  whatsappApiToken?: string;
  whatsappBusinessAccountId?: string;
  whatsappPhoneNumberId?: string;
  clientSchema?: ClientSchemaFieldDto[];
}

export interface OrganizationDto {
  id: string;
  name: string;
  website: string;
  email: string;
  whatsappApiToken?: string;
  whatsappBusinessAccountId?: string;
  whatsappPhoneNumberId?: string;
  clientSchema?: ClientSchemaFieldDto[];
}
