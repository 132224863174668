import { ROUTES } from "../../../constants";

import { useEffect } from "react";
import { whoAmIStaff } from "../../../api/whoAmI";
import { useStaffStore } from "../../../store/staffStore";
import { Spinner } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

const QUERY_KEY = "whoAmIStaff";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StaffProtectedRoute = ({ children }: any) => {
  const { pathname, search } = useLocation();
  const accessToken = useStaffStore((state) => state.accessToken);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const loginPath = `${ROUTES.LOGIN}?redirectPath=${pathname + search}`;

  const { isLoading, isError, isSuccess } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      if (!accessToken) {
        throw new Error("No access token");
      }
      return await whoAmIStaff();
    },
    // Refresh the validation when window gets focus
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 120,
    // Don't retry on error - just redirect to login
    retry: false,
    // Skip the query if there's no token
    enabled: !!accessToken,
  });

  useEffect(() => {
    if (!accessToken) {
      navigate(loginPath);
      return;
    }
    if (isError) {
      navigate(loginPath);
      return;
    }
    queryClient.invalidateQueries([QUERY_KEY]);
  }, [accessToken, isError, navigate, loginPath]);

  if (isLoading) {
    return <Spinner />;
  }
  if (isSuccess) {
    return children;
  }
  return null;
};
