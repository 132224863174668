import {
  Box,
  Container,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  HStack,
  Tag,
} from "@chakra-ui/react";
import { theme } from "../../constants";
import Header from "../../components/Head";
import { OrganizationTab } from "./OrganizationTab";
import { StaffTab } from "./StaffTab";
import { PatientsTab } from "./PatientsTab";

const AdminPage = () => {
  return (
    <Container maxW="container.xl" py={5}>
      <Header
        title="Admin Panel"
        description="Internal admin panel for Speedback"
      />
      <VStack spacing={4} align="stretch">
        <VStack align="start" spacing={1}>
          <HStack>
            <Text fontSize="2xl" fontWeight="bold" color={`${theme}.800`}>
              Admin Panel
            </Text>
          </HStack>
          <Text fontSize="md" color="gray.600">
            Internal admin panel for Speedback
          </Text>
        </VStack>

        <Box bg="white" borderRadius="lg" p={4}>
          <Tabs>
            <TabList>
              <Tab>Organizations</Tab>
              <Tab>Staff</Tab>
              <Tab>Patients</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <OrganizationTab />
              </TabPanel>

              <TabPanel>
                <StaffTab />
              </TabPanel>

              <TabPanel>
                <PatientsTab />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </VStack>
    </Container>
  );
};

export default AdminPage;
