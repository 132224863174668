import { LabReportDto, LabFoodDto, LabSummaryDto } from "../../types/lab";

const MOCK_PATIENT_ID = "0ef7e3c1-b1fe-49fb-886f-14eadfeb41a7";

export const mockLabReport: LabReportDto = {
  id: "lab-report-001",
  patientId: MOCK_PATIENT_ID,
  reportName: "Comprehensive Blood Panel",
  reportType: "Blood Test",
  mediaUrl: "https://example.com/reports/blood-panel-2024.pdf",
  content: {
    glucose: { value: 95, unit: "mg/dL", range: "70-100" },
    cholesterol: { value: 180, unit: "mg/dL", range: "125-200" },
    hdl: { value: 50, unit: "mg/dL", range: ">40" },
    ldl: { value: 110, unit: "mg/dL", range: "<130" },
  },
  createdAt: new Date("2024-02-15T08:30:00Z"),
  updatedAt: new Date("2024-02-15T08:30:00Z"),
};

export const mockLabFoods: LabFoodDto[] = [
  {
    id: "food-001",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?w=300&q=80",
    dishName: "Mediterranean Salad",
    description: "Fresh salad with olive oil dressing",
    calories: 320,
    protein: 12,
    carbohydrates: 25,
    fiber: 8,
    fat: 22,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 0)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 0)),
  },
  {
    id: "food-002",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1467003909585-2f8a72700288?w=300&q=80",
    dishName: "Grilled Salmon",
    description: "Wild-caught salmon with herbs",
    calories: 450,
    protein: 46,
    carbohydrates: 0,
    fiber: 0,
    fat: 28,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 1)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 1)),
  },
  {
    id: "food-003",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1638176066666-ffb2f013c7dd?w=300&q=80",
    dishName: "Green Smoothie",
    description: "Spinach, banana, and almond milk smoothie",
    calories: 220,
    protein: 8,
    carbohydrates: 35,
    fiber: 6,
    fat: 7,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 2)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 2)),
  },
  {
    id: "food-004",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?w=300&q=80",
    dishName: "Quinoa Buddha Bowl",
    description: "Quinoa with roasted vegetables",
    calories: 380,
    protein: 15,
    carbohydrates: 52,
    fiber: 10,
    fat: 16,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 3)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 3)),
  },
  {
    id: "food-005",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1532550907401-a500c9a57435?w=300&q=80",
    dishName: "Grilled Chicken Breast",
    description: "Herb-marinated chicken breast",
    calories: 280,
    protein: 38,
    carbohydrates: 2,
    fiber: 0,
    fat: 12,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 4)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 4)),
  },
  {
    id: "food-006",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1517673132405-a56a62b18caf?w=300&q=80",
    dishName: "Overnight Oats",
    description: "Oats with berries and nuts",
    calories: 310,
    protein: 12,
    carbohydrates: 45,
    fiber: 8,
    fat: 14,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 5)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 5)),
  },
  {
    id: "food-007",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1546069901-ba9599a7e63c?w=300&q=80",
    dishName: "Tofu Stir-Fry",
    description: "Tofu with mixed vegetables",
    calories: 340,
    protein: 20,
    carbohydrates: 32,
    fiber: 6,
    fat: 18,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 6)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 6)),
  },
  {
    id: "food-008",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1476718406336-bb5a9690ee2a?w=300&q=80",
    dishName: "Lentil Soup",
    description: "Homemade lentil soup with vegetables",
    calories: 260,
    protein: 16,
    carbohydrates: 38,
    fiber: 12,
    fat: 6,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 7)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 7)),
  },
  {
    id: "food-009",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1488477181946-6428a0291777?w=300&q=80",
    dishName: "Greek Yogurt Parfait",
    description: "Greek yogurt with honey and granola",
    calories: 290,
    protein: 18,
    carbohydrates: 42,
    fiber: 4,
    fat: 8,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 8)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 8)),
  },
  {
    id: "food-010",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1604068549290-dea0e4a305ca?w=300&q=80",
    dishName: "Baked Sweet Potato",
    description: "Baked sweet potato with black beans",
    calories: 330,
    protein: 10,
    carbohydrates: 56,
    fiber: 9,
    fat: 8,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 9)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 9)),
  },
  {
    id: "food-011",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1565958011703-44f9829ba187?w=300&q=80",
    dishName: "Vegetable Pasta",
    description: "Whole grain pasta with roasted vegetables",
    calories: 385,
    protein: 14,
    carbohydrates: 65,
    fiber: 7,
    fat: 12,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 10)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 10)),
  },
  {
    id: "food-012",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1547496502-affa22d38842?w=300&q=80",
    dishName: "Tuna Avocado Toast",
    description: "Whole grain toast with tuna and avocado",
    calories: 340,
    protein: 22,
    carbohydrates: 28,
    fiber: 8,
    fat: 18,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 11)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 11)),
  },
  {
    id: "food-013",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1600335895229-6e75511892c8?w=300&q=80",
    dishName: "Turkey Wrap",
    description: "Turkey and vegetable wrap with hummus",
    calories: 420,
    protein: 28,
    carbohydrates: 48,
    fiber: 6,
    fat: 16,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 12)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 12)),
  },
  {
    id: "food-014",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1490645935967-10de6ba17061?w=300&q=80",
    dishName: "Fruit and Nut Bowl",
    description: "Mixed berries with almonds and seeds",
    calories: 280,
    protein: 8,
    carbohydrates: 38,
    fiber: 9,
    fat: 14,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 13)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 13)),
  },
  {
    id: "food-015",
    patientId: MOCK_PATIENT_ID,
    mediaUrl:
      "https://images.unsplash.com/photo-1484980972926-edee96e0960d?w=300&q=80",
    dishName: "Egg White Omelette",
    description: "Egg white omelette with spinach and mushrooms",
    calories: 220,
    protein: 24,
    carbohydrates: 8,
    fiber: 3,
    fat: 10,
    createdAt: new Date(new Date().setDate(new Date().getDate() - 14)),
    updatedAt: new Date(new Date().setDate(new Date().getDate() - 14)),
  },
];

export const mockLabSummary: LabSummaryDto = {
  patientId: MOCK_PATIENT_ID,
  trends:
    "Based on your recent food logs, there's a consistent pattern of balanced meals with good protein intake. Your caloric intake averages around 318 calories per meal, which aligns well with your goals. There's also a good variety in your food choices, incorporating different protein sources and vegetables.",
  analysis: {
    strengths:
      "You're doing well with protein diversity, incorporating both plant and animal sources. Your fiber intake is consistently good, averaging 7g per meal. Most meals include vegetables or fruits, which is excellent for micronutrient intake.",
    improvements:
      "Some meals are slightly high in fat content, particularly the salmon and Mediterranean salad. While these are healthy fats, portion control could be beneficial. Carbohydrate intake varies significantly between meals.",
  },
  recommendations:
    "1. Consider reducing portion sizes for high-fat meals while maintaining the healthy ingredients\n2. Try to maintain more consistent carbohydrate intake across meals\n3. Add more leafy greens to meals that are lower in fiber\n4. Consider spreading protein intake more evenly throughout the day\n5. Include more complex carbohydrates in breakfast meals",
  lastGenerated: new Date("2024-02-23T14:00:00Z"),
};
