import { FC, useEffect } from "react";
import {
  Heading,
  VStack,
  Text,
  Button,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionIcon,
  Center,
  Spinner,
  Tabs,
  TabList,
  Tab,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useState } from "react";
import { MetricConfWithDetail } from "../../types/clientpass";
import { getClientPassConfig } from "../../api/clientpass";
import { getPatientMetricList } from "../../api/patientMetric";
import { useParams } from "react-router-dom";
import { ROUTES, theme } from "../../constants";
import { useNavigate } from "react-router-dom";
import { Graph, DataPoint, PropertyToDisplay } from "../../components/Graph";
import { TimePeriod } from "../../constants/time";
import { PropertyDetails } from "../../types/metricDetail";
import { toTitleCase } from "../../utils";
import { patientMetricDtoToDataPointsGroupedByTime } from "../../utils/patientMetric";
import { ShareAndroid, Copy } from "react-coolicons";
import { Logo } from "../../Logo";
import { useFilteredMetrics } from "../../hooks/useFilteredMetrics";

const ViewMetricData: FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(true);
  const [metricsConf, setMetricsConf] = useState<MetricConfWithDetail[]>();
  const [dataPointsGroupedByTime, setDataPointsGroupsByTime] = useState<
    DataPoint[][]
  >([]);
  const [selectedTimePeriodTab, setSelectedTimePeriodTab] = useState<number>(1);
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);
  const filteredMetrics = useFilteredMetrics(metricsConf);

  const timePeriodOptions = {
    [TimePeriod.PAST_DAY]: "Day",
    [TimePeriod.PAST_WEEK]: "Week",
    [TimePeriod.PAST_MONTH]: "Month",
  };

  const handleShare = () => {
    onOpen();
  };

  const handleCopy = async () => {
    const message =
      "I've started recording my health information on Speedback, my digital health passport. Click here to view my information: " +
      window.location.href;
    try {
      await navigator.clipboard.writeText(message);
      toast({
        title: "Message copied!",
        description: "You can now share this with your trusted caregiver",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to copy message",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchClientPassConfig = async (id: string) => {
    setLoading(true);
    const resp = await getClientPassConfig(id);
    if (resp.message || !resp.data) {
      toast({
        title: "Failed to fetch vitals",
        description: resp.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    setMetricsConf(resp.data.metrics);
    const enabledMetricsCount = resp.data.metrics.filter(
      (m) => m.enabled
    ).length;
    setExpandedIndices(
      Array.from({ length: enabledMetricsCount }, (_, i) => i)
    );
    setLoading(false);
  };

  const getPatientMetrics = async (clientId: string) => {
    setLoading(true);
    const metrics = await getPatientMetricList({ patientIds: [clientId] });
    if (!metrics) {
      setLoading(false);
      return;
    }

    const dataPoints = patientMetricDtoToDataPointsGroupedByTime(metrics);
    setLoading(false);
    setDataPointsGroupsByTime(dataPoints);
  };

  useEffect(() => {
    fetchClientPassConfig(clientId as string);
    getPatientMetrics(clientId as string);
  }, [clientId]);

  if (loading) {
    return (
      <Center w="full" h="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack mt={4}>
      <Logo />
      <Box w="full" position="relative" mt={4}>
        <Center>
          <Heading as="h1" size="lg">
            Trends
          </Heading>
        </Center>
        <IconButton
          aria-label="Share health data"
          icon={<ShareAndroid />}
          onClick={handleShare}
          colorScheme="blue"
          variant="ghost"
          fontSize="24px"
          position="absolute"
          right={0}
          top="50%"
          transform="translateY(-50%)"
        />
      </Box>
      <Tabs
        index={selectedTimePeriodTab}
        onChange={(index) => setSelectedTimePeriodTab(index)}
        variant="line"
        colorScheme="blue"
      >
        <TabList>
          {Object.entries(timePeriodOptions).map(([, value]) => (
            <Tab>{value}</Tab>
          ))}
        </TabList>
      </Tabs>
      <Accordion
        allowToggle
        allowMultiple
        w="full"
        mt={4}
        index={expandedIndices}
        onChange={(expandedIndex) => {
          if (Array.isArray(expandedIndex)) {
            setExpandedIndices(expandedIndex);
          }
        }}
      >
        {filteredMetrics
          ?.filter((m) => m.enabled)
          .map((metric, index) => (
            <AccordionItem key={index}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" py={2}>
                  <Heading
                    fontWeight="medium"
                    color={`${theme}.900`}
                    fontSize={{ base: "lg", md: "xl" }}
                  >
                    {metric.detail.title}
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Graph
                  dataPointsGroupedByTime={dataPointsGroupedByTime}
                  properties={getPropertiesIdsToDisplay(
                    metric.detail.properties
                  )}
                  selectedTimePeriod={
                    Object.keys(timePeriodOptions)[
                      selectedTimePeriodTab
                    ] as TimePeriod
                  }
                  endTime={new Date().getTime()}
                />
                <Button
                  onClick={() => {
                    navigate(
                      `${ROUTES.METRIC_FORM}/${metric.detail.id}/${clientId}`
                    );
                  }}
                  w="full"
                  mt={4}
                >
                  + Record
                </Button>
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share with caregivers</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text mb={4}>
              Invite your caregivers to your health passport so they know how
              you're doing, and can contribute readings on your behalf.
            </Text>
            <Alert status="warning" mb={4}>
              <AlertIcon />
              Please only share this link and your PIN with trusted caregivers.
            </Alert>
            <Button
              leftIcon={<Copy />}
              onClick={handleCopy}
              colorScheme="blue"
              size="lg"
              width="full"
            >
              Copy Link
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

function getPropertiesIdsToDisplay(
  properties: PropertyDetails[]
): PropertyToDisplay[] {
  return properties
    .filter((p) => p.showInGraph)
    .map((p) => {
      return {
        id: p.propertyId,
        name: toTitleCase(p.propertyId),
      };
    });
}

export default ViewMetricData;
