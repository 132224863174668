import { Box, Flex, Text, Badge, useColorModeValue } from "@chakra-ui/react";
import { ChatPreview } from "../../../types/chat";
import { getLocalRelativeTime } from "../../../utils/date";

interface ChatPreviewItemProps {
  preview: ChatPreview;
  isSelected: boolean;
  onClick: () => void;
}

export const ChatPreviewItem = ({
  preview,
  isSelected,
  onClick,
}: ChatPreviewItemProps) => {
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const selectedBg = useColorModeValue("blue.50", "blue.900");
  const mutedColor = useColorModeValue("gray.600", "gray.400");
  const hasUnread = preview.unreadCount > 0;

  return (
    <Box
      p={3}
      cursor="pointer"
      bg={isSelected ? selectedBg : "transparent"}
      _hover={{ bg: isSelected ? selectedBg : hoverBg }}
      borderBottom="1px"
      borderColor="gray.100"
      onClick={onClick}
      role="group"
      transition="all 0.2s"
    >
      <Flex justify="space-between" align="center" mb={1}>
        <Text
          fontWeight={hasUnread ? "bold" : "medium"}
          fontSize="sm"
          noOfLines={1}
        >
          {preview.patientName}
        </Text>
        {preview.lastMessage && (
          <Text fontSize="xs" color={mutedColor}>
            {getLocalRelativeTime(preview.lastMessage.createdAt)}
          </Text>
        )}
      </Flex>

      <Flex justify="space-between" align="center">
        {preview.lastMessage ? (
          <Text fontSize="xs" color={mutedColor} noOfLines={2} flex="1" mr={2}>
            {preview.lastMessage.text}
          </Text>
        ) : (
          <Text fontSize="xs" color={mutedColor} fontStyle="italic">
            No messages yet
          </Text>
        )}

        {hasUnread && (
          <Badge
            borderRadius="full"
            colorScheme="blue"
            fontSize="xs"
            minW="5"
            textAlign="center"
          >
            {preview.unreadCount}
          </Badge>
        )}
      </Flex>
    </Box>
  );
};
