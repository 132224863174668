import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Flex,
  Icon,
  Spinner,
  useToast,
  Divider,
  List,
  ListItem,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeftMd, AddPlus } from "react-coolicons";
import { ROUTES } from "../../constants";
import { getMetricDetail } from "../../api/metricDetail";
import { getPatientMetricList } from "../../api/patientMetric";
import { MetricDetail } from "../../types/metricDetail";
import { PatientMetricDto } from "../../types/form";
import { Logo } from "../../Logo";
import { format } from "date-fns";
import { Graph, DataPoint, PropertyToDisplay } from "../../components/Graph";
import { TimePeriod } from "../../constants/time";
import { patientMetricDtoToDataPointsGroupedByTime } from "../../utils/patientMetric";

const ClientMetricDetail: React.FC = () => {
  const { metricId, clientId } = useParams<{
    metricId: string;
    clientId: string;
  }>();
  const navigate = useNavigate();
  const toast = useToast();
  const [metricDetail, setMetricDetail] = useState<MetricDetail | null>(null);
  const [metrics, setMetrics] = useState<PatientMetricDto[]>([]);
  const [groupedMetrics, setGroupedMetrics] = useState<
    Record<string, PatientMetricDto[]>
  >({});
  const [isLoading, setIsLoading] = useState(true);
  const [dataPointsGroupedByTime, setDataPointsGroupedByTime] = useState<
    DataPoint[][]
  >([]);
  const [selectedTimePeriodTab, setSelectedTimePeriodTab] = useState<number>(1);

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const dateColor = useColorModeValue("blue.600", "blue.300");

  const timePeriodOptions = {
    [TimePeriod.PAST_DAY]: "Day",
    [TimePeriod.PAST_WEEK]: "Week",
    [TimePeriod.PAST_MONTH]: "Month",
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch metric detail
        const detailResponse = await getMetricDetail(metricId as string);
        if (detailResponse.message || !detailResponse.data) {
          toast({
            title: "Failed to fetch metric details",
            description: detailResponse.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        setMetricDetail(detailResponse.data);

        if (
          detailResponse.data.properties &&
          detailResponse.data.properties.length > 0
        ) {
          const propertyIds = detailResponse.data.properties.map(
            (prop) => prop.propertyId
          );

          const metricsResponse = await getPatientMetricList({
            patientIds: [clientId as string],
          });

          if (metricsResponse && Array.isArray(metricsResponse)) {
            const filteredMetrics = metricsResponse.filter((metric) =>
              propertyIds.includes(metric.propertyId)
            );

            const sortedMetrics = filteredMetrics.sort(
              (a, b) => parseInt(b.timestamp) - parseInt(a.timestamp)
            );
            setMetrics(sortedMetrics);

            // Group metrics by timestamp for combined display (especially for blood pressure)
            const grouped = groupMetricsByTimestamp(sortedMetrics);
            setGroupedMetrics(grouped);

            const dataPoints =
              patientMetricDtoToDataPointsGroupedByTime(filteredMetrics);
            setDataPointsGroupedByTime(dataPoints);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error",
          description: "Failed to load metric data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (metricId && clientId) {
      fetchData();
    }
  }, [metricId, clientId, toast]);

  const groupMetricsByTimestamp = (
    metrics: PatientMetricDto[]
  ): Record<string, PatientMetricDto[]> => {
    const grouped: Record<string, PatientMetricDto[]> = {};

    metrics.forEach((metric) => {
      if (!grouped[metric.timestamp]) {
        grouped[metric.timestamp] = [];
      }
      grouped[metric.timestamp].push(metric);
    });

    return grouped;
  };

  const handleAddClick = () => {
    navigate(`${ROUTES.METRIC_FORM}/${metricId}/${clientId}`);
  };

  const handleBackClick = () => {
    navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_PASS}`);
  };

  const getPropertiesToDisplay = (): PropertyToDisplay[] => {
    if (!metricDetail || !metricDetail.properties) return [];

    return metricDetail.properties
      .filter((p) => p.showInGraph)
      .map((p) => ({
        id: p.propertyId,
        name: p.propertyId
          .replace(/_/g, " ")
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      }));
  };

  const formatDate = (timestamp: string) => {
    const date = new Date(parseInt(timestamp));
    return format(date, "MMM d, yyyy 'at' h:mm a");
  };

  const isBloodPressureMetric = (): boolean => {
    if (!metricDetail?.properties) return false;

    const propertyIds = metricDetail.properties.map((p) => p.propertyId);
    return (
      propertyIds.includes("BLOOD_PRESSURE_SYSTOLIC") &&
      propertyIds.includes("BLOOD_PRESSURE_DIASTOLIC")
    );
  };

  // Render the value for a group of metrics (handles special cases like blood pressure)
  const renderMetricValue = (metricsGroup: PatientMetricDto[]) => {
    if (isBloodPressureMetric() && metricsGroup.length > 1) {
      // Find systolic and diastolic values
      const systolic = metricsGroup.find(
        (m) => m.propertyId === "BLOOD_PRESSURE_SYSTOLIC"
      )?.value;
      const diastolic = metricsGroup.find(
        (m) => m.propertyId === "BLOOD_PRESSURE_DIASTOLIC"
      )?.value;

      if (systolic !== undefined && diastolic !== undefined) {
        return (
          <Text fontWeight="semibold" fontSize="lg">
            {systolic}/{diastolic} {metricDetail?.unitDescription}
          </Text>
        );
      }
    }

    // Default case for single metrics
    return (
      <Text fontWeight="semibold" fontSize="lg">
        {metricsGroup[0].value} {metricDetail?.unitDescription}
      </Text>
    );
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  return (
    <VStack spacing={0} minH="100vh" bg="gray.50" my={5}>
      <Logo />
      <Box w="full" px={4} py={3} position="relative">
        <Flex w="full" align="center" position="relative" minH="40px">
          <Box position="absolute" left={0} zIndex={1}>
            <Icon
              as={ArrowLeftMd}
              color="blue.900"
              fontSize="2xl"
              onClick={handleBackClick}
              cursor="pointer"
            />
          </Box>
          <Box w="full" textAlign="center">
            <Heading as="h1" size="lg">
              {metricDetail?.title}
            </Heading>
            <Text fontSize="md" mt={1} color="gray.600">
              {metricDetail?.unitDescription}
            </Text>
          </Box>
          <Box position="absolute" right={0} zIndex={1}>
            <Icon
              as={AddPlus}
              color="blue.900"
              fontSize="2xl"
              onClick={handleAddClick}
              cursor="pointer"
            />
          </Box>
        </Flex>
      </Box>

      <Divider />

      <Box w="full" px={4} pb={6}>
        <Tabs
          index={selectedTimePeriodTab}
          onChange={(index) => setSelectedTimePeriodTab(index)}
          variant="line"
          colorScheme="blue"
          mt={2}
        >
          <TabList>
            {Object.entries(timePeriodOptions).map(([, value], index) => (
              <Tab key={index}>{value}</Tab>
            ))}
          </TabList>
        </Tabs>

        <Box
          bg={bgColor}
          borderRadius="md"
          borderWidth="1px"
          borderColor={borderColor}
          mt={4}
          mb={6}
          p={1}
          overflow="hidden"
        >
          {dataPointsGroupedByTime.length > 0 ? (
            <Graph
              dataPointsGroupedByTime={dataPointsGroupedByTime}
              properties={getPropertiesToDisplay()}
              selectedTimePeriod={
                Object.keys(timePeriodOptions)[
                  selectedTimePeriodTab
                ] as TimePeriod
              }
              endTime={new Date().getTime()}
            />
          ) : (
            <Flex justify="center" align="center" h="full" p={6}>
              <Text color="gray.500">No data recorded yet</Text>
            </Flex>
          )}
        </Box>

        <Heading size="md" mb={4}>
          History
        </Heading>

        {Object.keys(groupedMetrics).length > 0 ? (
          <List spacing={3}>
            {Object.entries(groupedMetrics).map(([timestamp, metricsGroup]) => (
              <ListItem key={timestamp}>
                <Box
                  p={4}
                  bg={bgColor}
                  borderRadius="md"
                  borderWidth="1px"
                  borderColor={borderColor}
                  boxShadow="sm"
                >
                  <Flex direction="column">
                    {renderMetricValue(metricsGroup)}
                    <Text fontSize="sm" color={dateColor} mt={1}>
                      {formatDate(timestamp)}
                    </Text>
                  </Flex>
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box
            p={6}
            bg={bgColor}
            borderRadius="md"
            borderWidth="1px"
            borderColor={borderColor}
            textAlign="center"
          >
            <Text color="gray.500">No history available</Text>
          </Box>
        )}
      </Box>
    </VStack>
  );
};

export default ClientMetricDetail;
